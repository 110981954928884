import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import useConsumerKYC from './hooks/useConsumerKYC'

const contextDefaultValues = {
    inputs: {},
    handleInputChange: () => {},
    setInputs: () => {},
    useType: '',
}

export const ConsumerKYCContext = createContext(contextDefaultValues)

const ConsumerKYCContextProvider = ({ children, useType }) => {
    const { inputs, handleInputChange, setInputs } = useConsumerKYC()

    return (
        <ConsumerKYCContext.Provider
            value={{ inputs, handleInputChange, setInputs, useType }}
        >
            {children}
        </ConsumerKYCContext.Provider>
    )
}

ConsumerKYCContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    useType: PropTypes.string,
}

export const useConsumerKYCContext = () => useContext(ConsumerKYCContext)
export default ConsumerKYCContextProvider
