import FormTitleBar from 'src/components/FormTitleBar'
import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import {
    InputFields,
    MultipleSelectField,
    SelectField,
} from 'src/components/InputFields'
import { useState, useEffect } from 'react'
import {
    allowedOptions,
    attributeTypeOptions,
    unitOfMeasurement,
} from '../data'
import Checkbox from 'src/components/Checkbox'
import Button from 'src/components/Button'
import styles from '../componentType.module.scss'
import { useQuery } from 'react-query'
import { updateComponentTypeApi } from 'src/api/admin/settings/componenttypes'
import { InlineLoader } from 'src/components/Loader'
import { attributeValidationSchema } from 'src/utils/validationSchema'
import Toast from 'src/components/Toast'
import { errorHandler } from 'src/utils/errorHandler'
import { isMobile } from 'src/utils/mediaQueries'

const EditAttribute = ({
    showModal,
    onCancel,
    editAttributeData,
    id,
    handleSuccess,
}) => {
    const [inputs, setInputs] = useState({})
    const [errors, setErrors] = useState({})
    const [currentValues, setCurrentValues] = useState({})
    const [payload, setPayload] = useState({})
    const [modalError, setModalError] = useState(false)
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const { CheckBoxWrapper, CheckBoxText, ModalBtn, EditModal, ModalWrapper } =
        styles

    useEffect(() => {
        const data = {
            id: editAttributeData?.id,
            name: editAttributeData?.name,
            field_type: editAttributeData?.field_type,
            units_of_measurement: editAttributeData?.units_of_measurement.map(
                each => ({ value: each, label: each }),
            ),
            required: editAttributeData?.required,
            options: editAttributeData?.options.map(each => ({
                value: each,
                label: each,
            })),
        }
        setCurrentValues(data)
        setInputs(data)
    }, [editAttributeData, showModal])

    const { refetch, isLoading } = useQuery(
        'edit-attribute',
        () => updateComponentTypeApi(id, { attributes: [payload] }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                handleSuccess()
            },
            onError: error => {
                setModalError(true)
                setModalErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleSave = () => {
        setErrors({})
        attributeValidationSchema
            .validate(inputs, { abortEarly: false })
            .then(() => {
                setPayload({
                    id: inputs?.id,
                    name: inputs?.name,
                    field_type: inputs?.field_type,
                    units_of_measurement: inputs?.units_of_measurement.map(
                        each => each.value,
                    ),
                    required: inputs?.required,
                    options: inputs?.options.map(each => each.value),
                })
            })
            .then(() => {
                setErrors({})
                refetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleCheck = () => {
        const value = inputs?.required
        setInputs(prev => ({ ...prev, required: !value }))
    }

    const closeModal = () => {
        setInputs(currentValues)
        onCancel()
    }

    return (
        <>
            <SecondaryModal
                showModal={showModal}
                modalWidth="624px"
                modalHeight="100%"
                onCancel={() => {
                    closeModal()
                }}
                content={
                    <div className={ModalWrapper}>
                        <FormTitleBar
                            title={'Edit Attribute'}
                            subtitle={'Enter the new details of the attribute '}
                            titleFontSize={'20px'}
                            alignLeft={isMobile}
                        />

                        {modalError && (
                            <Toast
                                messageType="error"
                                message={modalErrorMessage}
                            />
                        )}

                        <InputFields
                            title="Attribute Name"
                            inputWidth="100%"
                            name="name"
                            type="text"
                            value={inputs?.name}
                            handleChange={handleChange}
                            errorMessage={errors?.name}
                        />

                        <SelectField
                            name="field_type"
                            selectWidth="100%"
                            withCheckBox={true}
                            initialOption={'Attribute Type'}
                            floatingLabel="Attribute Type"
                            value={inputs?.field_type}
                            values={attributeTypeOptions}
                            handleChange={handleSelectChange}
                            selectedValue={inputs?.field_type}
                            currentSelected={inputs?.field_type}
                            type="approvedModal"
                            errorMessage={errors?.field_type}
                        />

                        <div className={CheckBoxWrapper}>
                            <Checkbox
                                isChecked={inputs?.required}
                                handleChecked={handleCheck}
                            >
                                <p className={CheckBoxText}>
                                    This attribute is required
                                </p>
                            </Checkbox>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <MultipleSelectField
                                title="Units of Measurement"
                                selectWidth="100%"
                                name="units_of_measurement"
                                values={unitOfMeasurement}
                                value={inputs?.units_of_measurement?.map(
                                    item => item.value,
                                )}
                                inputValue={inputs?.units_of_measurement?.map(
                                    item => item.value,
                                )}
                                dropDownWidth="150px"
                                dropdownMatchSelectWidth={false}
                                handleMultipleSelectChange={value => {
                                    const values = value?.map(item => ({
                                        value: item,
                                        label: item,
                                    }))
                                    inputs.units_of_measurement = values
                                    setInputs(prev => ({
                                        ...prev,
                                        units_of_measurement: values,
                                    }))
                                }}
                                errorMessage={errors?.units_of_measurement}
                            />
                        </div>

                        <div style={{ marginBottom: 20 }}>
                            <MultipleSelectField
                                title="Allowed Options"
                                selectWidth="100%"
                                name="options"
                                value={inputs?.options?.map(item => item.value)}
                                inputValue={inputs?.options?.map(
                                    item => item.value,
                                )}
                                values={allowedOptions}
                                dropDownWidth="150px"
                                dropdownMatchSelectWidth={false}
                                handleMultipleSelectChange={value => {
                                    const values = value?.map(item => ({
                                        value: item,
                                        label: item,
                                    }))
                                    inputs.options = values
                                    setInputs(prev => ({
                                        ...prev,
                                        options: values,
                                    }))
                                }}
                                errorMessage={errors?.options}
                            />
                        </div>
                        <div className={`${ModalBtn} ${EditModal}`}>
                            <Button
                                btnWidth="160px"
                                btnHeight="56px"
                                fontSize="14px"
                                btnBgColor="var(--purple-light)"
                                btnTextColor="#004AAD"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                handleClick={() => {
                                    closeModal()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                btnWidth="160px"
                                btnHeight="56px"
                                fontSize="14px"
                                handleClick={() => handleSave()}
                            >
                                {isLoading ? <InlineLoader /> : 'Save Changes'}
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    )
}

EditAttribute.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    editAttributeData: PropTypes.object,
    id: PropTypes.string,
    handleSuccess: PropTypes.func,
}
export default EditAttribute
