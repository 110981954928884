import s from './packageimages.module.scss'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import fallbackImage from 'src/assets/images/fallback.svg'

const PackageImagesCarousel = ({ systemDetails, margin }) => {
    const [images, setImages] = useState([
        {
            key: 1,
            link: fallbackImage,
        },
    ])
    const [selected, setSelected] = useState({
        key: 1,
        link: fallbackImage,
    })

    const selectImage = image => {
        setSelected(image)
    }
    useEffect(() => {
        if (systemDetails?.image?.length) {
            const mappedImages = systemDetails?.image?.map((image, idx) => ({
                key: idx + 1,
                link: image,
            }))
            setImages(mappedImages)
            setSelected(mappedImages?.[0])
        } else {
            const primaryPackageImage = systemDetails?.image?.length
                ? systemDetails?.image?.[0]
                : systemDetails?.has_solar_panel
                ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+Banner.png'
                : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+Banner.png'

            const defaultImage = {
                key: 1,
                link: primaryPackageImage,
            }
            setImages([defaultImage])
            setSelected(defaultImage)
        }
    }, [systemDetails])

    return (
        <div className={s.wrapper} style={{ margin: margin }}>
            <LazyLoadImage
                src={selected?.link}
                alt="image of the system"
                placeholderSrc={fallbackImage}
            />

            <div className={s.bottomPanel}>
                <h2>{systemDetails?.name}</h2>
                <div className={s.imageSelection}>
                    {images?.map((image, i) => (
                        <div
                            key={i}
                            className={`${s.imageOption} ${
                                image?.key === selected?.key
                                    ? s.imageOptionActive
                                    : ''
                            }`}
                            onClick={() => selectImage(image)}
                        >
                            <img src={image?.link} alt={'package image'} />
                            <div
                                className={
                                    image?.key === selected?.key
                                        ? s.overlayInactive
                                        : s.overlayActive
                                }
                            ></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

PackageImagesCarousel.propTypes = {
    systemDetails: PropTypes.object,
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default PackageImagesCarousel
