import axios from '../../../config/axios'

export const getAdminUsers = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
    state = '',
) => {
    const request = await axios.get(
        `/admin/users?page=${page}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&state=${state}&has_accepted_invite=true`,
    )
    return request
}

export const getAdminGroups = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
    state = '',
    workspace = 'admin',
) => {
    const request = await axios.get(
        `/admin/roles?page=${page}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&state=${state}&workspace=${workspace}`,
    )
    return request
}

export const inviteAdminUserApi = async users => {
    const request = await axios.post(`admin/users/invitation`, {
        admin_user: users,
    })
    return request
}
