import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { useConsumerKYCContext } from '../../context'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const PersonalInfo = () => {
    const { inputs, handleInputChange } = useConsumerKYCContext()
    const { isMobile } = useMediaQueries()

    return (
        <div className="PersonalInfo">
            <div className="GroupItems">
                <InputFields
                    title="First Name"
                    value={inputs?.first_name}
                    name="first_name"
                    handleChange={handleInputChange}
                    marginBottom={isMobile ? '16px' : ''}
                />
                <InputFields
                    title="Last Name"
                    value={inputs?.last_name}
                    name="last_name"
                    handleChange={handleInputChange}
                    marginBottom={isMobile ? '16px' : ''}
                />
            </div>

            <div className="GroupItems">
                <InputFields
                    title="Email Address"
                    value={inputs?.email}
                    name="email"
                    handleChange={handleInputChange}
                    marginBottom={isMobile ? '16px' : ''}
                />
                <SelectCountryCode
                    title="Phone number"
                    inputValue={inputs?.phone_number}
                    name="phone_number"
                    marginBottom={isMobile ? '16px' : ''}
                />
            </div>
        </div>
    )
}

export default PersonalInfo
