//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tabs, Pagination, Drawer, Skeleton, Dropdown, Menu } from 'antd'
import { useQuery } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { decodeUserInfo } from 'src/utils/auth'

import { format } from 'date-fns'

import ButtonComponent, { AppButton } from 'src/components/Button'
import {
    DateField,
    InputFields,
    SelectField,
    NoFLoatingLabelInputFields,
} from 'src/components/InputFields'
import './users-listing.scss'
import {
    userStatus,
    groups,
    extractEmailOrNumber,
    formattedGroupsData,
} from './data'
import {
    getAdminUsers,
    getAdminGroups,
    inviteAdminUserApi,
} from 'src/api/admin/user/listing'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { ReactComponent as PlusIcon } from 'src/assets/images/plus-icon.svg'
import { ReactComponent as BlueCloseIcon } from 'src/assets/images/blue-close-icon.svg'
import ActionModal from 'src/components/ActionModal'
import { errorHandler } from 'src/utils/errorHandler'
import { AdminInviteUserValidationSchema } from 'src/utils/validationSchema'
import Toast from 'src/components/Toast'
import { InlineLoader } from 'src/components/Loader'
import EmptyState from 'src/components/EmptyState'
import BackNav from 'src/components/BackNav'
import UsersListingsTable from './table'
import { isMobile } from 'src/utils/mediaQueries'
import {
    getProviderUsersApi,
    getProviderGroupsApi,
    getActiveProviderGroupsApi,
} from 'src/api/providers/users'
import InviteAdminUser from 'src/pages/Admin/Providers/Active/InviteUserModal'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateAdminUsersFilters,
    updatedAdminUsersFilters,
} from 'src/redux/reducers/admin/users/listing'
import { convertQueryParamsToObject, getKeyByValue } from 'src/utils/formatting'
import {
    applyDynamicPermissionsToAdminUser,
    permissionsControl,
} from 'src/utils/permissionsControl'
import CustomToolTip from 'src/components/CustomToolTip'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
    removeAllQueryParamsFromUrl,
    removeQueryParamFromUrl,
} from 'src/utils/urlTracker'

const AdminUsersListing = () => {
    const usersTabs = {
        1: 'users',
        2: 'groups',
    }
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const filterQuery = getSearchParamsFromUrl()
    const defaultQueries = useSelector(updatedAdminUsersFilters)
    const dispatch = useDispatch()
    const { TabPane } = Tabs
    const history = useHistory()
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(usersTabs, filterQuery.tab) ||
            defaultQueries.current_tab ||
            '1',
    )
    const [currentPage, setCurrentPage] = useState(
        Number(filterQuery.page) || Number(defaultQueries.page) || 1,
    )
    const [groupCurrentPage, setGroupCurrentPage] = useState(
        Number(filterQuery.page) || Number(defaultQueries.page) || 1,
    )
    const [totalPages, setTotalPages] = useState(1)
    const [groupTotalPages, setGroupTotalPages] = useState(1)
    const [count, setCount] = useState(0)
    const [groupCount, setGroupCount] = useState(0)
    const [inputs, setInputs] = useState({
        startDate: filterQuery.startDate || defaultQueries.start_date || null,
        endDate: filterQuery.endDate || defaultQueries.end_date || null,
    })
    const [showCalendar, setShowCalendar] = useState(0)
    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        inputs.endDate === null ? null : new Date(inputs.endDate),
    )
    const [statusValue, setStatusValue] = useState([
        filterQuery.state || defaultQueries.state || 'active',
    ])
    const [showInviteModal, setShowInviteModal] = useState(false)
    const [showInviteModalComponent, setShowInviteModalComponent] =
        useState(false)
    const [inviteErrors, setInviteErrors] = useState({})
    const [inviteSuccessModal, setInviteSuccessModal] = useState(false)
    const [addedUsers, setAddedUsers] = useState([
        {
            id: uuidv4(),
            email: '',
            groups: [],
        },
    ])
    const [selectedUsers, setSelectedUsers] = useState([
        { id: uuidv4(), email: '', groups: '' },
    ])
    const [errors, setErrors] = useState({})
    const [users, setUsers] = useState([])
    const [groupsData, setGroupsData] = useState([])
    const [toastError, setToastError] = useState(false)
    const [filterMode, setFilterMode] = useState(
        defaultQueries.filter_mode === 'true' ? true : false,
    )
    const [errorMessage, setErrorMessage] = useState('')
    const [searchText, setSearchText] = useState(
        filterQuery.search || defaultQueries.name || '',
    )
    const [fetchedGroups, setFetchedGroups] = useState([])
    const usersTab = currentTab === '1'
    const currentTabUrl =
        currentTab === '1' ? 'users' : currentTab === '2' ? 'groups' : ''
    let reset

    const userInfo = decodeUserInfo()
    const isProvider = userInfo.isAdminWorkspaceUser === 'false'
    const canAddGroup =
        applyDynamicPermissionsToAdminUser &&
        permissionsControl(['can_create_role'])
    const canInviteAdminUser = permissionsControl(['can_invite_admin_user'])

    const handleAddUsers = () => {
        setSelectedUsers([
            ...selectedUsers,
            {
                id: uuidv4(),
                email: '',
                groups: '',
            },
        ])
    }
    const handleRemoveUsers = id => {
        const filteredUsers = selectedUsers.filter(user => user.id !== id)
        setSelectedUsers(filteredUsers)
    }

    const closeModal = () => {
        setErrors({})
        setShowInviteModal(false)
        setSelectedUsers([
            {
                id: uuidv4(),
                email: '',
                groups: '',
            },
        ])
    }

    //fetch users
    const { refetch: adminUserRefetch, isFetching: adminUserFetching } =
        useQuery(
            ['getAdminUsers', currentPage],
            () =>
                isProvider
                    ? getProviderUsersApi(
                          searchText.length > 0 ? 1 : currentPage,
                          reset ? '' : searchText,
                          reset
                              ? ''
                              : currentStartDate
                              ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                              : '',
                          reset
                              ? ''
                              : currentEndDate
                              ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                              : '',
                          reset ? '' : statusValue,
                      )
                    : getAdminUsers(
                          searchText.length > 0 ? 1 : currentPage,
                          reset ? '' : searchText,
                          reset
                              ? ''
                              : currentStartDate
                              ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                              : '',
                          reset
                              ? ''
                              : currentEndDate
                              ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                              : '',
                          reset ? '' : statusValue,
                      ),
            {
                enabled: true,
                retry: false,
                onSuccess: data => {
                    const queries = data?.config?.url
                        .split('?')[1]
                        ?.replaceAll('=', ':')
                        ?.split('&')
                    dispatch(
                        updateAdminUsersFilters(
                            convertQueryParamsToObject([
                                ...queries,
                                `current_tab:${currentTab}`,
                                `filter_mode:${filterMode}`,
                            ]),
                        ),
                    )
                    setUsers(data?.data?.data)
                    setCount(data?.data?.count)
                    setTotalPages(data?.data?.total_pages)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    //fetch groups
    const { refetch: adminGroupRefetch, isFetching: adminGroupFetching } =
        useQuery(
            ['getAdminGroups', groupCurrentPage],
            () =>
                isProvider
                    ? getProviderGroupsApi(
                          searchText.length > 0 ? 1 : groupCurrentPage,
                          reset ? '' : searchText,
                          reset
                              ? ''
                              : currentStartDate
                              ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                              : '',
                          reset
                              ? ''
                              : currentEndDate
                              ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                              : '',
                          reset ? '' : statusValue,
                      )
                    : getAdminGroups(
                          searchText.length > 0 ? 1 : groupCurrentPage,
                          reset ? '' : searchText,
                          reset
                              ? ''
                              : currentStartDate
                              ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                              : '',
                          reset
                              ? ''
                              : currentEndDate
                              ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                              : '',
                          reset ? '' : statusValue,
                      ),
            {
                enabled: true,
                retry: false,
                onSuccess: data => {
                    const queries = data?.config?.url
                        .split('?')[1]
                        ?.replaceAll('=', ':')
                        ?.split('&')
                    dispatch(
                        updateAdminUsersFilters(
                            convertQueryParamsToObject([
                                ...queries,
                                `current_tab:${currentTab}`,
                                `filter_mode:${filterMode}`,
                            ]),
                        ),
                    )
                    setGroupsData(formattedGroupsData(data?.data?.data))
                    setGroupCount(data?.data?.count)
                    setGroupTotalPages(data?.data?.total_pages)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    // get list of groups (permission roles)
    const { refetch: activeGroupsRefetch } = useQuery(
        ['get-active-provider-groups'],
        () => getActiveProviderGroupsApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const groupData = data?.data?.data
                for (let i = 0; i < groupData?.length; i++) {
                    setFetchedGroups(prev => [
                        ...prev,
                        {
                            label: groupData?.[i]?.name,
                            value: groupData?.[i]?.name,
                            id: groupData?.[i]?.id,
                        },
                    ])
                }
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        activeGroupsRefetch()
    }, [])

    // useeffect to make an api call when a another tab is clicked, also resets page number to 1
    useEffect(() => {
        usersTab ? setTotalPages(0) : setGroupTotalPages(0)
        usersTab ? setUsers([]) : setGroupsData([])
        usersTab ? adminUserRefetch() : adminGroupRefetch()
        addQueryParamsToUrl({
            tab: currentTabUrl,
        })
    }, [currentTab])

    const resetValues = () => {
        reset = true
        setSearchText('')
        setFilterMode(false)
        statusValue.length = 0
        setInputs(prev => ({
            ...prev,
            endDate: null,
            startDate: null,
        }))
        setCurrentStartDate(null)
        setCurrentEndDate(null)
    }

    const handleReset = () => {
        resetValues()
        removeAllQueryParamsFromUrl()
        setDrawerVisible(false)
        usersTab ? adminUserRefetch() : adminGroupRefetch()
    }

    const handleAddFilter = () => {
        usersTab ? setCurrentPage(1) : setGroupCurrentPage(1)
        setTimeout(() => {
            usersTab ? adminUserRefetch() : adminGroupRefetch()
        }, 500)
        usersTab ? setCount(0) : setGroupCount(0)
        setFilterMode(true)
        setDrawerVisible(false)
        addQueryParamsToUrl({
            startDate: currentStartDate
                ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                : '',
            endDate: currentEndDate
                ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                : '',
            status: statusValue?.[0],
            page: 1,
        })
    }

    const selectedData = selectedUsers.map(item => ({
        email: item.email,
        user_types: [item.groups?.split(' ').join('_').toUpperCase()],
    }))
    // add user api post request
    const { refetch: addUsersRefetch, isLoading: addUsersLoading } = useQuery(
        ['invite-provider-users'],
        () => inviteAdminUserApi(selectedData),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeModal()
                setInviteSuccessModal(true)
            },
            onError: error => {
                if (
                    error?.response?.data?.errors?.admin_user?.[0]?.email?.[0]
                ) {
                    setErrorMessage(
                        error?.response?.data?.errors?.admin_user?.[0]
                            ?.email?.[0],
                    )
                } else {
                    setErrorMessage(errorHandler(error?.response?.data))
                }
            },
        },
    )

    const closeInviteModal = () => {
        setInviteErrors({})
        setAddedUsers([
            {
                id: uuidv4(),
                email: '',
                groups: [],
            },
        ])
        setShowInviteModalComponent(false)
    }

    const sendInvite = () => {
        AdminInviteUserValidationSchema.validate(
            { users: selectedUsers },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                setErrors({})
                addUsersRefetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    const showDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'endDate') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }

    const getSelectedStatusValue = () => {
        const statementOfAccountCheckboxes = document.querySelectorAll(
            `input[name="statementOfAccountType"]:checked`,
        )

        let statementOfAccountValues = []

        statementOfAccountCheckboxes.forEach(checkbox => {
            statementOfAccountValues.push(checkbox.value)
        })

        setStatusValue(statementOfAccountValues)
    }

    const handleSearchInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
        e.target.value.length === 0 ? resetValues() : ''
        e.target.value.length === 0
            ? usersTab
                ? adminUserRefetch()
                : adminGroupRefetch()
            : ''
        if (!e.target.value) {
            removeQueryParamFromUrl('search')
        }
    }

    const handleSearch = () => {
        usersTab ? setTotalPages(1) : setGroupTotalPages(1)
        usersTab ? setCurrentPage(1) : setGroupCurrentPage(1)
        usersTab ? setCount(0) : setGroupCount(0)
        usersTab ? adminUserRefetch() : adminGroupRefetch()
        setFilterMode(searchText.length > 0 ? true : false)
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
            if (event.target.value.length > 0) {
                addQueryParamsToUrl({
                    search: event.target.value,
                    page: 1,
                })
            } else {
                removeQueryParamFromUrl('search')
                addQueryParamsToUrl({
                    page: 1,
                })
            }
        }
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const updatePage = value => {
        usersTab ? setCurrentPage(value) : setGroupCurrentPage(value)
        addQueryParamsToUrl({
            page: Number(value),
        })
    }

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={usersTab ? currentPage : groupCurrentPage}
                current={usersTab ? currentPage : groupCurrentPage}
                total={parseInt(usersTab ? totalPages : groupTotalPages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => updatePage(Number(value))}
            />
        )
    }

    //menu to Add group and Invite user
    const menu = (
        <Menu className="menu">
            <Menu.Item
                key={1}
                onClick={() => {
                    isProvider
                        ? (window.location.href = '/app/users/add-group')
                        : (window.location.href = '/admin/users/add-group')
                }}
                disabled={!canAddGroup}
            >
                <CustomToolTip
                    showTooltip={!canAddGroup}
                    text="You are not authorised to perform this action"
                    arrowPositionCenter
                >
                    <a className="menuItem">
                        {isProvider ? 'Add Custom Group' : 'Add Group'}
                    </a>
                </CustomToolTip>
            </Menu.Item>
            {(permissionsControl(['can_invite_user']) ||
                canInviteAdminUser) && (
                <Menu.Item
                    key={2}
                    onClick={() => {
                        isProvider
                            ? setShowInviteModalComponent(true)
                            : setShowInviteModal(true)
                    }}
                >
                    <a className="menuItem">Invite Users</a>
                </Menu.Item>
            )}
        </Menu>
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            setErrorMessage('')
        }, 4100)
        return () => clearTimeout(timer)
    }, [errorMessage])

    return (
        <div className="AULContainer">
            <ActionModal
                actionModalOpen={inviteSuccessModal}
                headerText={'Invitation Successfully Sent'}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                closeModal={() => setInviteSuccessModal(false)}
                actionHandler={() => setInviteSuccessModal(false)}
                actionType="success"
                actionText="Okay, Got it"
                loading={''}
                noCancelBtn={true}
                noBackLink
            />
            <InviteAdminUser
                selectedUsers={addedUsers}
                setSelectedUsers={setAddedUsers}
                inviteErrors={inviteErrors}
                setInviteErrors={setInviteErrors}
                showInviteModal={showInviteModalComponent}
                groups={fetchedGroups}
                closeInviteModal={closeInviteModal}
                setSuccessModal={() => setInviteSuccessModal(true)}
                isProviderWorkspace={true}
            />
            {/*todo: this should be replaced with the invite users component when groups is being switched from dummy data*/}
            <SecondaryModal
                type="recommendedEnergy"
                modalWidth="624px"
                modalHeight={
                    windowWidth <= 1280 && windowHeight <= 800
                        ? '610px'
                        : '726px'
                }
                showModal={showInviteModal}
                closable={true}
                content={
                    <div className="AULInviteWrapper">
                        <h2 className="AULInviteTitle">Invite Users</h2>
                        <p className="AULInviteSubTitle">
                            Enter the details of the installed solution{' '}
                        </p>
                        <div className="AULInviterUserScrollWrapper">
                            <Toast
                                messageType="error"
                                message={errorMessage}
                                position="absolute"
                                top="10px"
                            />
                            <div className="AULInviteUserWrapper">
                                {selectedUsers.map((user, i) => (
                                    <div key={i}>
                                        <h3 className="AULInviteUserTitle">
                                            User {i + 1}
                                        </h3>
                                        <InputFields
                                            title="Email Address"
                                            name={`users[${i}].email`}
                                            value={user.email}
                                            inputValue={user.email}
                                            handleChange={event => {
                                                user.email = event.target.value
                                                setSelectedUsers([
                                                    ...selectedUsers,
                                                ])
                                            }}
                                            errorMessage={
                                                errors[`users[${i}].email`]
                                            }
                                        />
                                        <SelectField
                                            initialOption="Select Group"
                                            selectWidth="100%"
                                            dropDownWidth="280px"
                                            values={groups}
                                            name={`users[${i}].groups`}
                                            dropdownMatchSelectWidth={false}
                                            selectedValue={user?.groups}
                                            currentSelected={user?.groups}
                                            handleChange={(value, name) => {
                                                user.groups = name
                                                setSelectedUsers([
                                                    ...selectedUsers,
                                                ])
                                            }}
                                            errorMessage={
                                                errors[`users[${i}].groups`]
                                            }
                                        />
                                        {selectedUsers.length > 1 && (
                                            <div
                                                onClick={() =>
                                                    handleRemoveUsers(user.id)
                                                }
                                                className="AULRemoveUserWrapper"
                                            >
                                                <BlueCloseIcon />
                                                <p className="AULRemoveUserText">
                                                    Remove User
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="AULAddUserWrapper">
                                <ButtonComponent
                                    type="secondary"
                                    btnWidth="117px"
                                    btnHeight="40px"
                                    handleClick={handleAddUsers}
                                >
                                    <PlusIcon className="AULPlusIcon" /> Add
                                    User
                                </ButtonComponent>
                            </div>
                        </div>
                        <div className="AULInviteUserBtnWrapper">
                            <ButtonComponent
                                type="primary"
                                handleClick={sendInvite}
                            >
                                {addUsersLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Send Invite'
                                )}
                            </ButtonComponent>
                        </div>
                    </div>
                }
                onCancel={closeModal}
            />
            {/* slide out drawer, only visible on click */}
            <Drawer
                placement="right"
                width="367px"
                onClose={showDrawer}
                visible={drawerVisible}
                closable={isMobile}
                bodyStyle={{
                    minHeight: isMobile ? 'auto' : '100vh',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: isMobile ? 'flex-start' : 'space-between',
                }}
            >
                <div>
                    <h2 className="ARLFilterHeader">Filter Requests</h2>
                    {/* filtering by date */}
                    <h4 className="ARLDateFilterHeader">
                        Filter By Created Date
                    </h4>
                    <div className="ARLDateFieldsWrapper">
                        <div className="ARLDateField">
                            <DateField
                                placeholder="Start Date"
                                name="startDate"
                                caretStyle="caretStyle"
                                dateValue={
                                    inputs.startDate === null
                                        ? null
                                        : new Date(inputs.startDate)
                                }
                                handleDateChange={(name, date) =>
                                    handleDateChange(name, date)
                                }
                                openCalendar={showCalendar === 1 ? true : false}
                                closeCalendarHandler={() =>
                                    setShowCalendar(null)
                                }
                                openCalendarHandler={() => setShowCalendar(1)}
                                setHandleDateChange={name =>
                                    setHandleDateChange(name)
                                }
                            />
                        </div>

                        <div className="ARLDateField">
                            <DateField
                                placeholder="End Date"
                                name="endDate"
                                calendarClassName="AULEndDateFilter"
                                caretStyle="caretStyle"
                                dateValue={
                                    inputs.endDate === null
                                        ? null
                                        : new Date(inputs.endDate)
                                }
                                handleDateChange={(name, date) =>
                                    handleDateChange(name, date)
                                }
                                openCalendar={showCalendar === 2 ? true : false}
                                closeCalendarHandler={() =>
                                    setShowCalendar(null)
                                }
                                openCalendarHandler={() => setShowCalendar(2)}
                                setHandleDateChange={name =>
                                    setHandleDateChange(name)
                                }
                            />
                        </div>
                    </div>

                    {/* filtering by statement of account */}
                    {usersTab && (
                        <>
                            <h4 className="ARLStatementFilterHeader">
                                User status
                            </h4>
                            <div className="ARLStatementFilterWrapper">
                                {userStatus.map((data, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                getSelectedStatusValue()
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                name="statementOfAccountType"
                                                id={data.id}
                                                value={data.id}
                                                style={{
                                                    display: 'none',
                                                }}
                                            />
                                            <label
                                                htmlFor={data.id}
                                                className="ARLStatementLabel"
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                                                        fill={
                                                            statusValue.find(
                                                                query =>
                                                                    query ===
                                                                    data.id,
                                                            )
                                                                ? 'var(--blue)'
                                                                : ' var(--grey)'
                                                        }
                                                    />
                                                </svg>
                                                <p>{data.label}</p>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}
                </div>
                <div className="AULFilterButtonWrapper">
                    <AppButton
                        btnBgColor="#E2EEFF"
                        btnTextColor="#004AAD"
                        btnWidth={160}
                        btnHeight={56}
                        handleClick={handleReset}
                    >
                        Reset Filter
                    </AppButton>
                    <AppButton
                        btnTextColor="#FFFFFF"
                        btnBgColor="#004AAD"
                        btnWidth={160}
                        btnHeight={56}
                        handleClick={handleAddFilter}
                        disabled={adminUserFetching || adminGroupFetching}
                    >
                        {adminUserFetching || adminGroupFetching ? (
                            <InlineLoader />
                        ) : (
                            'Apply Filter'
                        )}
                    </AppButton>
                </div>
            </Drawer>
            <div className="AULHeaderWrapper">
                {filterMode ? (
                    <div className="NavPageTitle">
                        <BackNav
                            title={`Back to ${usersTab ? 'Users' : 'Groups'}`}
                            onClick={() => {
                                history.push(
                                    isProvider
                                        ? `/app/users/listing?tab=${currentTabUrl}`
                                        : `/admin/users/listing?tab=${currentTabUrl}`,
                                )
                                resetValues()
                                usersTab
                                    ? adminUserRefetch()
                                    : adminGroupRefetch()
                            }}
                        />
                    </div>
                ) : (
                    <div className="AULSpaceBetween">
                        <p className="NavPageTitle">
                            {`${isProvider ? 'Workspace' : 'Admin'} ${
                                usersTab ? 'Users' : 'Groups'
                            }`}
                        </p>
                        <div className="AULMobileDropdown">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <a onClick={e => e.preventDefault()}>
                                    <ButtonComponent
                                        btnBgColor="var(--blue)"
                                        btnTextColor="var(--white)"
                                        btnWidth="40px"
                                        btnHeight="40px"
                                    >
                                        <span
                                            style={{
                                                position: 'relative',
                                                top: '2.5px',
                                            }}
                                        >
                                            <PlusIcon />
                                        </span>
                                    </ButtonComponent>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                )}
            </div>
            <div className="AULWrapper">
                <div className="AULSpaceBetween">
                    <div style={{ display: 'flex' }}>
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            borderRadius="10px"
                            inputWidth="350px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            inputBackground="#F6F6F6"
                            iconType="search"
                            prefilled
                            inputPlaceholder="Search"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleSearchInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleOnBlur}
                        />
                        <div
                            className="AULFilterBox"
                            style={{ border: '1px solid #e5e5e5' }}
                            onClick={() => setDrawerVisible(true)}
                        >
                            <svg
                                width="12"
                                height="15"
                                viewBox="0 0 12 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginTop: 4 }}
                            >
                                <path
                                    d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                    fill={'#1D2A30'}
                                />
                            </svg>

                            <p className="AULFilterText">Filters</p>
                        </div>
                    </div>
                    {!filterMode && (
                        <div className="AULBtnWrapper">
                            <ButtonComponent
                                type="secondary"
                                handleClick={() => {
                                    isProvider
                                        ? (window.location.href =
                                              '/app/users/add-group')
                                        : (window.location.href =
                                              '/admin/users/add-group')
                                }}
                                disabled={!canAddGroup}
                                toolTipText="You are not allowed to perform this action"
                                showTooltip={!canAddGroup}
                            >
                                {isProvider ? 'Add Custom Group' : 'Add Group'}
                            </ButtonComponent>
                            {(permissionsControl(['can_invite_user']) ||
                                canInviteAdminUser) && (
                                <ButtonComponent
                                    type="primary"
                                    handleClick={() => {
                                        isProvider
                                            ? setShowInviteModalComponent(true)
                                            : setShowInviteModal(true)
                                    }}
                                >
                                    Invite Users
                                </ButtonComponent>
                            )}
                        </div>
                    )}
                </div>
                {filterMode && (
                    <h1 className="AdminPaymentPlanFilterText">
                        Filter Results ({usersTab ? count : groupCount})
                    </h1>
                )}
                <div className="AULTabWrapper">
                    {filterMode ? (
                        (usersTab ? users.length : groupsData.length) > 0 ? (
                            <>
                                <div className={'AULTablewWrapperGroups'}>
                                    <div className="AULTableHeader">
                                        <span>
                                            {usersTab ? 'Full' : 'Group'} Name
                                        </span>
                                        <span>
                                            {usersTab
                                                ? 'Phone Number'
                                                : 'Permissions'}
                                        </span>
                                        <span>
                                            {usersTab ? 'Active' : 'Users'}
                                        </span>
                                        <span>
                                            {usersTab
                                                ? 'Email Address'
                                                : 'Created Time'}
                                        </span>
                                        <span>Actions</span>
                                    </div>
                                    {((
                                        usersTab
                                            ? adminUserFetching
                                            : adminGroupFetching
                                    )
                                        ? [...Array(5)]
                                        : usersTab
                                        ? isProvider
                                            ? users?.map(user => user.user)
                                            : users
                                        : groupsData
                                    ).map(item => (
                                        <div
                                            key={item?.id}
                                            className={`AULTableContentGroups ${
                                                !usersTab
                                                    ? 'AULTableContentGroupsSecondary'
                                                    : ''
                                            }`}
                                        >
                                            {(
                                                usersTab
                                                    ? adminUserFetching
                                                    : adminGroupFetching
                                            ) ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <div>
                                                    <p>
                                                        {usersTab
                                                            ? 'First/Last Name'
                                                            : 'Group Name'}
                                                    </p>
                                                    <span>
                                                        {usersTab
                                                            ? `${item?.first_name} ${item?.last_name}`
                                                            : item?.group_name}
                                                    </span>
                                                </div>
                                            )}
                                            {(
                                                usersTab
                                                    ? adminUserFetching
                                                    : adminGroupFetching
                                            ) ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <span>
                                                    {usersTab
                                                        ? extractEmailOrNumber(
                                                              item?.phone_number,
                                                          )
                                                        : item?.permission}
                                                </span>
                                            )}
                                            {(
                                                usersTab
                                                    ? adminUserFetching
                                                    : adminGroupFetching
                                            ) ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '70px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <div>
                                                    <p>
                                                        {usersTab
                                                            ? 'Users'
                                                            : ''}
                                                    </p>
                                                    <span>
                                                        {usersTab
                                                            ? item?.state ===
                                                                  'ACTIVE' &&
                                                              !item?.user_types?.includes(
                                                                  'DEACTIVATED_USER',
                                                              )
                                                                ? 'Yes'
                                                                : item?.state ===
                                                                      'ACTIVE' &&
                                                                  item?.user_types?.includes(
                                                                      'DEACTIVATED_USER',
                                                                  )
                                                                ? 'No'
                                                                : 'No'
                                                            : item?.users}
                                                    </span>
                                                </div>
                                            )}
                                            {(
                                                usersTab
                                                    ? adminUserFetching
                                                    : adminGroupFetching
                                            ) ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <div>
                                                    <p>
                                                        {usersTab
                                                            ? 'Email Address'
                                                            : ''}
                                                    </p>
                                                    <span>
                                                        {usersTab
                                                            ? extractEmailOrNumber(
                                                                  item?.email,
                                                              )
                                                            : item?.created_time}
                                                    </span>
                                                </div>
                                            )}
                                            {(
                                                usersTab
                                                    ? adminUserFetching
                                                    : adminGroupFetching
                                            ) ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <span>
                                                    <ButtonComponent
                                                        type="XSmall"
                                                        handleClick={() => {
                                                            usersTab
                                                                ? history.push(
                                                                      isProvider
                                                                          ? `/app/user/${item?.id}`
                                                                          : `/admin/user/${item?.id}`,
                                                                  )
                                                                : history.push(
                                                                      isProvider
                                                                          ? `/app/group/${item?.id}`
                                                                          : `/admin/group/${item?.id}`,
                                                                  )
                                                        }}
                                                        disabled={
                                                            usersTab &&
                                                            item?.state ===
                                                                'DELETED'
                                                                ? true
                                                                : usersTab &&
                                                                  !permissionsControl(
                                                                      [
                                                                          'can_view_user_details',
                                                                      ],
                                                                  )
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        View
                                                    </ButtonComponent>
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                {(usersTab
                                    ? users.length > 0
                                    : groupsData.length > 0) &&
                                (usersTab
                                    ? !adminUserFetching
                                    : !adminGroupFetching) ? (
                                    <div className="AULPaginationWrapper">
                                        {paginationBlock()}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            <EmptyState
                                subTitle={'There are no results, try again!'}
                            />
                        )
                    ) : (usersTab ? users.length < 1 : groupsData.length < 1) &&
                      (usersTab ? !adminUserFetching : !adminGroupFetching) ? (
                        <EmptyState
                            subTitle={'There are no results, try again!'}
                        />
                    ) : (
                        <Tabs
                            activeKey={currentTab}
                            style={{ marginTop: 38 }}
                            onChange={values => {
                                setCurrentTab(values)
                                usersTab
                                    ? setGroupCurrentPage(1)
                                    : setCurrentPage(1)
                                addQueryParamsToUrl({
                                    page: 1,
                                    tab: values,
                                })
                                resetValues()
                            }}
                        >
                            {toastError && (
                                <Toast
                                    messageType="error"
                                    message={errorMessage}
                                />
                            )}
                            <TabPane
                                tab={
                                    adminUserFetching ? (
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{
                                                width: '125px',
                                                marginRight: '10px',
                                            }}
                                        />
                                    ) : (
                                        `Users ${'(' + count + ')'}`
                                    )
                                }
                                key="1"
                            >
                                <UsersListingsTable
                                    adminUserFetching={adminUserFetching}
                                    tableData={
                                        isProvider
                                            ? users.map(user => user.user)
                                            : users
                                    }
                                    isProvider={isProvider}
                                />
                            </TabPane>
                            <TabPane
                                tab={
                                    adminGroupFetching ? (
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{
                                                width: '125px',
                                                marginRight: '10px',
                                            }}
                                        />
                                    ) : (
                                        `Groups ${'(' + groupCount + ')'}`
                                    )
                                }
                                key="2"
                            >
                                <div className="AULTableWrapperGroups">
                                    {isProvider ? (
                                        <div className="AULTableHeader">
                                            <span> Name</span>
                                            <span
                                                style={{
                                                    textAlign: 'center',
                                                    marginLeft: '20px',
                                                }}
                                            >
                                                No. of Users
                                            </span>
                                            <span
                                                style={{
                                                    width: '120px',
                                                }}
                                            >
                                                Status
                                            </span>
                                            <span
                                                style={{
                                                    marginLeft: '30px',
                                                }}
                                            >
                                                Date Created
                                            </span>
                                            <span>Actions</span>
                                        </div>
                                    ) : (
                                        <div className="AULTableHeader">
                                            <span>Group Name</span>
                                            <span>Permissions</span>
                                            <span
                                                style={{
                                                    minWidth: '120px',
                                                }}
                                            >
                                                Users
                                            </span>
                                            <span>Created Time</span>
                                            <span>Actions</span>
                                        </div>
                                    )}

                                    {(adminGroupFetching
                                        ? [...Array(5)]
                                        : groupsData
                                    ).map(item => (
                                        <div
                                            key={item?.id}
                                            className={`AULTableContentGroups ${
                                                !usersTab
                                                    ? 'AULTableContentGroupsSecondary'
                                                    : ''
                                            }`}
                                        >
                                            {adminGroupFetching ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        display:
                                                            isProvider &&
                                                            'flex',
                                                        width:
                                                            isProvider &&
                                                            !isMobile &&
                                                            '200px',
                                                    }}
                                                >
                                                    <div className="GroupNameWrapper">
                                                        <p>GROUP NAME</p>
                                                        <span
                                                            style={{
                                                                marginRight:
                                                                    isProvider &&
                                                                    '10px',
                                                            }}
                                                        >
                                                            {item?.group_name}
                                                        </span>
                                                    </div>
                                                    {isProvider &&
                                                        item?.is_custom && (
                                                            <span className="CustomWrapperStyle">
                                                                <p>custom</p>
                                                            </span>
                                                        )}
                                                </div>
                                            )}
                                            {adminGroupFetching ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <span
                                                    style={{
                                                        textAlign:
                                                            isProvider &&
                                                            'center',
                                                        width:
                                                            isProvider &&
                                                            '95px',
                                                    }}
                                                >
                                                    {isProvider
                                                        ? item?.users
                                                        : item?.permissions}
                                                </span>
                                            )}
                                            {adminGroupFetching ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        width:
                                                            isProvider &&
                                                            '150px',
                                                    }}
                                                >
                                                    <p>
                                                        {isProvider && !isMobile
                                                            ? 'STATUS'
                                                            : 'USERS'}
                                                    </p>
                                                    <span
                                                        style={{
                                                            minWidth: '120px',
                                                        }}
                                                    >
                                                        {isProvider && !isMobile
                                                            ? item?.status
                                                            : item?.users}
                                                    </span>
                                                </div>
                                            )}
                                            {adminGroupFetching ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <div>
                                                    <p>
                                                        {isProvider
                                                            ? 'STATUS'
                                                            : 'CREATED TIME'}
                                                    </p>
                                                    <span
                                                        style={{
                                                            textAlign:
                                                                isProvider &&
                                                                'center',
                                                            width:
                                                                isProvider &&
                                                                '150px',
                                                            fontSize:
                                                                isMobile &&
                                                                '13px',
                                                        }}
                                                    >
                                                        {isProvider && isMobile
                                                            ? item.status
                                                            : item?.created_time}
                                                    </span>
                                                </div>
                                            )}
                                            {adminGroupFetching ? (
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '125px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            ) : (
                                                <span>
                                                    <ButtonComponent
                                                        type="XSmall"
                                                        handleClick={() =>
                                                            history.push(
                                                                isProvider
                                                                    ? `/app/group/${item?.id}`
                                                                    : `/admin/group/${item?.id}`,
                                                            )
                                                        }
                                                        disabled={
                                                            usersTab &&
                                                            item?.state ===
                                                                'DELETED'
                                                                ? true
                                                                : usersTab &&
                                                                  !permissionsControl(
                                                                      [
                                                                          'can_view_user_details',
                                                                      ],
                                                                  )
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        View
                                                    </ButtonComponent>
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                    {groupsData.length > 0 &&
                                    !adminGroupFetching ? (
                                        <div className="AULPaginationWrapper">
                                            {paginationBlock()}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    )}
                </div>
                {users.length > 0 &&
                !adminUserFetching &&
                usersTab &&
                !filterMode ? (
                    <div className="AULPaginationWrapper">
                        {paginationBlock()}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default AdminUsersListing
