import UpdateGroupPermissions from 'src/components/UpdateGroupPermissions'

const UpdateAdminGroupPermissions = () => {
    return (
        <UpdateGroupPermissions
            type={'admin'}
            module={'existing-admin-group'}
        />
    )
}
export default UpdateAdminGroupPermissions
