import '../consumerkyc.scss'
import { useState } from 'react'
import { ReactComponent as LightBulb } from 'src/assets/images/lightbulb.svg'
import PropTypes from 'prop-types'
import arrow from 'src/assets/images/arrowDown.svg'
import SelectedSolutionCard from '../../AccountSetupOverview/components/SelectedSolutionCard'
import EnergyInfo from './EnergyInfo'
import Button from 'src/components/Button'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useTypeMapping } from '../data'
import PageHeading from './PageHeading'

const SelectedSolutionSection = ({
    useType,
    handleShowVideo,
    estimation,
    goBackToSystem,
    handlePackageChange,
    handleEditInfo,
    handleUseTypeChange,
    showPageHeading,
}) => {
    const [isOpen, setIsOpen] = useState(Array(2).fill(false))
    const { isMobile } = useMediaQueries()

    const toggleBox = index => {
        setIsOpen(prevOpen => {
            return prevOpen.map((open, i) => {
                index
                if (i === index) {
                    return !open
                }
                return false
            })
        })
    }

    const dropdownOptions = [
        {
            title: 'Your Package Details',
            component: (
                <SelectedSolutionCard
                    estimation={estimation}
                    goBackToSystem={goBackToSystem}
                    planType={estimation?.payment_plan}
                    handlePackageChange={handlePackageChange}
                />
            ),
        },
        {
            title: 'Your Energy Info',
            component: (
                <EnergyInfo
                    energyInfo={estimation?.energy_info}
                    paymentPlan={estimation?.payment_plan}
                    handleEditInfo={handleEditInfo}
                />
            ),
        },
    ]

    const DetailsDropdown = ({ title, index, children }) => {
        return (
            <div className="DetailsDropdownContainer">
                <div
                    className={`DetailsDropdown ${
                        isOpen[index] ? 'isOpen' : ''
                    }`}
                    onClick={() => toggleBox(index)}
                >
                    <p>{title}</p>
                    <div>
                        <img
                            src={arrow}
                            alt="arrow"
                            className={
                                isOpen[index]
                                    ? 'TopArrowOpen'
                                    : 'TopArrowClosed'
                            }
                        />
                    </div>
                </div>
                {isOpen[index] && <>{children}</>}
            </div>
        )
    }

    DetailsDropdown.propTypes = {
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        children: PropTypes.node,
    }

    return (
        <div className="LeftContainerSection">
            {showPageHeading && (
                <PageHeading handleShowVideo={handleShowVideo} />
            )}
            <div className="Container">
                <div className="SelectedSolutionContainer">
                    <div className="SelectedSolutionNav">
                        <h5>Your Selected Solution</h5>
                        <div
                            className={'ASOTextIconBtn'}
                            role={'button'}
                            onClick={handlePackageChange}
                        >
                            <LightBulb
                                role={'presentation'}
                                className={'ASOSSvg'}
                            />
                            <p>Change Package</p>
                        </div>
                    </div>

                    <div>
                        {dropdownOptions.map((option, index) => (
                            <DetailsDropdown
                                title={option.title}
                                key={index}
                                index={index}
                            >
                                {option.component}
                            </DetailsDropdown>
                        ))}
                    </div>
                    <p className="Questions">
                        Have questions? <span>Chat with us</span>
                    </p>
                </div>

                <div className="ChangeUseType">
                    <p>
                        Is this solution for{' '}
                        {useTypeMapping[useType] === 'residential'
                            ? 'business'
                            : 'residential'}{' '}
                        purposes?
                    </p>
                    <Button
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#fff'}
                        btnWidth={isMobile ? '186px' : '140px'}
                        btnHeight={'29px'}
                        fontFamily={'SF-Pro-Display-Medium'}
                        handleClick={handleUseTypeChange}
                    >
                        Switch to{' '}
                        {useTypeMapping[useType] === 'residential'
                            ? 'business'
                            : 'residential'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

SelectedSolutionSection.propTypes = {
    useType: PropTypes.string,
    handleShowVideo: PropTypes.func,
    estimation: PropTypes.object,
    goBackToSystem: PropTypes.func,
    handlePackageChange: PropTypes.func,
    handleEditInfo: PropTypes.func,
    handleUseTypeChange: PropTypes.func,
    showPageHeading: PropTypes.bool,
}

export default SelectedSolutionSection
