import axios from 'src/config/axios'

export const fetchSingleProviderGroupApi = async id => {
    const request = axios.get(`provider/roles/${id}`)
    return request
}

export const fetchProviderGroupUsersApi = async role_id => {
    const request = axios.get(`provider/roles/${role_id}/users`)
    return request
}

export const updateProvidersGroupApi = async (id, data) => {
    const request = axios.patch(`provider/roles/${id}`, data)
    return request
}

export const deactivateProvidersGroupApi = async id => {
    const request = axios.post(`provider/roles/${id}/deactivate`)
    return request
}

export const activateProvidersGroupApi = async id => {
    const request = axios.post(`provider/roles/${id}/activate`)
    return request
}

export const removeProviderGroupUserApi = async (user_id, role_id) => {
    const request = axios.patch(`providers/users/${user_id}`, {
        remove_role_ids: [role_id],
    })
    return request
}

export const fetchPermissionsUnderProviderRoleApi = async role_id => {
    const request = axios(
        `providers/permissions/group-permission?role_id=${role_id}`,
    )
    return request
}

export const fetchPermissionsUnderResourceApi = async resource_id => {
    const request = axios(`providers/permissions?resource_id=${resource_id}`)
    return request
}
export const fetchPermissionsUnderRoleApi = async (
    role_id,
    parent_id,
    paginate = false,
) => {
    const request = axios(
        `provider/roles/${role_id}/permissions?parent_id=${parent_id}&paginate=${paginate}`,
    )
    return request
}

export const getAddedPermissionsUnderProviderRoleApi = async (
    role_id,
    paginate = false,
) => {
    const request = axios(
        `provider/roles/${role_id}/permissions?paginate=${paginate}`,
    )
    return request
}
