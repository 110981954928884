import axios from 'src/config/axios'

export const getGlobalLibraryContributorsApi = async () => {
    const request = axios.get(`admin/global-library-contributors`)
    return request
}

export const getGlobalLibraryUsersApi = async () => {
    const request = axios.get(`admin/global-library-users`)
    return request
}

export const getSubscriptionUsersApi = async () => {
    const request = axios.get(`admin/subscription-models`)
    return request
}

export const getProvidersApi = () => {
    const request = axios.get('admin/provider?paginate=false')
    return request
}

export const deleteGlobalLibraryContributorApi = async id => {
    const request = await axios.delete(
        `admin/global-library-contributors/${id}`,
    )
    return request
}

export const deleteGlobalLibraryUserApi = async id => {
    const request = await axios.delete(`admin/global-library-users/${id}`)
    return request
}

export const deleteSubscriptionUserApi = async id => {
    const request = await axios.delete(`admin/subscription-models/${id}`)
    return request
}

export const addGlobalLibraryContributorApi = id => {
    const data = { provider_id: id }
    const request = axios.post(`admin/global-library-contributors`, data)
    return request
}

export const addGlobalLibraryUserApi = id => {
    const data = { provider_id: id }
    const request = axios.post(`admin/global-library-users`, data)
    return request
}

export const addSubscriptionProviderApi = id => {
    const data = { provider_id: id }
    const request = axios.post(`admin/subscription-models`, data)
    return request
}
