import { useState } from 'react'
import { useMutation } from 'react-query'
import { otpSchema } from 'src/utils/validationSchema'
import { useHistory } from 'react-router-dom'
import {
    initiateSSEConsumerLoginApi,
    verifySSEConsumerLoginApi,
} from 'src/api/shoppingExperience/login'
import { errorHandler } from 'src/utils/errorHandler'
import { encodeUserInfo, setToken } from 'src/utils/auth'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import {
    initiateClusterRepLoginApi,
    verifyClusterRepLoginApi,
} from 'src/api/representative/auth'
import { appTracking } from 'src/utils/appTracker'

const useVerifyLoginOTP = (setShowModal, method, inputs, userType) => {
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [resent, setResent] = useState(false)
    const [action, setAction] = useState('verify-otp') // verify-otp || select-kyc-page-type
    const history = useHistory()
    const isSSELogIn =
        location.pathname === '/consumer/login' ||
        location.pathname === '/' ||
        location.pathname === '/home'
    const isRepLogin = location.pathname === '/rep/login'
    const [kycPageType, setKYCPageType] = useState('') // one-page-kyc || multi-page-kyc

    const verifyLoginApi =
        userType === 'consumer'
            ? verifySSEConsumerLoginApi
            : verifyClusterRepLoginApi
    const loginApi =
        userType === 'consumer'
            ? initiateSSEConsumerLoginApi
            : initiateClusterRepLoginApi

    //resend OTP
    const resendOTPMutation = useMutation({
        mutationFn: () => {
            return loginApi(
                method === 'email'
                    ? { email: inputs.email }
                    : { phone_number: inputs.phone },
            )
        },
        onSuccess: () => {
            setResent(true)
            const timeout = setTimeout(() => setResent(false), [2000])
            return () => clearTimeout(timeout)
        },
        onError: error => {
            setToastError(true)
            setResent(false)
            setErrorMessage(errorHandler(error?.response.data))
        },
    })

    // verify submitted otp
    const verifyLoginMutation = useMutation({
        mutationFn: () => {
            return verifyLoginApi({
                code: otp.join(''),
                ...(method === 'phone'
                    ? { phone_number: inputs.phone }
                    : { email: inputs.email }),
            })
        },
        onSuccess: res => {
            const data = res?.data?.data
            setToken(res?.data?.data?.token?.access)
            encodeUserInfo(res)
            appTracking('', '', '', '', '', '', ['MP'], 'identify', {
                identify: { id: data?.user?.id },
            })
            appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
                people_set: {
                    'User Types': data?.user?.user_types,
                    'Phone Number': data?.user?.phone_number,
                    $email: data?.user?.email,
                    $name: data?.user?.display_name,
                    $avatar: data?.user?.avatar_url,
                    'Payment Plan Type': data?.payment_model,
                    'Consumer Type': data?.is_dep_customer
                        ? 'DEP Cons.'
                        : 'DTC Cons.',
                },
            })
            if (isRepLogin) {
                localStorage.setItem('isClusterRep', JSON.stringify(true))
                history.push('/rep/workspace')
            } else {
                localStorage.removeItem('isClusterRep')
            }

            if (isSSELogIn) {
                localStorage.setItem('isSSEConsumer', JSON.stringify(true))
                const isApproved =
                    res?.data?.data?.approval_status?.toLowerCase() ===
                        'approved' ||
                    data?.application_status === 'TENURE_SELECTION'
                const isClosed =
                    data?.application_status === 'PLAN_COMPLETED' ||
                    data?.application_status === 'ARCHIVED'

                const isOutrightSaleCustomer =
                    data?.payment_model === 'Outright Sale'

                if (isOutrightSaleCustomer && isApproved) {
                    history.push('/consumer/account-setup/overview')
                } else if (isOutrightSaleCustomer && !isApproved) {
                    history.push(
                        '/consumer/account-setup/outright-sale-information',
                    )
                } else {
                    if (isApproved || isClosed) {
                        history.push('/consumer/workspace')
                    } else {
                        if (
                            process.env.REACT_APP_FLAG_SHOW_ONE_PAGE_KYC &&
                            kycPageType !== ''
                        ) {
                            setAction('select-kyc-page-type')
                        } else {
                            history.push('/consumer/account-setup/overview')
                        }
                    }
                }
            } else {
                localStorage.removeItem('isSSEConsumer')
            }
        },
        onError: err => {
            setToastError(true)
            setErrorMessage(errorHandler(err?.response?.data))
        },
    })

    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                verifyLoginMutation.mutate()
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    const fullMethodName = method === 'phone' ? 'phone number' : 'email address'

    const closeModal = () => {
        setShowModal(false)
        setOtp(['', '', '', '', '', ''])
        setOtpError(false)
    }

    useOTPVerify({ otp, handleOtpSubmit })

    const modalValues = action => {
        switch (action) {
            case 'verify-otp':
                return {
                    title: `Verify your ${fullMethodName}`,
                    primaryBtnText: `Verify  ${fullMethodName}`,
                }
            case 'select-kyc-page-type':
                return {
                    title: 'Getting Started on SunFi',
                    primaryBtnText: 'Continue',
                }
        }
    }

    const handleKYCPageSelection = key => {
        setKYCPageType(key)
    }

    const handlePrimaryBtnClick = () => {
        if (action === 'verify-otp') {
            handleOtpSubmit()
        } else if (action === 'select-kyc-page-type') {
            const page =
                kycPageType === 'one-page-kyc' ? 'residential' : 'overview' //TODO: use condition to check use type
            history.push(`/consumer/account-setup/${page}`)
        }
    }

    return {
        otpError,
        toastError,
        errorMessage,
        resent,
        resendOTPMutation,
        fullMethodName,
        closeModal,
        handleOtpSubmit,
        verifyLoginMutation,
        otp,
        setOtp,
        setResent,
        action,
        modalValues: modalValues(action),
        handleKYCPageSelection,
        handlePrimaryBtnClick,
        kycPageType,
    }
}

export default useVerifyLoginOTP
