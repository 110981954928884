import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Skeleton } from 'antd'
import { extractEmailOrNumber } from './data'
import ButtonComponent from 'src/components/Button'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import { permissionsControl } from 'src/utils/permissionsControl'
import { useApplyPermissions } from 'src/utils/Hooks/useApplyPermissions'

const UsersListingsTable = ({ adminUserFetching, tableData, isProvider }) => {
    const history = useHistory()

    const {
        applyDynamicPermissionsToAdminUser,
        applyDynamicPermissionsToProviderUser,
    } = useApplyPermissions()

    const canUpdateAdminUser = permissionsControl(['can_update_admin_user'])

    return (
        <>
            <div className="AULTableWrapperUsers HideUserDesktop">
                <div className="AULTableHeader">
                    <span>Full Name</span>
                    <span>Phone Number</span>
                    <span>Active</span>
                    <span>Email Address</span>
                    <span>Actions</span>
                </div>
                {(adminUserFetching ? [...Array(5)] : tableData).map(user => (
                    <div key={user?.id} className="AULTableContentUsers">
                        {adminUserFetching ? (
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '125px',
                                    marginRight: '10px',
                                }}
                            />
                        ) : (
                            <span>
                                {user?.first_name + ' ' + user?.last_name}
                            </span>
                        )}
                        {adminUserFetching ? (
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '125px',
                                    marginRight: '10px',
                                }}
                            />
                        ) : (
                            <span>
                                {extractEmailOrNumber(user?.phone_number)}
                            </span>
                        )}
                        {adminUserFetching ? (
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '70px',
                                    marginRight: '10px',
                                }}
                            />
                        ) : (
                            <span>
                                {user?.state === 'ACTIVE' &&
                                !user?.user_types?.includes('DEACTIVATED_USER')
                                    ? 'Yes'
                                    : user?.state === 'ACTIVE' &&
                                      user?.user_types?.includes(
                                          'DEACTIVATED_USER',
                                      )
                                    ? 'No'
                                    : 'No'}
                            </span>
                        )}
                        {adminUserFetching ? (
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '125px',
                                    marginRight: '10px',
                                }}
                            />
                        ) : (
                            <span>{extractEmailOrNumber(user?.email)}</span>
                        )}
                        {adminUserFetching ? (
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '125px',
                                    marginRight: '10px',
                                }}
                            />
                        ) : (
                            <ButtonComponent
                                type="XSmall"
                                handleClick={() =>
                                    history.push(
                                        isProvider
                                            ? `/app/user/${user?.id}`
                                            : `/admin/user/${user?.id}`,
                                    )
                                }
                                disabled={
                                    user?.state === 'DELETED' ||
                                    applyDynamicPermissionsToAdminUser
                                        ? !canUpdateAdminUser
                                        : applyDynamicPermissionsToProviderUser
                                        ? !permissionsControl([
                                              'can_view_user_details',
                                          ])
                                        : false
                                }
                            >
                                View
                            </ButtonComponent>
                        )}
                    </div>
                ))}
            </div>

            {/*    mobile table*/}
            <div className="ACLMobileDiv">
                {adminUserFetching ? (
                    <ListingsSkeletalCards total={0} />
                ) : (
                    tableData?.map((user, i) => (
                        <div className="ACLMobileTable" key={i}>
                            <div className="ACLMobileTableSection">
                                <div>
                                    <h4 className="ACLMobileTableHeader">
                                        FIRST/LAST NAME
                                    </h4>
                                    <p className="ACLMobileText Name">
                                        {user?.first_name +
                                            ' ' +
                                            user?.last_name}
                                    </p>
                                </div>
                                <div>
                                    <h4 className="ACLMobileTableHeader">
                                        EMAIL ADDRESS
                                    </h4>
                                    <p className="ACLMobileText">
                                        {extractEmailOrNumber(user?.email)}
                                    </p>
                                </div>
                            </div>
                            <div className="ACLMobileTableSection">
                                <ButtonComponent
                                    btnBgColor="#E2EEFF"
                                    btnTextColor="#004AAD"
                                    btnWidth="70px"
                                    btnHeight="37px"
                                    fontSize="12px"
                                    fontFamily="SF-Pro-Display-Medium"
                                    handleClick={() =>
                                        history.push(
                                            isProvider
                                                ? `/app/user/${user?.id}`
                                                : `/admin/user/${user?.id}`,
                                        )
                                    }
                                    disabled={
                                        user?.state === 'DELETED' ||
                                        applyDynamicPermissionsToAdminUser
                                            ? !canUpdateAdminUser
                                            : applyDynamicPermissionsToProviderUser
                                            ? !permissionsControl([
                                                  'can_view_user_details',
                                              ])
                                            : false
                                    }
                                >
                                    View
                                </ButtonComponent>
                                <div>
                                    <h4
                                        className="ACLMobileTableHeader"
                                        style={{ textAlign: 'right' }}
                                    >
                                        ACTIVE
                                    </h4>
                                    <p className="ACLMobileText Right">
                                        {user?.state === 'ACTIVE' &&
                                        !user?.user_types?.includes(
                                            'DEACTIVATED_USER',
                                        )
                                            ? 'Yes'
                                            : user?.state === 'ACTIVE' &&
                                              user?.user_types?.includes(
                                                  'DEACTIVATED_USER',
                                              )
                                            ? 'No'
                                            : 'No'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    )
}

UsersListingsTable.propTypes = {
    adminUserFetching: PropTypes.bool,
    tableData: PropTypes.array,
    isProvider: PropTypes.bool,
}

export default UsersListingsTable
