import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import FormTitleBar from 'src/components/FormTitleBar'
import { InputFields } from 'src/components/InputFields'
import Button from 'src/components/Button'
import styles from 'src/components/Groups/providerGroups.module.scss'
import { updateComponentTypeApi } from 'src/api/admin/settings/componenttypes'
import { InlineLoader } from 'src/components/Loader'
import { useQuery } from 'react-query'
import Toast from 'src/components/Toast'
import { errorHandler } from 'src/utils/errorHandler'
import { isMobile } from 'src/utils/mediaQueries'

const MoreActionsModal = ({
    showModal,
    onCancel,
    action,
    handleSubmit,
    name,
    id,
}) => {
    const [inputs, setInputs] = useState({ componentName: '' })
    const [errors, setErrors] = useState({})
    const [modalError, setModalError] = useState(false)
    const [modalErrorMessage, setModalErrorMessage] = useState('')
    const { ModalContent, ModalBtn, MoreActions } = styles

    const { refetch: renameRefetch, isLoading: renameLoading } = useQuery(
        'rename-component-type',
        () => updateComponentTypeApi(id, { name: inputs.componentName }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                handleSubmit()
            },
            onError: error => {
                setModalError(true)
                setModalErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        setInputs({ componentName: name })
    }, [name, showModal])

    const titleMapping = {
        'rename-component-type': 'Rename Component Type',
    }
    const subTitleMapping = {
        'rename-component-type': 'Enter the details of the installed solution ',
    }

    const handleChange = event => {
        const { name, value } = event.target
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleClick = () => {
        if (action === 'rename-component-type') {
            if (inputs?.componentName === '') {
                setErrors({ componentName: 'This field is required' })
            } else {
                setErrors({})
                renameRefetch()
            }
        }
    }

    return (
        <>
            <SecondaryModal
                showModal={showModal}
                onCancel={onCancel}
                modalWidth="624px"
                content={
                    <>
                        <div className={`${ModalContent} ${MoreActions}`}>
                            <FormTitleBar
                                title={titleMapping[action]}
                                subtitle={subTitleMapping[action]}
                                marginTop="48px"
                                titleFontSize={'20px'}
                                alignLeft={isMobile}
                            />
                            {modalError && (
                                <Toast
                                    messageType="error"
                                    message={modalErrorMessage}
                                />
                            )}
                            <InputFields
                                title="Component Type Name"
                                inputWidth="100%"
                                name="componentName"
                                type="text"
                                value={inputs?.componentName}
                                errorMessage={errors?.componentName}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className={`${ModalBtn} ${MoreActions}`}>
                            <Button
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                btnWidth="160px"
                                btnHeight="56px"
                                fontSize="14px"
                                handleClick={() => handleClick()}
                            >
                                {renameLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Rename Component'
                                )}
                            </Button>
                        </div>
                    </>
                }
            />
        </>
    )
}
MoreActionsModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    action: PropTypes.string,
    componentName: PropTypes.string,
    handleSubmit: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
}
export default MoreActionsModal
