import axios from 'src/config/axios'

export const getSingleComponentTypeApi = async id => {
    const request = await axios.get(`admin/component-types/${id}`)
    return request
}

export const addComponentTypeAttributeApi = async (id, data) => {
    const request = await axios.post(
        `admin/component-types/${id}/add-attribute`,
        data,
    )
    return request
}

export const removeComponentTypeAttributeApi = async (id, attribute_id) => {
    const request = await axios.post(
        `admin/component-types/${id}/remove-attribute`,
        { attribute_id: attribute_id },
    )
    return request
}

export const updateComponentTypeApi = async (id, data) => {
    const request = await axios.patch(`admin/component-types/${id}`, data)
    return request
}

export const deleteComponentTypeApi = async id => {
    const request = await axios.delete(`admin/component-types/${id}`)
    return request
}
