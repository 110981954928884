import '../consumerkyc.scss'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import BackNav from 'src/components/BackNav'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import ChatCTA from 'src/components/ChatCTA'

const PageHeading = ({ handleShowVideo }) => {
    const history = useHistory()
    const { isMobile } = useMediaQueries()

    return (
        <div className="OnePageKYC_HeadingSection" style={{ paddingBottom: 0 }}>
            {!isMobile && (
                <BackNav
                    v2
                    title="Go back"
                    marginBottom="16px"
                    onClick={() => history.push('/')}
                />
            )}
            <h3 className="Heading">Welcome to the KYC section!</h3>
            <p className="SubHeading">
                We would love to get to know you better! <br /> Kindly provide
                the information requested below.
            </p>
            <div className="KYCPageVideo" onClick={() => handleShowVideo(true)}>
                <PlayIcon />
                <p>How to complete your KYC</p>
            </div>
            {isMobile && (
                <ChatCTA
                    text="Have questions about this process?"
                    margin="19px 0 0 0"
                />
            )}
        </div>
    )
}

PageHeading.propTypes = {
    handleShowVideo: PropTypes.func,
}

export default PageHeading
