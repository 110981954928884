import residenceIcon from 'src/assets/images/residence-icon.svg'
import businessIcon from 'src/assets/images/business-icon.svg'

export const requiredResidentialInfo = [
    '6-12 months of up-to-date bank statements',
    'Your monthly income',
    'Your Bank Verification Number (BVN)',
    'A recent photo of yourself',
    "Identity verification (such as a Driver's License, Voter's Card, or NIN)",
]

export const requiredBusinessInfo = [
    '12-24 months of up-to-date bank statements',
    'Business Name',
    'Business Location',
    'RC number',
    'Monthly Business Revenue',
]

export const requiredOutrightSaleInfo = [
    'Full name',
    'Phone number & email address',
    'Installation and contact address',
]

export const requiredInfoList = inputs => {
    switch (inputs.useType) {
        case 'Residential use':
            return requiredResidentialInfo
        case 'Business use':
            return requiredBusinessInfo
        default:
            return []
    }
}

export const useTypeDetails = [
    {
        icon: residenceIcon,
        title: 'Home or Stall/Shops',
        description:
            'Suitable for remote workers, shop owners, residential homes and apartments.',
        value: 'Residential use',
    },
    {
        icon: businessIcon,
        title: 'Registered Business',
        description:
            'Suitable for NGOs, LLCs, or large businesses with CAC registration.',
        value: 'Business use',
    },
]
