/* eslint-disable react/prop-types */

import { useState } from 'react'
import FormTitleBar from "src/components/FormTitleBar"
import { useQuery } from 'react-query'
import { InputFields, SelectField } from "src/components/InputFields"
import ActionModal from 'src/components/ActionModal'
import Button from 'src/components/Button'
import { isMobile } from 'src/utils/mediaQueries'
import { unitsOfMeasurementOptions } from '../data'
import { ReactComponent as PlusIcon } from 'src/assets/images/plus-icon.svg'
import { ReactComponent as BlueCloseIcon } from 'src/assets/images/blue-close-icon.svg'
import { createApplianceApi, createBulkApplianceApi } from 'src/api/admin/settings'
import { InlineLoader } from 'src/components/Loader'
import useErrorHandler from 'src/utils/Hooks/useErrorHandler'
import { AddAppliancesValidationSchema } from 'src/utils/validationSchema'


const AddAppliance = ({ closeAddAppliance }) => {
    const [showSuccess, setShowSuccess] = useState(false)
    const [errors, setErrors] = useState('')
    const [errs, setErrs] = useState(null)

    const [selectedAppliance, setSelectedAppliance] = useState([{
        name: '',
        power_rating: '',
        unit_of_measurement: '',
    }])
   
    const onChange = (name, value, index) => {
        const appliancesList = selectedAppliance.map((appliance, idx)=> {
            if(index === idx){
            appliance[name] = value
        }
            return appliance;
        })
        setSelectedAppliance(appliancesList)
        setErrors('')
    }
    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        onChange(name, value, index)
    }

    const handleSave = () => {
        setSelectedAppliance([
            {
                name: '',
                power_rating: '',
                unit_of_measurement: '',
            }
        ])
    }

    const handleAdd = () => {
        setSelectedAppliance([
            ...selectedAppliance,
            {
                name: '',
                power_rating: '',
                unit_of_measurement: '',
            },
        ])
    }

    const handleRemove = (i) => {
        const filterSelected = selectedAppliance.filter((_, j) => j !== i)
        setSelectedAppliance(filterSelected)
    }

    const handleSubmit = () => {
        AddAppliancesValidationSchema.validate(
            { appliances: selectedAppliance },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                createApplianceRefetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    const {
        refetch: createApplianceRefetch,
        isFetching: createApplianceFetching,
    } = useQuery(
        ['create-appliance-api'],
        () => selectedAppliance.length > 1 ? createBulkApplianceApi(selectedAppliance) : createApplianceApi(
            selectedAppliance[0]),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setShowSuccess(true)
                closeAddAppliance()
                handleSave()
            },
            onError: error => {
                if (error?.response?.data?.errors) {
                    setErrs(error?.response?.data)
                }
            },
        },
    )

    const errorValue = useErrorHandler(errs)

    return (
        <div>
            <ActionModal
                actionModalOpen={showSuccess}
                actionType="success"
                headerText={'Appliance Added Successfully'}
                onCancel={() => setShowSuccess(false)}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText="Okay, Got it"
                actionHandler={() => {
                    setShowSuccess(false)
                    window.location.href = '/admin/settings?tab=1'
                }}
                closable
                noBackLink
                noCancelBtn
            />
            <div style={{ marginTop: 48 }}>
                <FormTitleBar
                    title="Add Appliance"
                    subtitle="Enter the details of the appliance"
                    fontFamily="SF-Pro-Display-Bold"
                    titleFontSize={20}
                    alignLeft={isMobile}
                />
            </div>
            <div className='AddApplianceModalContent'>
                {selectedAppliance.map((appliance, i) => (
                    <>
                        <p className="ApplianceCount">Appliance {i + 1}</p>
                        <div className='ASLMobileWidth'>
                            <InputFields
                                title="Appliance Name"
                                name="name"
                                inputValue={appliance?.name}
                                value={appliance?.name}
                                inputWidth="100%"
                                handleChange={(e) => handleInputChange(e, i)}   
                                errorMessage={
                                    errors[
                                        `appliances[${i}].name`
                                    ] || errorValue['name']?.[0]
                                }                            
                            />
                        </div>
                        <div className="ASLEditModalInputWrapper">
                            <div className='ASLMobileWidth'>
                                <InputFields
                                    title="Power Rating"
                                    name="power_rating"
                                    type="number"
                                    inputValue={appliance?.power_rating}
                                    value={appliance?.power_rating}
                                    inputWidth="224px"
                                    handleChange={(e) => handleInputChange(e, i)}
                                    errorMessage={
                                        errors[
                                            `appliances[${i}].power_rating`
                                        ]
                                    }                                 
                                />
                            </div>

                            <div className='ASLMobileWidth'>
                                <SelectField
                                    floatingLabel={appliance?.unit_of_measurement !== '' && "Unit of Measurement"}
                                    type="approvedModal"
                                    name="unit_of_measurement"
                                    selectWidth="220px"
                                    withCheckBox={true}
                                    initialOption='Unit of Measurement'
                                    value={appliance?.unit_of_measurement}
                                    values={unitsOfMeasurementOptions}
                                    handleChange={(name, value)=>  onChange(name, value, i)}
                                    selectedValue={appliance?.unit_of_measurement}
                                    currentSelected={appliance?.unit_of_measurement}
                                    errorMessage={
                                        errors[
                                            `appliances[${i}].unit_of_measurement`
                                        ]
                                    } 
                                />
                            </div>
                        </div>
                        {selectedAppliance.length > 1 && <div
                            className='ApplianceCloseTextButton'
                        >
                            <BlueCloseIcon />
                            <p  onClick={() => handleRemove(i)}>Remove</p>
                        </div>}
                    </>

                ))}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        btnWidth="113px"
                        btnHeight="40px"
                        fontSize="14px"
                        btnBgColor="var(--purple-light)"
                        btnTextColor="#004AAD"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        handleClick={handleAdd}
                    >

                        <PlusIcon
                            style={{ marginRight: 8 }}
                        />
                        Add New
                    </Button>
                </div>

            </div>
            <div className="ASLEditModalButtonWrapper" style={{ marginBottom: 46 }}>
                <Button
                    btnTextColor="var(--white)"
                    btnBgColor="var(--blue)"
                    btnWidth="150px"
                    btnHeight="56px"
                    fontSize="14px"
                    fontFamily="SF-Pro-Display-Semibold"
                    handleClick={handleSubmit}
                >
                    {createApplianceFetching ? <InlineLoader /> : 'Add Appliance'}
                </Button>
            </div>

        </div>
    )
}

export default AddAppliance