import { InputFields, SelectField } from 'src/components/InputFields'
import '../../consumerkyc.scss'
import { useState } from 'react'
import { Switch } from 'antd'
import { localGovtData, stateData } from 'src/utils/stateData'
import DetailsSection from './DetailsSection'
import PersonalInfo from './PersonalInfo'
import { useConsumerKYCContext } from '../../context'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const BasicInformationSection = () => {
    const { inputs, useType } = useConsumerKYCContext()
    const [isSameInstallationAddress, setIsSameInstallationAddress] = useState(
        inputs?.sameAddress !== '',
    )
    const { isMobile } = useMediaQueries()

    const countries = ['Nigeria']

    return (
        <div>
            {useType === 'residential' && (
                <>
                    <PersonalInfo />
                    <InputFields
                        title="Home Address"
                        value={inputs?.contactAddress}
                        marginBottom={isMobile ? '16px' : ''}
                    />
                </>
            )}

            {useType === 'business' && (
                <div>
                    <InputFields
                        title="Registered Business Name"
                        value={inputs?.business_name}
                        marginBottom={isMobile ? '16px' : ''}
                    />
                    <InputFields
                        title="Business contact address"
                        value={inputs?.contactAddress}
                        marginBottom={isMobile ? '16px' : ''}
                    />
                </div>
            )}

            <div className="GroupItems AddressDetails">
                <SelectField
                    initialOption={
                        useType === 'residential' ? 'Country' : 'Nationality'
                    }
                    values={countries.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactCountry}
                    marginBottom={isMobile ? '16px' : ''}
                />
                <SelectField
                    initialOption="State"
                    values={stateData?.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactState}
                    marginBottom={isMobile ? '16px' : ''}
                />
                <SelectField
                    initialOption="LGA"
                    values={localGovtData['Lagos']?.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactCity}
                    marginBottom={isMobile ? '16px' : ''}
                />
            </div>
            <div className="GroupItems">
                <p className="InstallationText">
                    Is this contact address the same as where you want to
                    install the system?
                </p>

                <div className="SwitchWrapper">
                    <span
                        style={{
                            color: !isSameInstallationAddress ? '#004AAD' : '',
                        }}
                    >
                        No
                    </span>
                    <Switch
                        checked={isSameInstallationAddress}
                        onChange={() =>
                            setIsSameInstallationAddress(
                                !isSameInstallationAddress,
                            )
                        }
                        size={isMobile ? 'small' : ''}
                    />
                    <span
                        style={{
                            color: isSameInstallationAddress ? '#004AAD' : '',
                        }}
                    >
                        Yes
                    </span>
                </div>
            </div>
            <div>
                {!isSameInstallationAddress && (
                    <InputFields
                        title="Installation Address"
                        value={inputs?.installationAddress}
                        marginBottom={isMobile ? '16px' : ''}
                    />
                )}
            </div>
            <DetailsSection />
        </div>
    )
}

export default BasicInformationSection
