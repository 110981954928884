/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, forwardRef, useEffect } from 'react'
import { toDate } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import DatePicker from 'react-datepicker'
import { Select, TreeSelect } from 'antd'
import { SelectCheckbox } from '../Checkbox'
import {
    EyeOutlined,
    EyeInvisibleOutlined,
    LockOutlined,
} from '@ant-design/icons'
import { CaretDownOutlined } from '@ant-design/icons'
import Button from '../Button'
import 'react-datepicker/dist/react-datepicker.css'
import LockIcon from '../../assets/images/lock.svg'
import CalendarIcon from 'src/assets/images/consumer-calendar.svg'
import CloseIcon from 'src/assets/images/close-circle.svg'
import { InlineLoaderDark } from '../Loader'
import { ReactComponent as VerifiedIcon } from 'src/assets/images/circle-check-green.svg'
import { ReactComponent as SearchIcon } from 'src/assets/images/search-icon-grey.svg'
import { ReactComponent as ProgressIcon } from 'src/assets/images/progress-clock.svg'

import './inputfields.scss'
import CustomToolTip from '../CustomToolTip'

const PasswordIcon = ({ visible, color }) => {
    return (
        <>
            {visible ? (
                <EyeOutlined style={{ color }} />
            ) : (
                <EyeInvisibleOutlined style={{ color }} />
            )}
        </>
    )
}

export const InputFields = ({
    title,
    type,
    name,
    step,
    value,
    inputWidth,
    inputHeight,
    inputValue,
    inputBackground,
    handleChange,
    halfWidth,
    largeDesktopWidth,
    errorMessage = '',
    disabled,
    prefilled,
    iconType,
    inputPlaceholder,
    TextPrefilled,
    onIconClick,
    floatingLabel,
    nairaFloatingLabel,
    inputFieldWrapperStyle = {},
    passwordColor,
    autoFocus,
    mobileMarginRight,
    marginRight,
    marginBottom,
    onBlur,
    border = '',
    disableRequiredAttribute = false,
    paddingBottom,
    top,
    paddingRight,
    loadingIcon,
    verified,
    exemptMouseOut = true,
    setDoubleActive,
    floatingLabelFontSize,
    fontFamily,
    inprogress,
    onFocus,
    preventAutoComplete = false,
    activateEnterClick,
    enterFunc,
    listId = '',
    dataList = [],
    optional,
}) => {
    const [showPassword, setShowPassword] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)

    const width =
        typeof inputWidth === 'string'
            ? Number(inputWidth.split('px')[0])
            : inputWidth + '%'

    const inputType = showPassword ? 'text' : 'password'

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            enterFunc(event)
        }
    }

    return (
        <div
            style={{
                marginBottom: `${
                    step === 'addAttributesToComponent'
                        ? '0px'
                        : marginBottom
                        ? marginBottom
                        : '20px'
                }`,
            }}
            className={mobileMarginRight && 'mobileMarginRight'}
        >
            <div
                className={
                    largeDesktopWidth ? 'LargeWidthStyle' : 'InputFieldWrapper'
                }
                style={{ ...inputFieldWrapperStyle }}
            >
                <input
                    defaultValue={inputValue}
                    value={value}
                    type={type === 'password' ? inputType : type}
                    onChange={handleChange}
                    className={`InputText ${
                        halfWidth ? 'ResponsiveWidth' : ''
                    }`}
                    style={{
                        width,
                        border: `${
                            errorMessage !== ''
                                ? '1px solid #DA1E28'
                                : TextPrefilled
                                ? '1px solid var(--blue)'
                                : border
                        }`,
                        backgroundColor: inputBackground,
                        height: inputHeight,
                        paddingLeft: nairaFloatingLabel && '60px',
                        marginRight: marginRight,
                        paddingRight: paddingRight,
                        paddingBottom: paddingBottom || '16px',
                        fontFamily: fontFamily,
                    }}
                    name={name}
                    required={!disableRequiredAttribute}
                    disabled={disabled ? true : false}
                    placeholder={inputPlaceholder ?? ''}
                    onFocus={() => {
                        setInputFocus(true)
                        setDoubleActive && setDoubleActive(true)
                        onFocus && onFocus()
                    }}
                    autoFocus={autoFocus && autoFocus}
                    onBlur={e => {
                        onBlur && onBlur(e)
                        setInputFocus(false)
                        setDoubleActive && setDoubleActive(false)
                    }}
                    onMouseOut={e => {
                        if (!exemptMouseOut) {
                            onBlur && type !== 'password' && onBlur(e)
                        }
                    }}
                    data-testid={`input-${name}`}
                    autoComplete={preventAutoComplete ? 'new-password' : null}
                    onKeyUp={activateEnterClick && handleKeyPress}
                    list={listId}
                />
                {dataList?.length ? (
                    <datalist id={listId}>
                        {dataList?.map((each, i) => (
                            <option value={each} key={i}>
                                {each}
                            </option>
                        ))}
                    </datalist>
                ) : null}
                {verified ? (
                    <span
                        className="password-icon"
                        style={{ left: 'calc(100% - 42px)' }}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <VerifiedIcon role="presentation" />
                    </span>
                ) : (
                    inprogress && (
                        <span
                            className="password-icon"
                            style={{ left: 'calc(100% - 42px)' }}
                            //onClick={() => setShowPassword(!showPassword)}
                        >
                            <ProgressIcon role="presentation" />
                        </span>
                    )
                )}
                {type === 'password' && (
                    <span
                        className="password-icon"
                        style={{ left: 'calc(100% - 30px)' }}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <PasswordIcon
                            visible={showPassword}
                            className="PasswordIconFieldPosition"
                            color={passwordColor && passwordColor}
                        />
                    </span>
                )}
                {prefilled && (
                    <span
                        className="lock-icon"
                        style={{ left: width - 30, top: top || '15px' }}
                        onClick={onIconClick}
                    >
                        {iconType === 'search' ? (
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginTop: 4 }}
                            >
                                <path
                                    d="M11.0208 10.0777L13.8762 12.9323L12.9328 13.8757L10.0782 11.0203C9.01599 11.8718 7.69483 12.3349 6.33349 12.333C3.0215 12.333 0.333496 9.64501 0.333496 6.33301C0.333496 3.02101 3.0215 0.333008 6.33349 0.333008C9.64549 0.333008 12.3335 3.02101 12.3335 6.33301C12.3354 7.69434 11.8723 9.0155 11.0208 10.0777ZM9.68349 9.58301C10.5296 8.71293 11.0021 7.54663 11.0002 6.33301C11.0002 3.75434 8.91149 1.66634 6.33349 1.66634C3.75483 1.66634 1.66683 3.75434 1.66683 6.33301C1.66683 8.91101 3.75483 10.9997 6.33349 10.9997C7.54711 11.0016 8.71342 10.5291 9.58349 9.68301L9.68349 9.58301Z"
                                    fill={`${
                                        inputFocus ? 'var(--blue)' : '#1D2A30'
                                    }`}
                                />
                            </svg>
                        ) : (
                            <img src={LockIcon} className="LockFieldPosition" />
                        )}
                    </span>
                )}
                {iconType === 'DropdownArrow' && (
                    <span
                        className="lock-icon"
                        style={{ left: width - 30, marginTop: '4px' }}
                    >
                        <CaretDownOutlined />
                    </span>
                )}
                {loadingIcon && (
                    <i
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            position: 'absolute',
                            right: '10px',
                            top: '30%',
                        }}
                    >
                        <InlineLoaderDark />
                        <InlineLoaderDark />
                        <InlineLoaderDark />
                    </i>
                )}
                <span
                    className="FloatingLabel"
                    style={{
                        left: nairaFloatingLabel && '63px',
                        fontSize: floatingLabelFontSize,
                    }}
                >
                    {title}
                </span>
                {floatingLabel ? (
                    <span className="AddFloatingLabel">{floatingLabel}</span>
                ) : nairaFloatingLabel ? (
                    <span className="NairaFloatingLabel">
                        {nairaFloatingLabel}
                    </span>
                ) : (
                    ''
                )}
            </div>
            {errorMessage ? (
                <span className="ErrorText">
                    {errorMessage === '' ? '' : errorMessage}
                </span>
            ) : (
                ''
            )}
            {optional ? (
                <span className="InputFields_OptionalText">Optional</span>
            ) : (
                ''
            )}
        </div>
    )
}

export const NoFLoatingLabelInputFields = ({
    type,
    name,
    value,
    inputWidth,
    inputHeight,
    inputValue,
    inputBackground,
    handleChange,
    halfWidth,
    errorMessage = '',
    disabled,
    prefilled,
    iconType,
    inputPlaceholder,
    TextPrefilled,
    onIconClick,
    onKeyUp,
    nairaFloatingLabel,
    inputFieldWrapperStyle = {},
    passwordColor,
    autoFocus,
    borderRadius,
    inputRef,
    onBlur,
    blurOnMouseOut = true,
    marginBottom,
    border,
    optional,
}) => {
    const [showPassword, setShowPassword] = useState(false)
    const [inputFocus, setInputFocus] = useState(false)

    const width =
        typeof inputWidth === 'string'
            ? Number(inputWidth.split('px')[0])
            : inputWidth
    const inputType = showPassword ? 'text' : 'password'

    return (
        <div style={{ marginBottom: marginBottom && marginBottom }}>
            <div
                className="InputFieldWrapper"
                style={{ ...inputFieldWrapperStyle }}
            >
                {prefilled && iconType === 'promo-search' && (
                    <span
                        className="leftSearchIcon"
                        onClick={onIconClick}
                        style={{ right: 'auto' }}
                    >
                        <SearchIcon />
                    </span>
                )}
                <input
                    defaultValue={inputValue}
                    value={value}
                    type={type === 'password' ? inputType : type}
                    onChange={handleChange}
                    className={`NoFloatingLabelInputText InputText ${
                        halfWidth && 'ResponsiveWidth'
                    } ${iconType === 'promo-search' && 'CSSESearchStyle'}`}
                    style={{
                        width,
                        border: `${
                            border
                                ? border
                                : errorMessage !== ''
                                ? '1px solid #DA1E28'
                                : TextPrefilled
                                ? '1px solid var(--blue)'
                                : ''
                        }`,
                        backgroundColor: inputBackground,
                        borderRadius,
                        height: inputHeight,
                        paddingLeft: nairaFloatingLabel && '60px',
                    }}
                    name={name}
                    required
                    disabled={disabled ? true : false}
                    placeholder={inputPlaceholder}
                    onFocus={() => setInputFocus(true)}
                    onBlur={e => {
                        onBlur && type !== 'password' && onBlur(e)
                        setInputFocus(false)
                    }}
                    onMouseOut={e => {
                        blurOnMouseOut &&
                            onBlur &&
                            type !== 'password' &&
                            onBlur(e)
                    }}
                    autoFocus={autoFocus && autoFocus}
                    ref={inputRef}
                    onKeyUp={onKeyUp}
                />
                {type === 'password' && (
                    <span
                        className="password-icon"
                        style={{ left: 'calc(100% - 30px)' }}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <PasswordIcon
                            visible={showPassword}
                            className="PasswordIconFieldPosition"
                            color={passwordColor && passwordColor}
                        />
                    </span>
                )}
                {prefilled && iconType !== 'promo-search' && (
                    <span className="lock-icon" onClick={onIconClick}>
                        {iconType === 'search' ? (
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.0207 10.0777L13.876 12.9323L12.9327 13.8757L10.078 11.0203C9.01586 11.8718 7.69471 12.3349 6.33337 12.333C3.02137 12.333 0.333374 9.64501 0.333374 6.33301C0.333374 3.02101 3.02137 0.333008 6.33337 0.333008C9.64537 0.333008 12.3334 3.02101 12.3334 6.33301C12.3353 7.69434 11.8722 9.0155 11.0207 10.0777ZM9.68337 9.58301C10.5294 8.71293 11.0019 7.54663 11 6.33301C11 3.75434 8.91137 1.66634 6.33337 1.66634C3.75471 1.66634 1.66671 3.75434 1.66671 6.33301C1.66671 8.91101 3.75471 10.9997 6.33337 10.9997C7.54699 11.0016 8.7133 10.5291 9.58337 9.68301L9.68337 9.58301Z"
                                    fill={inputFocus ? 'var(--blue)' : 'black'}
                                />
                            </svg>
                        ) : (
                            <LockOutlined className="LockFieldPosition" />
                        )}
                    </span>
                )}
            </div>

            {errorMessage ? (
                <span className="ErrorText">
                    {errorMessage === '' ? '' : errorMessage}
                </span>
            ) : (
                ''
            )}
            {optional ? (
                <span className="InputFields_OptionalText">Optional</span>
            ) : (
                ''
            )}
        </div>
    )
}

export const SelectField = ({
    selectWidth,
    values = [],
    selectedValue = '',
    handleChange,
    currentSelected,
    name,
    initialOption,
    withCheckBox,
    errorMessage = '',
    type,
    value,
    mobileWidth,
    marginBottom,
    dropdownPositionRelative,
    handleSearch,
    searchValue,
    onSearch,
    marginRight,
    floatingLabel,
    marginTop,
    floatLabelOnSelectedValue = false,
    prefilled,
    optionStyle,
    pii,
    optionStyleNoCheckbox,
    selectedField = 'value',
    contentField = 'value',
    selectedBlue = false,
    disabled,
    activeBorder,
    floatingLabelFontSize,
    fontColor,
    hideSuffixIcon,
    dropdownStyle,
    ...props
}) => {
    const [width, setWidth] = useState(window.innerWidth)
    const [selectFocus, setSelectFocus] = useState(false)
    const { Option } = Select

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowResize)

        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    const showFloatingLabel = value?.length > 0 && floatLabelOnSelectedValue

    return (
        <div
            style={{
                marginBottom: marginBottom && marginBottom,
                marginTop: marginTop && marginTop,
            }}
            className={
                (!floatLabelOnSelectedValue && floatingLabel) ||
                showFloatingLabel
                    ? 'SelectFloatingLabelWrapper'
                    : 'SelectNoFloatingLabelWrapper'
            }
        >
            {((!floatLabelOnSelectedValue && floatingLabel) ||
                showFloatingLabel) && (
                <span className="SelectFloatingLabel">{floatingLabel}</span>
            )}
            <Select
                defaultValue={selectedValue}
                style={{
                    height: pii ? '47px' : '',
                    borderRadius: pii ? '28px' : '',
                    width: selectWidth,
                    border:
                        errorMessage !== ''
                            ? '1px solid #DA1E28'
                            : selectFocus
                            ? '1px solid var(--blue)'
                            : '',
                    position: 'relative',
                    marginRight: marginRight,
                }}
                onFocus={() => {
                    if (activeBorder) {
                        setSelectFocus(true)
                    }
                }}
                onBlur={() => {
                    if (activeBorder) {
                        setSelectFocus(false)
                    }
                }}
                onChange={(value, option) => handleChange(name, value, option)}
                onSearch={onSearch && (value => handleSearch(name, value))}
                name={name}
                className={`SelectBox ${mobileWidth && 'mobileWidth'} ${
                    ((!floatLabelOnSelectedValue && floatingLabel) ||
                        showFloatingLabel) &&
                    'SelectFloatingBox'
                } ${type === 'analyticsChart' && 'analyticsChart'} ${
                    selectedBlue && 'selectedBlue'
                }`}
                suffixIcon={
                    hideSuffixIcon ? null : prefilled ? (
                        <img src={LockIcon} className="LockFieldPosition" />
                    ) : (
                        <CaretDownOutlined />
                    )
                }
                {...props}
                dropdownStyle={{
                    position: `${
                        dropdownPositionRelative ? 'relative' : 'fixed'
                    }`,
                    borderRadius: '15px',
                    marginTop: '20px',
                    ...dropdownStyle,
                }}
                dropdownClassName={
                    type === 'add-margin'
                        ? 'DropdownClassName'
                        : type === 'approvedModal' || type === 'SSEOffset'
                        ? 'HalfWidth'
                        : type === 'analyticsChart'
                        ? 'analyticsStyle'
                        : type === 'sse-savings-calculator'
                        ? 'SavingsDropdownClassName'
                        : ''
                }
                value={value}
                dropdownAlign={
                    type === 'add-margin'
                        ? { offset: [-4, -60] }
                        : type === 'approvedModal'
                        ? { offset: [-4, -55] }
                        : type === 'SSEOffset'
                        ? width <= 500
                            ? { offset: [-48, 19] }
                            : { offset: [-108, 3] }
                        : type === 'analyticsChart' && width <= 500
                        ? { offset: [-55, 3] }
                        : type === 'analyticsChart' && width > 500
                        ? { offset: [-30, 3] }
                        : ''
                }
                notFoundContent={
                    type === 'direct-debit'
                        ? 'Your bank is not on the list because it does not support direct debit. Please input another bank name.'
                        : undefined
                }
                disabled={disabled}
                data-testid={`select-${name}`}
            >
                <Option value={''} className="EmptyOption">
                    <span
                        className="InitialOptionTab"
                        style={{ fontSize: floatingLabelFontSize || '14px' }}
                    >
                        {initialOption}
                    </span>
                </Option>
                {withCheckBox
                    ? values.map(option => {
                          return (
                              <Option
                                  value={option[selectedField]}
                                  key={uuidv4()}
                                  style={optionStyle}
                                  disabled={option.disabled}
                              >
                                  <SelectCheckbox
                                      isChecked={
                                          option[selectedField] ===
                                          currentSelected
                                              ? true
                                              : false
                                      }
                                      id={`select-${uuidv4()}`}
                                      fontColor={fontColor}
                                  >
                                      {option[contentField]}
                                  </SelectCheckbox>
                              </Option>
                          )
                      })
                    : values.map(option => {
                          return (
                              <Option
                                  value={option[selectedField]}
                                  key={uuidv4()}
                                  style={optionStyleNoCheckbox}
                              >
                                  {option[contentField]}
                              </Option>
                          )
                      })}
            </Select>

            {errorMessage ? (
                <div className="ErrorText">
                    {errorMessage === '' ? '' : errorMessage}
                </div>
            ) : (
                ''
            )}
        </div>
    )
}
export const MultipleSelectField = ({
    selectWidth,
    dropDownWidth,
    top,
    right,
    values = [],
    title = '',
    handleMultipleSelectChange,
    handleMultipleSelectChange2,
    inputValue,
    errorMessage,
    disabled,
    maxTagCount,
    floatingLabel,
    width,
    value,
    mobileWidth,
    dropdownMatchSelectWidth,
    currentSelected = [],
    onSelect,
    optionStyle,
    selectStyle,
    onBlur,
    contentField,
    topArrow,
}) => {
    const { Option } = Select

    return (
        <>
            <div
                className={`MultiSelect ${
                    floatingLabel && value?.length > 0 ? 'FloatParent' : ''
                } ${topArrow ? 'MultiSelectWithTopArrow' : ''}`}
                style={{
                    border: errorMessage && '1px solid #DA1E28',
                    width: width,
                }}
            >
                {floatingLabel && value?.length > 0 && (
                    <span className="FloatingLabelTreeSelection">
                        {floatingLabel}
                    </span>
                )}
                <Select
                    mode="multiple"
                    placeholder={title}
                    style={{
                        width: selectWidth,
                        ...selectStyle,
                    }}
                    onChange={value =>
                        !onSelect && handleMultipleSelectChange(value)
                    }
                    value={value ? value : inputValue}
                    disabled={disabled}
                    dropdownStyle={{
                        borderRadius: '15px',
                        minWidth: dropDownWidth,
                        width: dropDownWidth,
                    }}
                    dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                    onSelect={data => {
                        onSelect && handleMultipleSelectChange2(data)
                    }}
                    showArrow
                    suffixIcon={
                        <CaretDownOutlined
                            className="SelectFloatingIcon"
                            style={{ right: right, top: top }}
                        />
                    }
                    maxTagCount={maxTagCount && maxTagCount}
                    className={mobileWidth && 'MultipleSelectMobileWidth'}
                    dropdownClassName={mobileWidth && 'DropdownMobileWidth'}
                    onBlur={onBlur}
                >
                    {values.map((value, index) => {
                        return (
                            <Option
                                value={value.value}
                                label={value.label}
                                key={index}
                                style={optionStyle}
                            >
                                <SelectCheckbox
                                    isChecked={
                                        inputValue?.includes(value?.value)
                                            ? true
                                            : false ||
                                              currentSelected.includes(
                                                  value.value,
                                              )
                                            ? true
                                            : false
                                    }
                                    id={`multi-select-${index}`}
                                >
                                    {contentField
                                        ? value[contentField]
                                        : value.value}
                                </SelectCheckbox>
                            </Option>
                        )
                    })}
                </Select>
            </div>
            {errorMessage ? (
                <div className="ErrorText">
                    {errorMessage === '' ? '' : errorMessage}
                </div>
            ) : (
                ''
            )}
        </>
    )
}
export const TextArea = ({
    title,
    name,
    rows,
    cols,
    value,
    handleChange,
    width,
    height,
    errorMessage,
    TextPrefilled,
    defaultValue,
    placeholder,
    maxHeight,
    resize,
    padding,
    onBlur,
    disabled,
}) => {
    return (
        <>
            <div className="InputFieldWrapper">
                <textarea
                    type="text"
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    className="InputText"
                    name={name}
                    style={{
                        maxHeight,
                        width: width,
                        resize: resize ? 'none' : '',
                        height: height,
                        border: `${
                            errorMessage
                                ? '1px solid #DA1E28'
                                : TextPrefilled
                                ? '1px solid var(--blue)'
                                : ''
                        }`,
                        padding: padding || '25px 22px',
                    }}
                    rows={rows}
                    cols={cols}
                    value={value}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    required
                    disabled={disabled}
                    data-testid={`textarea-${name}`}
                >
                    {value}
                </textarea>
                {errorMessage ? (
                    <div className="ErrorText" style={{ textAlign: 'left' }}>
                        {errorMessage === '' ? '' : errorMessage}
                    </div>
                ) : (
                    ''
                )}

                <span className="FloatingLabelArea">{title}</span>
            </div>
        </>
    )
}

export const DateField = ({
    name,
    placeholder,
    dateValue,
    handleDateChange,
    position,
    openCalendar,
    closeCalendarHandler,
    openCalendarHandler,
    setHandleDateChange,
    errorMessage,
    caretStyle,
    prefilled,
    calendarClassName,
    maxDateType,
    disabledCursorIcon,
    placePopperRight,
    filterRangeFn,
    floatingLabel,
    marginLeft,
    isFullWidth,
}) => {
    const [selectedDate, setSelectedDate] = useState(new Date())

    // eslint-disable-next-line react/display-name
    const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
        <>
            <div className="DateBox" onClick={openCalendarHandler}>
                <div
                    className="DateBoxText"
                    onClick={onClick}
                    ref={ref}
                    style={{
                        border: errorMessage && '1px solid #DA1E28',
                        cursor: disabledCursorIcon ? 'not-allowed' : 'pointer',
                    }}
                >
                    {!dateValue || floatingLabel ? placeholder : value}
                </div>
                <div
                    className={caretStyle ? caretStyle : 'DateBoxCaret'}
                    onClick={onClick}
                    style={{ top: errorMessage && '19%' }}
                >
                    {prefilled ? (
                        <img src={LockIcon} className="LockFieldPosition" />
                    ) : (
                        <CaretDownOutlined />
                    )}
                </div>
            </div>
            {errorMessage ? (
                <div
                    className="ErrorText"
                    style={{ marginLeft: marginLeft && marginLeft }}
                >
                    {!errorMessage ? '' : errorMessage}
                </div>
            ) : (
                ''
            )}
        </>
    ))
    return (
        <>
            <div
                className={`CalenderBox ${position} ${
                    isFullWidth ? 'CalendarFullwidth' : ''
                }`}
            >
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    selected={dateValue}
                    onChange={date => {
                        if (date) {
                            handleDateChange(name, date)
                        }
                    }}
                    customInput={<CustomDateInput />}
                    shouldCloseOnSelect={false}
                    open={openCalendar}
                    calendarClassName={calendarClassName}
                    calendarStartDay={1}
                    maxDate={
                        maxDateType === 'future dates included'
                            ? ''
                            : toDate(new Date())
                    }
                    popperPlacement={
                        placePopperRight ? 'right-start' : 'left-start'
                    }
                    popperModifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -320],
                            },
                        },
                    ]}
                    filterDate={
                        filterRangeFn
                            ? filterRangeFn
                            : () => {
                                  return true
                              }
                    }
                >
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            btnBgColor="var(--purple-light)"
                            btnTextColor="var(--blue)"
                            btnTextColorOutline="var(--blue)"
                            btnOutlineColor="var(--purple-light)"
                            btnBgColorOutline="#E2EEFF"
                            type="outline"
                            btnWidth="120px"
                            handleClick={closeCalendarHandler}
                        >
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnWidth="120px"
                            handleClick={() => {
                                setSelectedDate(selectedDate)
                                setHandleDateChange(name)
                                closeCalendarHandler()
                            }}
                        >
                            Set Date
                        </Button>
                    </div>
                </DatePicker>
            </div>
        </>
    )
}
export const ConsumerDateField = ({
    name,
    placeholder,
    dateValue,
    value,
    handleDateChange,
    position,
    openCalendar,
    closeCalendarHandler,
    openCalendarHandler,
    setHandleDateChange,
    errorMessage,
    caretStyle,
    prefilled,
    calendarClassName,
    maxDateType,
    placePopperRight,
    filterRangeFn,
    handleChange,
    width,
    height,
    title,
    onBlur,
    marginBottom,
    disabled,
}) => {
    const [selectedDate, setSelectedDate] = useState(new Date())

    // eslint-disable-next-line react/display-name
    const CustomDateInput = forwardRef(({ onClick }, ref) => (
        <>
            <div
                className="ConsumerDateInputWrapper"
                onClick={disabled ? () => {} : openCalendarHandler}
            >
                <span className="ConsumerDateInputImgWrapper">
                    <img src={CalendarIcon} />
                </span>
                <input
                    className="ConsumerDateInput"
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    style={{
                        width: width,
                        height: height,
                        marginBottom: marginBottom,
                    }}
                    onBlur={e => {
                        onBlur && onBlur(e)
                    }}
                    onMouseOut={e => {
                        onBlur && onBlur(e)
                    }}
                    type="text"
                    required
                    disabled={disabled}
                    data-testid={`consumer-date-field-input-${name}`}
                />
                <span className="FloatingLabel">{title}</span>
                <div
                    className={caretStyle ? caretStyle : 'DateBoxCaret'}
                    onClick={onClick}
                >
                    {prefilled && (
                        <img src={LockIcon} className="LockFieldPosition" />
                    )}
                </div>
            </div>
            {errorMessage ? (
                <div className="ErrorText ConsumerDateErrorText">
                    {!errorMessage ? '' : errorMessage}
                </div>
            ) : (
                ''
            )}
        </>
    ))
    return (
        <div className={`CalenderBox ConsumerCalendarWrapper ${position}`}>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                selected={dateValue}
                onChange={date => {
                    if (date) {
                        handleDateChange(name, date)
                    }
                }}
                customInput={<CustomDateInput />}
                shouldCloseOnSelect={false}
                open={openCalendar}
                calendarClassName={calendarClassName}
                calendarStartDay={1}
                maxDate={
                    maxDateType === 'future dates included'
                        ? ''
                        : toDate(new Date())
                }
                popperPlacement={
                    placePopperRight ? 'right-start' : 'left-start'
                }
                popperModifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -320],
                        },
                    },
                ]}
                filterDate={
                    filterRangeFn
                        ? filterRangeFn
                        : () => {
                              return true
                          }
                }
            >
                <div style={{ textAlign: 'center' }}>
                    <Button
                        btnBgColor="var(--purple-light)"
                        btnTextColor="var(--blue)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        btnWidth="120px"
                        handleClick={closeCalendarHandler}
                        dataTestId={`consumer-date-field-close-btn-${name}`}
                    >
                        Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnWidth="120px"
                        handleClick={() => {
                            setSelectedDate(selectedDate)
                            setHandleDateChange && setHandleDateChange(name)
                            closeCalendarHandler()
                        }}
                    >
                        Set Date
                    </Button>
                </div>
            </DatePicker>
        </div>
    )
}

export const DoubleInputFields = ({
    title,
    type,
    nameA,
    nameB,
    valueA,
    valueB,
    inputWidthA,
    inputWidthB,
    inputValueA,
    inputValueB,
    handleChangeA,
    handleChangeB,
    errorMessage = '',
    disabled,
    prefilled,
    iconType,
    inputPlaceholderA,
    inputPlaceholderB,
    TextPrefilled,
    onIconClick,
    floatingLabel,
    inputFieldWrapperStyle = {},
    passwordColor,
    autoFocus,
    mobileMarginRight,
    marginRight,
    marginBottom,
    onBlur,
    border,
    inputTooltipTextA,
    inputTooltipTextB,
    inputTooltipWidthA,
    inputTooltipWidthB,
    toolTipMarginLeftA,
    arrowPositionLeftTooltipA,
    sseUse = false,
}) => {
    const [divider, setDivider] = useState(false)
    const [doubleActive, setDoubleActive] = useState(false)

    const showDivider = () => {
        setDivider(true)
    }

    const hideDivider = () => {
        valueA.length === 0 && valueB.length === 0 ? setDivider(false) : null
    }

    return (
        <div style={{ position: 'relative' }}>
            <div
                className={`
                DoubleInputFieldsWrapper
                ${mobileMarginRight && 'mobileMarginRight'}`}
                style={{
                    marginRight: marginRight,
                    marginBottom: marginBottom,
                    gridTemplateColumns: divider ? '' : 'auto auto',
                    border: errorMessage
                        ? '1px solid #DA1E28'
                        : doubleActive
                        ? '1px solid var(--blue)'
                        : '1px solid #e5e5e5',
                }}
                onClick={showDivider}
                onBlur={() => {
                    hideDivider()
                    onBlur && onBlur()
                }}
            >
                <CustomToolTip
                    text={inputTooltipTextA}
                    toolTipFontSize="12px"
                    toolTipPadding="10px 6px 14px 11px"
                    toolTipPopupWidth={inputTooltipWidthA}
                    toolTipMarginLeft={toolTipMarginLeftA}
                    showTooltip={divider}
                    arrowPositionLeft={arrowPositionLeftTooltipA}
                >
                    <InputFields
                        inputWidth={inputWidthA}
                        border={border}
                        disabled={disabled}
                        marginBottom="0px"
                        value={valueA}
                        inputValue={inputValueA}
                        handleChange={handleChangeA}
                        inputPlaceholder={divider && inputPlaceholderA}
                        type={type}
                        disableRequiredAttribute
                        setDoubleActive={setDoubleActive}
                    />
                </CustomToolTip>
                {divider && (
                    <div
                        className={`DoubleInputDivider ${
                            sseUse && 'sseUseDivider'
                        } ${doubleActive ? 'DoubleInputDividerActive' : ''}`}
                    ></div>
                )}
                <CustomToolTip
                    text={inputTooltipTextB}
                    toolTipFontSize="12px"
                    toolTipPadding="10px 6px 14px 11px"
                    toolTipPopupWidth={inputTooltipWidthB}
                    showTooltip={divider}
                >
                    <InputFields
                        inputWidth={inputWidthB}
                        border={border}
                        disabled={disabled}
                        marginBottom="0px"
                        value={valueB}
                        inputValue={inputValueB}
                        handleChange={handleChangeB}
                        inputPlaceholder={divider && inputPlaceholderB}
                        type={type}
                        disableRequiredAttribute
                        setDoubleActive={setDoubleActive}
                    />
                </CustomToolTip>
            </div>
            <span
                className={
                    !divider && (valueA?.length === 0 || valueB?.length === 0)
                        ? 'FloatingLabel'
                        : `DoubleFloatingLabel ${
                              sseUse && 'sseDoubleFloatingLabel'
                          }`
                }
            >
                {sseUse ? inputPlaceholderA : title}
            </span>
            {divider && sseUse && (
                <span
                    className={
                        !divider &&
                        (valueA?.length === 0 || valueB?.length === 0)
                            ? 'FloatingLabel'
                            : 'doubleFloatingLabel2'
                    }
                >
                    {inputPlaceholderB}
                </span>
            )}
            {errorMessage !== '' && (
                <span className="DoubleInputErrorMessage">{errorMessage}</span>
            )}
        </div>
    )
}

export const TreeSelectAll = ({
    values,
    placeholder,
    selectedValues,
    handleTreeSelectChange,
    handleSelectAll,
    handleUnselectAll,
    floatingLabel,
    selectText,
    unselectText,
    mobileWidth,
    selectWidth,
    dropDownWidth,
    dropdownMatchSelectWidth,
    errorMessage,
    handleSearch,
    totalLength,
    disabled,
}) => {
    return (
        <>
            <div
                className={`MultiSelect ${
                    floatingLabel && selectedValues.length > 0
                        ? 'FloatParent'
                        : ''
                }`}
                style={{
                    border: errorMessage && '1px solid #DA1E28',
                }}
            >
                {selectedValues.length > 0 && (
                    <span className="FloatingLabelTreeSelection">
                        {floatingLabel}
                    </span>
                )}
                <TreeSelect
                    autoClearSearchValue={false}
                    placeholder={placeholder}
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    style={{
                        width: selectWidth,
                    }}
                    disabled={disabled}
                    dropdownStyle={{
                        borderRadius: '15px',
                        minWidth: dropDownWidth,
                        width: dropDownWidth,
                    }}
                    dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                    onChange={handleTreeSelectChange}
                    value={selectedValues}
                    maxTagCount={1}
                    showArrow
                    showSearch
                    suffixIcon={
                        <CaretDownOutlined className="SelectFloatingIcon" />
                    }
                    className={mobileWidth && 'MultipleSelectMobileWidth'}
                    dropdownClassName={mobileWidth && 'DropdownMobileWidth'}
                    treeNodeFilterProp="title"
                    treeDefaultExpandAll={true}
                    treeData={[
                        {
                            title:
                                selectedValues?.length === totalLength ? (
                                    <span
                                        onClick={handleUnselectAll}
                                        className="TreeSelectSpan"
                                    >
                                        {totalLength === 0 ? '' : unselectText}
                                    </span>
                                ) : (
                                    <div className="TreeReselectWrapper">
                                        <span
                                            onClick={handleSelectAll}
                                            className="TreeSelectSpan"
                                        >
                                            {selectText}
                                        </span>
                                        {selectedValues?.length > 0 && (
                                            <div onClick={handleUnselectAll}>
                                                <img
                                                    src={CloseIcon}
                                                    alt="close-icon"
                                                />
                                                <span className="TreeReselectText">
                                                    Reset Selection
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ),
                            value: 'xxx',
                            disableCheckbox: true,
                            disabled: true,
                            children: [...values],
                        },
                    ]}
                    onSearch={val => handleSearch(val)}
                />
            </div>
            {errorMessage ? (
                <div className="ErrorText">
                    {errorMessage === '' ? '' : errorMessage}
                </div>
            ) : (
                ''
            )}
        </>
    )
}
