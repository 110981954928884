import axios from 'src/config/axios'

export const getSingleProviderGroupApi = async id => {
    const request = axios.get(`admin/roles/${id}`)
    return request
}

export const getProviderGroupUsersApi = async id => {
    const request = axios.get(`admin/roles/users?role_id=${id}`)
    return request
}

export const updateProviderGroupApi = async (id, data) => {
    const request = axios.patch(`admin/roles/${id}`, data)
    return request
}

export const deactivateProviderGroupApi = async id => {
    const request = axios.post(`admin/roles/${id}/deactivate-provider-role`)
    return request
}

export const updateAdminGroupStatusApi = async (id, action) => {
    const request = axios.patch(`admin/roles/${id}/admin-role-status`, {
        action: action,
    })
    return request
}

export const activateProviderGroupApi = async id => {
    const request = axios.post(`admin/roles/${id}/activate-provider-role`)
    return request
}

export const removeGroupUserApi = async (user_id, role_id) => {
    const request = axios.post(`admin/users/${user_id}/update`, {
        remove_role_ids: [role_id],
    })
    return request
}

export const getPermissionsByParentResourceApi = async id => {
    const request = axios(
        `admin/permissions/group-permission?role_id=${id}&paginate=false`,
    )
    return request
}

export const getParentProviderGroupResourcesApi = async type => {
    const request = axios(`admin/resources?workspace=${type}&paginate=false`)
    return request
}

export const getChildProviderGroupResourcesApi = async parent_id => {
    const request = axios(
        `admin/resources?parent_id=${parent_id}&paginate=false`,
    )
    return request
}

export const getPermissionsUnderResourceApi = async (resource_id, paginate) => {
    const request = axios(
        `admin/permissions?resource_id=${resource_id}&paginate=${paginate}`,
    )
    return request
}
export const getPermissionsUnderRoleApi = async (
    id,
    parent_id,
    paginate = false,
) => {
    const request = axios(
        `admin/roles/${id}/permissions?parent_id=${parent_id}&paginate=${paginate}`,
    )
    return request
}

export const getAddedPermissionsUnderRoleApi = async (id, paginate = false) => {
    const request = axios(`admin/roles/${id}/permissions?paginate=${paginate}`)
    return request
}
