import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import FormTitleBar from 'src/components/FormTitleBar'
import { SelectField } from 'src/components/InputFields'
import Button from 'src/components/Button'
import styles from '../providerresources.module.scss'
import { InlineLoader } from 'src/components/Loader'
import Toast from 'src/components/Toast'

const AddProviderModal = ({
    type,
    showModal,
    onCancel,
    data,
    input,
    handleChange,
    isLoading,
    handleClick,
    toastError,
    errorMessage,
    error,
}) => {
    const { ModalWrapper } = styles
    const titleFormatting = {
        'global-library-contributors': 'Global Library Contributor',
        'global-library-users': 'Global Library User',
        'subscription-model-providers': 'Subscription model provider',
    }

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            modalWidth="624px"
            content={
                <div className={ModalWrapper}>
                    <FormTitleBar
                        title={`Add ${titleFormatting[type]}`}
                        subtitle="Select the provider from the list below"
                        marginBottom="40px"
                    />

                    {/*error toast*/}
                    {toastError && (
                        <Toast messageType="error" message={errorMessage} />
                    )}

                    <SelectField
                        initialOption="Provider Name"
                        name="providerName"
                        value={input}
                        withCheckBox={true}
                        selectWidth="100%"
                        marginBottom="111px"
                        values={data.map(option => ({
                            value: option?.name,
                        }))}
                        currentSelected={input}
                        selectedValue={input}
                        handleChange={handleChange}
                        dropdownPositionRelative
                        errorMessage={error}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            btnWidth="160px"
                            btnBgColor="#004AAD"
                            btnTextColor="#FFFFFF"
                            btnHeight="56px"
                            fontSize="14px"
                            handleClick={() => handleClick()}
                        >
                            {isLoading ? <InlineLoader /> : 'Add Provider'}
                        </Button>
                    </div>
                </div>
            }
        />
    )
}

AddProviderModal.propTypes = {
    type: PropTypes.string,
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    data: PropTypes.array,
    input: PropTypes.string,
    handleChange: PropTypes.func,
    isLoading: PropTypes.bool,
    handleClick: PropTypes.func,
    toastError: PropTypes.bool,
    errorMessage: PropTypes.any,
    error: PropTypes.string,
}

export default AddProviderModal
