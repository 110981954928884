import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './admingroupsandpermissions.scss'
import BackNav from '../../../../components/BackNav'
import CreateAdminGroup from './createGroup'
import { ReactComponent as InfoIcon } from '../../../../assets/images/info.svg'
import { ReactComponent as EnergyIllustration } from '../../../../assets/images/package.svg'
import AddAdminGroupPermissions from './addPermissions'
import Toast from 'src/components/Toast'

const AdminGroupsAndPermissions = () => {
    const [step, setStep] = useState(1)
    const [holdPermissions, setHoldPermissions] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const [permissions, setPermissions] = useState([])
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([])
    const [groupName, setGroupName] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    //permissions setting handler to be passed to all children
    const handlePermissions = arr => {
        setHoldPermissions(prevHold => [...prevHold, arr])
    }
    const location = useLocation()

    const queryParams = new URLSearchParams(location?.search)
    // user is in admin workspace - settings module
    const redirectToSettings = queryParams.get('to')
    // user is in provider workspace - users module
    const isProviderWorkspace = window.location.pathname.includes('app')
    useEffect(() => {
        let stringArr = holdPermissions.map(JSON.stringify)
        let uniqueArr = new Set(stringArr)
        setPermissions(Array.from(uniqueArr, JSON.parse))
    }, [holdPermissions])

    //clear permissions list
    const clearPermissions = () => {
        setHoldPermissions([])
    }

    //remove single item from permissions list
    const removeSinglePermission = each => {
        setHoldPermissions(
            holdPermissions.filter(arr => arr.join() !== each.join()),
        )
    }

    const handleBackNavigation = () => {
        const redirectPath = isProviderWorkspace
            ? '/app/users/listing'
            : redirectToSettings
            ? '/admin/settings'
            : '/admin/users/listing';
        window.location.href = redirectPath;
    };


    return (
        <div className="AGAPWrapper">
            <div className="AGAPNavWrapper">
                <BackNav
                    title={`Back to ${redirectToSettings || 'Group'}`}
                    onClick={handleBackNavigation} 
                />
            </div>

            <div className="AGAPContentWrapper">
                {/*left section*/}
                <div className="AGAPLeftSection">
                    <h4>Create New Group</h4>
                    <h2>
                        {step === 1
                            ? 'What’s the name of your group?'
                            : `Add permissions to ${groupName}`}
                    </h2>
                    {/*counting the number of permissions added*/}
                    {step === 2 && (
                        <div className="AGAPCount-Mobile">
                            <p>No. of permissions added</p>
                            <p>{selectedPermissions?.length}</p>
                        </div>
                    )}
                    {error && (
                        <Toast messageType="error" message={errorMessage} />
                    )}
                    {step === 1 && (
                        <CreateAdminGroup
                            setStep={setStep}
                            groupName={groupName}
                            setGroupName={setGroupName}
                        />
                    )}
                    {step === 2 && (
                        <AddAdminGroupPermissions
                            setStep={setStep}
                            module={'add-new-group'}
                            groupName={groupName}
                            permissions={permissions}
                            setPermissions={setPermissions}
                            handlePermissions={handlePermissions}
                            clearPermissions={clearPermissions}
                            removeSinglePermission={removeSinglePermission}
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                            selectedPermissionIds={selectedPermissionIds}
                            setSelectedPermissionIds={setSelectedPermissionIds}
                            redirectToSettings={redirectToSettings}
                            setError={setError}
                            setErrorMessage={setErrorMessage}
                            type={
                                isProviderWorkspace || redirectToSettings
                                    ? 'provider'
                                    : 'admin'
                            }
                            isProviderWorkspace={isProviderWorkspace}
                        />
                    )}
                </div>

                {/*right section*/}
                <div>
                    <div className="AGAPInfoWrapper">
                        <InfoIcon
                            style={{
                                transform: 'scale(2)',
                                marginLeft: 20.67,
                                marginTop: 34.67,
                            }}
                        />
                        <p>
                            We’ve solved the problems that make it difficult for
                            energy providers to transition millions
                        </p>
                        <EnergyIllustration />
                    </div>

                    {/*counting the number of permissions added*/}
                    {step === 2 && (
                        <div className="AGAPCount">
                            <p>No. of permissions added</p>
                            <p>{selectedPermissions?.length}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AdminGroupsAndPermissions
