import PropTypes from 'prop-types'
import Button, { AppButton } from 'src/components/Button'
import styles from '../providerresources.module.scss'
import { TableSkeletalCards } from 'src/components/SkeletalCards'
import { isMobile } from 'src/utils/mediaQueries'
import { canRemoveLibraryUserPermissions } from '../../Listings/data'

const ProviderListTable = ({
    data,
    handleExitClick,
    handleRemoveClick,
    isLoading,
    pageId,
}) => {
    const {
        TableHead,
        TableRow,
        BtnWrapper,
        Rows,
        Heading,
        Desktop,
        MobileTopRow,
        MobileBottomRow,
    } = styles
    const providerStatusMapping = {
        APPROVED: 'active',
        UNAPPROVED: 'pending',
        SUSPENDED: 'deactivated',
    }
    return (
        <div>
            <>
                {isLoading ? (
                    <TableSkeletalCards total={3} />
                ) : (
                    <>
                        <h2 className={Heading}>Provider List</h2>
                        <div className={`${TableHead} ${Desktop}`}>
                            <p>Name</p>
                            <p>Provider Type</p>
                            <p>Actions</p>
                        </div>
                        <div className={`${Rows}`}>
                            {data?.map((each, i) => (
                                <div key={i} className={TableRow}>
                                    {isMobile ? (
                                        <>
                                            <div className={MobileTopRow}>
                                                <div>
                                                    <p>Name</p>
                                                    <h4>{each?.name}</h4>
                                                </div>

                                                <div className={BtnWrapper}>
                                                    <AppButton
                                                        btnWidth="67px"
                                                        btnBgColor="#E2EEFF"
                                                        btnTextColor="var(--blue)"
                                                        btnHeight="37px"
                                                        fontFamily="SF-Pro-Display-Medium"
                                                        fontSize="12px"
                                                        handleClick={() =>
                                                            handleExitClick(
                                                                each?.id,
                                                                providerStatusMapping[
                                                                    each?.status
                                                                ],
                                                            )
                                                        }
                                                    >
                                                        View
                                                    </AppButton>
                                                    <Button
                                                        type="MainButtonTwo"
                                                        btnWidth="70px"
                                                        btnBgColor="transparent"
                                                        btnTextColor="var(--blue)"
                                                        btnHeight="37px"
                                                        fontSize="12px"
                                                        handleClick={() =>
                                                            handleRemoveClick(
                                                                each?.id_under_resource,
                                                            )
                                                        }
                                                        disabled={
                                                            !canRemoveLibraryUserPermissions[
                                                                pageId
                                                            ]
                                                        }
                                                        toolTipText={
                                                            !canRemoveLibraryUserPermissions[
                                                                pageId
                                                            ]
                                                                ? 'You are not authorised to perform this action'
                                                                : null
                                                        }
                                                        toolTipPopupWidth={164}
                                                        arrowPositionCenter
                                                    >
                                                        Remove
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className={MobileBottomRow}>
                                                <p>Provider Type</p>
                                                <h4>{each?.type}</h4>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h4>{each?.name}</h4>
                                            <p>{each?.type}</p>
                                            <div className={BtnWrapper}>
                                                <AppButton
                                                    btnWidth="70px"
                                                    btnBgColor="#E2EEFF"
                                                    btnTextColor="var(--blue)"
                                                    btnHeight="37px"
                                                    fontFamily="SF-Pro-Display-Medium"
                                                    fontSize="12px"
                                                    handleClick={() =>
                                                        handleExitClick(
                                                            each?.id,
                                                            providerStatusMapping[
                                                                each?.status
                                                            ],
                                                        )
                                                    }
                                                >
                                                    View
                                                </AppButton>
                                                <Button
                                                    type="MainButtonTwo"
                                                    btnWidth="70px"
                                                    btnBgColor="transparent"
                                                    btnTextColor="var(--blue)"
                                                    btnHeight="37px"
                                                    fontSize="12px"
                                                    handleClick={() =>
                                                        handleRemoveClick(
                                                            each?.id_under_resource,
                                                        )
                                                    }
                                                    disabled={
                                                        !canRemoveLibraryUserPermissions[
                                                            pageId
                                                        ]
                                                    }
                                                    toolTipText={
                                                        !canRemoveLibraryUserPermissions[
                                                            pageId
                                                        ]
                                                            ? 'You are not authorised to perform this action'
                                                            : null
                                                    }
                                                    toolTipPopupWidth={164}
                                                    arrowPositionCenter
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </>
        </div>
    )
}

ProviderListTable.propTypes = {
    data: PropTypes.array,
    handleExitClick: PropTypes.func,
    handleRemoveClick: PropTypes.func,
    isLoading: PropTypes.bool,
    pageId: PropTypes.string,
}
export default ProviderListTable
