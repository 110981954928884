import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import FormTitleBar from 'src/components/FormTitleBar'
import { InputFields } from 'src/components/InputFields'
import Button from 'src/components/Button'
import styles from '../providerGroups.module.scss'
import { InlineLoader } from 'src/components/Loader'
import Toast from 'src/components/Toast'
import { isMobile } from 'src/utils/mediaQueries'

const MoreActionsModal = ({
    showModal,
    onCancel,
    action,
    groupName,
    handleSubmit,
    inputs,
    setInputs,
    isLoading,
    error,
    errorMessage,
}) => {
    const [errors, setErrors] = useState({})
    const { ModalContent, ModalBtn, MoreActions } = styles

    const titleMapping = {
        'rename-group': 'Rename Group',
    }
    const subTitleMapping = {
        'rename-group': 'Enter the new name for this group',
    }

    const handleChange = event => {
        const { name, value } = event.target
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleClick = () => {
        if (action === 'rename-group') {
            if (inputs?.name === '') {
                setErrors({ name: 'This field is required' })
            } else {
                setErrors({})
                handleSubmit()
            }
        }
    }

    useEffect(() => {
        setInputs(prev => ({
            ...prev,
            name: groupName,
        }))
    }, [groupName, setInputs])

    return (
        <>
            <SecondaryModal
                showModal={showModal}
                onCancel={onCancel}
                modalWidth="624px"
                content={
                    <>
                        <FormTitleBar
                            title={titleMapping[action]}
                            subtitle={subTitleMapping[action]}
                            marginTop="48px"
                            titleFontSize={'20px'}
                            alignLeft={isMobile}
                            marginLeft={isMobile ? '23px' : '0px'}
                        />
                        {error && (
                            <Toast messageType="error" message={errorMessage} />
                        )}
                        <div className={ModalContent}>
                            <InputFields
                                title="Group Name"
                                inputWidth="100%"
                                name="name"
                                type="text"
                                value={inputs?.name}
                                errorMessage={errors?.name}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className={`${ModalBtn} ${MoreActions}`}>
                            <Button
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                btnWidth="160px"
                                btnHeight="56px"
                                fontSize="14px"
                                handleClick={() => handleClick()}
                            >
                                {isLoading ? <InlineLoader /> : 'Rename Group'}
                            </Button>
                        </div>
                    </>
                }
            />
        </>
    )
}
MoreActionsModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    action: PropTypes.string,
    groupName: PropTypes.string,
    handleSubmit: PropTypes.func,
    inputs: PropTypes.object,
    setInputs: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
}
export default MoreActionsModal
