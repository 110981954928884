import { SelectField } from 'src/components/InputFields'
import '../../consumerkyc.scss'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import mono from 'src/assets/images/mono.svg'
import UploadField from 'src/components/InputFields/UploadField'
import { useState } from 'react'
import InputFieldWithButton from 'src/components/InputFields/InputFieldWithButton'
import { useConsumerKYCContext } from '../../context'

const FinancialInformationSection = () => {
    const { useType, inputs } = useConsumerKYCContext()
    const [uploadType, setUploadType] = useState('third-party') // third-party || manual-upload
    const { isMobile } = useMediaQueries()

    const isManualUpload = uploadType === 'manual-upload'
    const isThirdParty = uploadType === 'third-party'

    const handleUploadType = () => {
        if (uploadType === 'third-party') {
            setUploadType('manual-upload')
        } else {
            setUploadType('third-party')
        }
    }

    return (
        <div className="FinancialKYC">
            {(useType === 'residential' || useType === 'business') && (
                <div
                    className={` ${useType === 'business' ? 'GroupItems' : ''}`}
                >
                    <InputFieldWithButton
                        title="Bank Verfication Number"
                        type="number"
                        name="bvn"
                        btnText={isMobile ? 'Verify' : 'Verify your Bvn'}
                        value={inputs?.bvn}
                        mobileBtnText="Verify"
                    />
                    {isManualUpload && useType === 'residential' && (
                        <div className="GroupItems">
                            <SelectField initialOption="Select the bank you want to upload from" />
                            <UploadField
                                width="100%"
                                btnText="Upload bank statement (PDF)"
                            />
                        </div>
                    )}
                    {useType === 'business' && isManualUpload && (
                        <UploadField
                            btnText="Audited financial report"
                            width="100%"
                        />
                    )}
                </div>
            )}

            {isThirdParty && (
                <div className="ThirdParty">
                    <p>
                        By clicking on “connect to Mono” we help you securely
                        retrieve your account statement faster to enable us
                        complete your KYC process. However, please note that
                        this process does NOT give us any access to your bank
                        username, password, nor can we make payments without
                        your permission.
                    </p>
                    <div>
                        <button>
                            <img src={mono} />
                            {isMobile ? 'Use Mono' : 'Connect to Mono'}
                        </button>
                    </div>
                    <p className="note">
                        Please note that we don not support dollar accounts or
                        microfinance banks
                    </p>
                </div>
            )}
            <small className={isManualUpload ? 'ManualUpload' : ''}>
                {uploadType === 'third-party'
                    ? 'Do you have your bank statement in PDF?'
                    : 'Don’t have your bank statement?'}
                {'   '}
                <span role="button" onClick={handleUploadType}>
                    {uploadType === 'third-party'
                        ? 'Upload here'
                        : 'Use our third party partner'}
                </span>
            </small>
        </div>
    )
}

export default FinancialInformationSection
