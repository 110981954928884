import { useEffect, useState } from 'react'
import { decodeUserInfo } from '../auth'

export const useApplyPermissions = () => {
    const [
        applyDynamicPermissionsToProviderUser,
        setApplyDynamicPermissionsToProviderUser,
    ] = useState(false)
    const [
        applyDynamicPermissionsToAdminUser,
        setApplyDynamicPermissionsToAdminUser,
    ] = useState(false)

    useEffect(() => {
        const userInfo = decodeUserInfo()
        const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'

        setApplyDynamicPermissionsToProviderUser(
            !isAdminWorkspace &&
                process.env.REACT_APP_FLAG_ENFORCE_PERMISSION === 'true',
        )
        setApplyDynamicPermissionsToAdminUser(
            isAdminWorkspace &&
                process.env.REACT_APP_FLAG_ENFORCE_ADMIN_PERMISSION === 'true',
        )
    }, [])

    return {
        applyDynamicPermissionsToAdminUser,
        applyDynamicPermissionsToProviderUser,
    }
}
