import { repaymentMethodReverseMap } from 'src/utils/repaymentMethods'
import { sameAddressOptions } from '../CreateAccount/OutrightSaleInformation/utils'
import { parsePhoneNumber } from 'libphonenumber-js'
import { numberWithCommas } from 'src/utils/formatting'

export const formatConsumerKYC = (data, inputs) => {
    let generalInfo = {
        first_name: data?.consumer?.user?.first_name ?? inputs?.first_name,
        last_name: data?.consumer?.user?.last_name ?? inputs?.last_name,
        phone_number: data?.consumer?.user?.phone_number
            ? parsePhoneNumber(data?.consumer?.user?.phone_number || '', 'NG')
                  ?.nationalNumber
            : inputs?.phone,
        phoneVerified: data?.consumer?.user?.phone_verified,
        email: data?.consumer?.user?.email ?? inputs?.email,
        emailVerified: data?.consumer?.user?.email_verified,
        contactAddress:
            data?.consumer?.user?.street_address ?? inputs?.contactAddress,
        contactState:
            data?.consumer?.user?.state_of_residence ?? inputs?.contactState,
        contactCity: data?.consumer?.user?.city ?? inputs?.contactCity,
        contactCountry: data?.consumer?.user?.country ?? inputs?.contactCountry,
        sameAddress:
            data?.consumer?.user?.street_address ===
                data?.consumer?.installation_details?.street_address &&
            data?.consumer?.user?.state_of_residence ===
                data?.consumer?.installation_details?.state &&
            data?.consumer?.user?.city ===
                data?.consumer?.installation_details?.city
                ? sameAddressOptions[0]
                : data?.consumer?.user?.street_address !==
                      data?.consumer?.installation_details?.street_address &&
                  data?.consumer?.installation_details?.street_address?.length >
                      0 &&
                  data?.consumer?.installation_details?.state?.length > 0 &&
                  data?.consumer?.installation_details?.city?.length > 0
                ? sameAddressOptions[1]
                : sameAddressOptions[0],
        installationAddress:
            data?.consumer?.installation_details?.street_address ??
            inputs?.installationAddress,
        installationState:
            data?.consumer?.installation_details?.state ??
            inputs?.installationState,
        installationCity:
            data?.consumer?.installation_details?.city ??
            inputs?.installationCity,
        installationCountry:
            data?.consumer?.installation_details?.country ??
            inputs?.installationCountry,
        employmentType:
            data?.consumer?.employment_status ?? inputs?.employmentType,
        companyName:
            data?.consumer?.employment_details?.company_name ??
            inputs?.companyName,
        jobTitle:
            data?.consumer?.employment_details?.job_title ?? inputs?.jobTitle,
        jobStartDate:
            data?.consumer?.employment_details?.employment_start_date ??
            inputs?.jobStartDate,
        employmentContractUrl:
            data?.consumer?.employment_details?.employment_contract_url ??
            inputs?.employmentContractUrl,
        linkedinPage:
            data?.consumer?.employment_details?.linkedin_url ??
            inputs?.linkedinPage,
        netMonthlySalary: data?.consumer?.employment_details?.net_monthly_salary
            ? numberWithCommas(
                  data?.consumer?.employment_details?.net_monthly_salary,
              )
            : '',
        employeeId:
            data?.consumer?.employment_details?.employee_id ??
            inputs?.employeeId,
        registeredBusiness:
            data?.consumer?.business?.is_business_registered ??
            inputs?.registeredBusiness,
        business_name:
            data?.consumer?.business?.business_name ?? inputs?.business_name,
        business_industry:
            data?.consumer?.business?.business_industry ??
            inputs?.business_industry,
        cacNumber: data?.consumer?.business?.business_registration_number,
        cacNumberVerified:
            data?.consumer?.business?.business_registration_number_verified,
        numberOfYearsInOperation:
            data?.consumer?.business?.years_in_operation ??
            inputs?.numberOfYearsInOperation,
        business_website:
            data?.consumer?.business?.business_website ??
            inputs?.business_website,
        business_description:
            data?.consumer?.business?.business_description ??
            inputs?.business_description,
        pension_manager:
            data?.consumer?.pension_details?.pension_manager ??
            inputs?.pension_manager,
        retirementDate:
            data?.consumer?.pension_details?.retirement_date ??
            inputs?.retirementDate,
        selfieImageUrl: data?.consumer?.user?.avatar_url ?? '',
        identityType: data?.identity_verification?.type ?? inputs?.identityType,
        identityNumber:
            data?.identity_verification?.number ?? inputs?.identityNumber,
        identityVerificationId:
            data?.identity_verification?.id ?? inputs?.identityVerificationId,
        identityVerified:
            data?.identity_verification?.verified ?? inputs?.identityVerified,
        bvn: data?.financial_verification?.bvn?.number ?? inputs?.bvn,
        bvnVerified:
            data?.financial_verification?.bvn?.verified ?? inputs?.bvnVerified,
        repaymentMethod: data?.consumer?.repayment_method
            ? repaymentMethodReverseMap[data?.consumer?.repayment_method]
            : inputs?.repaymentMethod,
        bankStatements:
            data?.financial_verification?.bank_statements ??
            inputs?.bankStatements,
        business: data?.consumer?.business,
        business_type:
            data?.consumer?.business?.business_type ?? inputs?.business_type,
        director_email:
            data?.consumer?.director_details?.email ?? inputs?.director_email,
        director_first_name:
            data?.consumer?.director_details?.first_name ??
            inputs?.director_first_name,
        director_last_name:
            data?.consumer?.director_details?.last_name ??
            inputs?.director_last_name,
        director_phone_number:
            data?.consumer?.director_details?.phone_number ??
            inputs?.director_phone_number,
    }
    return generalInfo
}
