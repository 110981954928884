import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { message, Upload } from 'antd'
import { ReactComponent as UploadIcon } from 'src/assets/images/upload-icon.svg'
import { ReactComponent as SuccessUploadIcon } from 'src/assets/images/double-tick-success-icon.svg'
import { truncateSentence } from 'src/utils/formatting'
import { handleMultipleFilesUpload } from './utils'
import styles from './imageupload.module.scss'

const ImageUpload = ({
    successUpload,
    setSuccessUpload,
    acceptableFileTypes = { 'image/jpeg': 'jpeg', 'image/png': 'png' },
    isLoading,
    maxCount = 1,
    maxSize = 1,
    multiple = false,
    showUploadList = false,
    imageURL,
}) => {
    const [showAdditionalLoadingCopy, setShowAdditionalLoadingCopy] =
        useState(false)
    const [uploading, setUploading] = useState(false)
    const [width, setWidth] = useState(40)
    const [, setFileValid] = useState(true)
    const [imageDataURL, setImageDataURL] = useState('')
    const additionalCopyTimeoutRef = useRef(null)

    const { Dragger } = Upload

    const getFileName = () => {
        let name = ''

        if (imageURL?.length) {
            name = imageURL.split('/').pop()
        } else if (successUpload?.[0]?.name) {
            name = successUpload[0].name
        }

        return truncateSentence(name, 40)
    }

    const createBlobURLFromFile = file => {
        if (file) {
            const blobUrl = URL.createObjectURL(file.originFileObj)
            setImageDataURL(blobUrl)
        }
    }

    const handleViewFileButtonClick = event => {
        event.stopPropagation()

        const url = imageURL || imageDataURL
        window.open(url, '_blank')
    }

    const props = {
        name: 'file',
        onChange({ fileList }) {
            if (!multiple) {
                setUploading(true)
                const isLt2M = fileList[0].size / 1000 / 1000 <= maxSize

                if (
                    !Object.keys(acceptableFileTypes)?.includes(
                        fileList[0].type,
                    )
                ) {
                    message.error(
                        `${
                            fileList[0].name
                        } is not one of the supported file types (${Object.values(
                            acceptableFileTypes,
                        )?.join(',')})`,
                    )
                    setFileValid(false)
                    setUploading(false)
                    return false
                } else if (!isLt2M) {
                    message.error(`File should be less than ${maxSize}mb`)
                    setFileValid(false)
                    setUploading(false)
                    return false
                } else {
                    setFileValid(true)
                    setSuccessUpload(fileList)
                    setUploading(false)
                    createBlobURLFromFile(fileList[0])
                    return true
                }
            }
        },
        beforeUpload(fileList) {
            if (multiple) {
                setUploading(true)
                handleMultipleFilesUpload(
                    fileList,
                    setFileValid,
                    setUploading,
                    message,
                    setSuccessUpload,
                    acceptableFileTypes,
                )
            }
        },
        onDrop() {
            setUploading(false)
        },
    }

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    useEffect(() => {
        if (isLoading) {
            additionalCopyTimeoutRef.current = setTimeout(() => {
                setShowAdditionalLoadingCopy(true)
            }, 5000)
        } else {
            setShowAdditionalLoadingCopy(false)
            if (additionalCopyTimeoutRef.current) {
                clearTimeout(additionalCopyTimeoutRef.current)
                additionalCopyTimeoutRef.current = null
            }
        }
    }, [isLoading])

    return (
        <>
            <Dragger
                className={`${styles.ImageUploadDragger} ${
                    successUpload?.length > 0
                        ? styles.WithFile
                        : styles.WithoutFile
                }`}
                fileList={successUpload}
                maxCount={maxCount}
                showUploadList={showUploadList}
                customRequest={() => {}}
                multiple={multiple}
                accept={Object.keys(acceptableFileTypes)?.join(',')}
                {...props}
            >
                {uploading ? (
                    <div className="SlideContainer">
                        <p
                            className="Slider"
                            style={{
                                width: `${width}%`,
                            }}
                        ></p>
                    </div>
                ) : successUpload?.length > 0 ? (
                    <div className={styles.ImageUploadContent}>
                        <SuccessUploadIcon />
                        {multiple ? (
                            <div
                                style={{
                                    fontSize: '12px',
                                    maxWidth: '85%',
                                    margin: '0 auto',
                                }}
                            >
                                {successUpload
                                    ?.map(file => file.name)
                                    .join(', ')}
                            </div>
                        ) : (
                            <>
                                <p>{getFileName()}</p>
                                <button
                                    className={styles.viewFileButton}
                                    onClick={handleViewFileButtonClick}
                                >
                                    View this file
                                </button>
                            </>
                        )}

                        {showAdditionalLoadingCopy && (
                            <p className={styles.UMC__AdditionalLoadingCopy}>
                                Your upload is taking a bit longer than
                                <br />
                                usual, we are working on it
                            </p>
                        )}
                    </div>
                ) : (
                    <div className={styles.ImageUploadContent}>
                        <UploadIcon className={styles.uploadIcon} />
                        <p>
                            Upload file. Drag and drop or <span>browse</span>
                        </p>
                        <p className={styles.fileSize}>
                            The maximum accepted file size is {maxSize}mb
                        </p>
                        <p className={styles.fileFormat}>
                            Supported format :{' '}
                            {Object.values(acceptableFileTypes).join(' & ') ||
                                ''}
                        </p>
                    </div>
                )}
            </Dragger>
        </>
    )
}

ImageUpload.propTypes = {
    isLoading: PropTypes.bool,
    successUpload: PropTypes.array,
    setSuccessUpload: PropTypes.func,
    acceptableFileTypes: PropTypes.object,
    setFileToState: PropTypes.func,
    maxCount: PropTypes.number,
    maxSize: PropTypes.number,
    multiple: PropTypes.bool,
    showUploadList: PropTypes.bool,
    imageURL: PropTypes.string,
}

export default ImageUpload
