import PropTypes from 'prop-types'
import '../consumerkyc.scss'
import { ReactComponent as HelpIcon } from 'src/assets/images/help-circle.svg'
import arrow from 'src/assets/images/arrowDown.svg'
import CustomToolTip from 'src/components/CustomToolTip'

const SectionContainer = ({ title, children, isOpen, onToggle, info }) => {
    return (
        <div className="SectionContainer">
            <div
                className={`HeaderContainer ${isOpen ? 'isOpen' : ''}`}
                onClick={onToggle}
            >
                <h6 className="Heading">
                    {title}{' '}
                    <CustomToolTip arrowPositionCenter text={info}>
                        <HelpIcon className="OPKYC_HeadingHelpIcon" />
                    </CustomToolTip>
                </h6>
                <div className="GroupItems ProgressToggle">
                    <div className="ProgressContainer">
                        <div className="GroupItems">
                            <div className="GroupItems">
                                {[...Array.from({ length: 5 })].map(index => (
                                    <span key={index}></span>
                                ))}
                            </div>
                            <p>0/5</p>
                        </div>
                    </div>
                    <img
                        src={arrow}
                        alt="arrow"
                        className={isOpen ? 'TopArrowOpen' : 'TopArrowClosed'}
                    />
                </div>
            </div>
            {isOpen && <>{children}</>}
        </div>
    )
}

SectionContainer.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    children: PropTypes.node,
    info: PropTypes.string,
}
export default SectionContainer
