import { isMobile } from 'src/utils/mediaQueries'
import { InputFields } from 'src/components/InputFields'
import './inputfieldwithbutton.scss'
import PropTypes from 'prop-types'

const InputFieldWithButton = ({
    title,
    btnText,
    name,
    mobileBtnText,
    type,
    handleChange,
    disabled,
    value,
}) => {
    return (
        <div className="field_container">
            <InputFields
                title={title}
                type={type}
                name={name}
                marginBottom="10px"
                handleChange={handleChange}
                disabled={disabled}
                value={value}
            />
            <button>
                <span>{isMobile ? mobileBtnText : btnText}</span>
            </button>
        </div>
    )
}

InputFieldWithButton.propTypes = {
    title: PropTypes.string,
    btnText: PropTypes.string,
    name: PropTypes.string,
    mobileBtnText: PropTypes.string,
    type: PropTypes.string,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,
}

export default InputFieldWithButton
