import { employmentTypes, businessTypes } from '../../data'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import UploadField from 'src/components/InputFields/UploadField'
import InputFieldWithButton from 'src/components/InputFields/InputFieldWithButton'
import { useConsumerKYCContext } from '../../context'
import { businessIndustry } from 'src/utils/business'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const DetailsSection = () => {
    const { useType, inputs, setInputs, handleInputChange } =
        useConsumerKYCContext()
    const { isMobile } = useMediaQueries()
    const isBusiness = useType === 'business'
    const isResidential = useType === 'residential'
    const types = isBusiness ? businessTypes : employmentTypes
    const handleTypeSelection = item => {
        const name = useType === 'business' ? 'business_type' : 'employmentType'
        setInputs(prev => {
            if (prev[name] === item) {
                return { ...prev, [name]: null }
            } else {
                return { ...prev, [name]: item }
            }
        })
    }

    const handleBusinessRegistrationToggle = isRegistered => {
        setInputs(prev => ({
            ...prev,
            registeredBusiness: isRegistered,
        }))
    }

    return (
        <div className="DetailsContainer">
            <p>
                Select Your {isBusiness ? 'Business type' : 'Employment Type'}
            </p>
            <div className="GroupItems Types">
                {types?.map(item => (
                    <span
                        key={item}
                        onClick={() => handleTypeSelection(item)}
                        className={`Badge${
                            inputs?.employmentType === item ||
                            inputs?.business_type === item
                                ? ' ActiveType'
                                : ''
                        }`}
                    >
                        {item}
                    </span>
                ))}
            </div>

            {(inputs?.employmentType !== '' ||
                inputs?.business_type !== '') && (
                <>
                    {isResidential &&
                        inputs?.employmentType === 'Self Employed' && (
                            <div className="isBusiness">
                                <p>Is your business registered?</p>
                                <div className="GroupItems ContainsBadges">
                                    <span
                                        onClick={() =>
                                            handleBusinessRegistrationToggle(
                                                true,
                                            )
                                        }
                                        className={`Badge ${
                                            inputs?.registeredBusiness === true
                                                ? 'ActiveType'
                                                : ''
                                        }`}
                                    >
                                        Yes
                                    </span>
                                    <span
                                        onClick={() =>
                                            handleBusinessRegistrationToggle(
                                                false,
                                            )
                                        }
                                        className={`Badge ${
                                            inputs?.registeredBusiness === false
                                                ? 'ActiveType'
                                                : ''
                                        }`}
                                    >
                                        No
                                    </span>
                                </div>
                            </div>
                        )}
                    {((isBusiness && inputs?.business_type !== '') ||
                        (isResidential &&
                            inputs?.employmentType === 'Self Employed')) && (
                        <div>
                            <div
                                className="GroupItems BusinessDetails"
                                style={{
                                    margin:
                                        (isResidential &&
                                            inputs?.registeredBusiness !==
                                                undefined) ||
                                        isBusiness
                                            ? ''
                                            : 0,
                                }}
                            >
                                {isResidential &&
                                    inputs?.registeredBusiness !==
                                        undefined && (
                                        <InputFields
                                            title="Business name"
                                            value={inputs?.business_name}
                                            name="business_name"
                                            handleChange={handleInputChange}
                                        />
                                    )}

                                {(inputs?.registeredBusiness === false ||
                                    isBusiness) && (
                                    <SelectField
                                        initialOption="Business Industry"
                                        value={inputs?.business_industry}
                                        values={businessIndustry.map(
                                            option => ({
                                                value: option,
                                            }),
                                        )}
                                        withCheckBox
                                        currentSelected={
                                            inputs?.business_industry
                                        }
                                        selectedValue={
                                            inputs?.business_industry
                                        }
                                    />
                                )}
                                {(inputs?.registeredBusiness || isBusiness) && (
                                    <InputFieldWithButton
                                        title="CAC"
                                        btnText={
                                            isMobile
                                                ? 'Verify'
                                                : 'Verify CAC number'
                                        }
                                        type="number"
                                        name="cacNumber"
                                        mobileBtnText="Verify"
                                        value={inputs?.cacNumber}
                                        handleChange={handleInputChange}
                                    />
                                )}
                            </div>
                            {((isBusiness &&
                                inputs?.business_type !==
                                    'Non-governmental Organisation') ||
                                inputs?.registeredBusiness === false ||
                                isBusiness) && (
                                <div className="GroupItems BusinessDetails">
                                    {inputs?.business_type !==
                                        'Non-governmental Organisation' && (
                                        <UploadField
                                            width="100%"
                                            btnText="Memorandum of association"
                                        />
                                    )}
                                    {(inputs?.registeredBusiness === false ||
                                        isBusiness) && (
                                        <TextArea
                                            placeholder="Tell us about your business (example: how many years of operations, if you have a website, etc.)"
                                            height={'159px'}
                                            resize={false}
                                            width={327}
                                            value={inputs?.business_description}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {inputs?.employmentType === 'Retired' && (
                        <div className="GroupItems flex-column">
                            <InputFields
                                title="Pension Manager"
                                value={inputs?.pension_manager}
                                marginBottom={0}
                            />
                            <InputFields
                                title="Date of Retirement"
                                marginBottom={0}
                            />
                        </div>
                    )}
                    {((!isBusiness && inputs?.employmentType === 'Full Time') ||
                        inputs?.employmentType === 'Part Time' ||
                        inputs?.employmentType === 'Contract') && (
                        <div className="GroupItems flex-column">
                            <InputFields
                                title="Where do you work"
                                value={inputs?.companyName}
                                marginBottom={0}
                            />
                            <InputFields
                                title="Job title"
                                value={inputs?.jobTitle}
                                marginBottom={0}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default DetailsSection
