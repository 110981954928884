/* eslint-disable react/prop-types */
import {useState} from 'react'
import FormTitleBar from "src/components/FormTitleBar"
import { InputFields } from "src/components/InputFields"
import ActionModal from 'src/components/ActionModal'
import AddAttribute from "../../ComponentTypes/components/AddAttribute"
import Button from "src/components/Button"
import './addcomponent.scss'

const AddComponentType = ({closeAddComponentType}) => {
    const [showAddAttributeModal, setShowAddAttributeModal] = useState(false)
    const [componentName, setComponentName] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [errors, setErrors] = useState('')

    const handleShowAddAttribute = () => {
        if (componentName === '') {
            setErrors('Component name is required')
        } else {
            setShowAddAttributeModal(true)
            closeAddComponentType()
        }
    }

    const handleAddAttribute = () => {
        setShowAddAttributeModal(false)
        closeAddComponentType()
        setShowSuccess(true)
    }
    const handleInputChange = event => {
        setComponentName(event.target.value)
        setErrors('')
    }


    return (
        <>
            <AddAttribute
                showModal={showAddAttributeModal}
                onCancel={() => {
                    setShowAddAttributeModal(false)
                }}
                handleAddAttribute={handleAddAttribute}
                cancelText="Previous"
                componentName={componentName}
            />
            <ActionModal
                actionModalOpen={showSuccess}
                actionType="success"
                headerText={'Component type added sucessfully'}
                onCancel={() => setShowSuccess(false)}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText="Okay, Got it"
                actionHandler={() => {
                    setShowSuccess(false)
                    window.location.href = '/admin/settings?tab=4'
                }}
                closable
                noBackLink
                noCancelBtn
            />
            <div>
                <FormTitleBar
                    title="Add component type"
                    subtitle="Enter the details of the appliance "
                    fontFamily="SF-Pro-Display-Bold"
                    titleFontSize={20}
                />
                <div style={{marginTop: 54}}>
                    <InputFields
                        title="Component Name"
                        name="componentName"
                        inputWidth="100%"
                        handleChange={handleInputChange}
                        inputValue={componentName}
                        errorMessage={errors}
                    />
                </div>
                <div className="AddComponentBtn">
                    <Button
                        btnTextColor="var(--white)"
                        btnBgColor="var(--blue)"
                        btnWidth="150px"
                        btnHeight="56px"
                        fontSize="14px"
                        fontFamily="SF-Pro-Display-Semibold"
                        handleClick={handleShowAddAttribute}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </>
    )
}

export default AddComponentType