import PropTypes from 'prop-types'
import './admingroupsandpermissions.scss'
import { NoFLoatingLabelInputFields } from '../../../../components/InputFields'
import Button from '../../../../components/Button'

const CreateAdminGroup = ({ setStep, groupName, setGroupName }) => {
    const searchChangeHandler = e => {
        e.preventDefault()
        setGroupName(e.target.value)
    }

    const handleClick = () => {
        setStep(2)
    }
    return (
        <div className="CreateAdminGroupWrapper">
            <NoFLoatingLabelInputFields
                inputWidth="409px"
                inputBackground="#FFFFFF"
                inputPlaceholder="Group name"
                value={groupName}
                TextPrefilled={groupName ? true : false}
                handleChange={searchChangeHandler}
            />
            <div className='AGAPCreateGroupButton'>
                <Button
                    btnWidth="132px"
                    btnHeight="50px"
                    btnBgColor="#004AAD"
                    btnTextColor="#FFFFFF"
                    fontSize="14px"
                    fontFamily="SF-Pro-Display-Semibold"
                    handleClick={handleClick}
                    disabled={groupName.length <= 2}
                >
                    Continue
                </Button>
            </div>
        </div>
    )
}

CreateAdminGroup.propTypes = {
    setStep: PropTypes.func,
    groupName: PropTypes.string,
    setGroupName: PropTypes.func,
}

export default CreateAdminGroup
