import { format, parseISO } from 'date-fns'

export const formattedUsersData = data => {
    return data.map(item => {
        return {
            id: item.id,
            full_name: item.first_name + ' ' + item.last_name,
            phone_number: item.phone_number,
            active: item.state === 'ACTIVE' ? 'Yes' : 'No',
            email: item.email,
        }
    })
}

export const formattedGroupsData = data => {
    return data.map(item => {
        return {
            id: item.id,
            group_name: item.name,
            permissions: item.permissions_count,
            users: item.users_count,
            status: item.is_active ? 'Active' : 'Deactivated',
            created_time: format(
                parseISO(item.created_at),
                'dd MMM yyyy, HH:mm',
            ),
        }
    })
}

export const userStatus = [
    {
        id: 'active',
        label: 'Active',
    },
    {
        id: 'deactivated',
        label: 'Deactivated',
    },
]

export const groups = [
    { label: 'USER_SERVICE', value: 'User Service' },
    { label: 'GROWTH', value: 'Growth' },
    { label: 'FINANCE', value: 'Finance' },
    { label: 'DEVELOPER', value: 'Developer' },
    { label: 'ADMIN', value: 'Admin' },
]

export const extractEmailOrNumber = str => {
    const extractedValue =
        str === null
            ? 'N/A'
            : str === undefined
            ? 'N/A'
            : str.includes('DEACTIVATED')
            ? str.split('-')[2]
            : str
    return extractedValue
}
