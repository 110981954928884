import DirectorInfo from './components/Business/DirectorInfo'
import IdentityInformationSection from './components/Residential/IdentityInformationSection'
import BasicInformationSection from './components/Shared/BasicInformationSection'
import PersonalInfo from './components/Shared/PersonalInfo'
import FinancialInformationSection from './components/Shared/FinancialInformation'

export const residentialKYC = [
    {
        title: 'Basic Information',
        component: <BasicInformationSection />,
        info: '',
    },
    {
        title: 'Identity Information',
        component: <IdentityInformationSection />,
        info: '',
    },
    {
        title: 'Financial Information',
        component: <FinancialInformationSection />,
        info: '',
    },
]

export const businessKYC = [
    {
        title: 'Business Information',
        component: <BasicInformationSection />,
        info: '',
    },
    {
        title: "Director's Information",
        component: <DirectorInfo />,
        info: '',
    },
    {
        title: 'Contact Information',
        component: <PersonalInfo />,
        info: '',
    },
    {
        title: 'Financial Information',
        component: <FinancialInformationSection />,
        info: '',
    },
]

export const useTypeMapping = {
    RESIDENTIAL: 'residential',
    BUSINESS: 'business',
}

export const actionModalValues = (action, useType) => {
    switch (action) {
        case 'save-progress':
            return {
                actionModalTitle: 'Getting started on SunFi',
                actionModalHeaderText: 'Save your progress? Please confirm',
                actionModalSubtitle:
                    "You can save your progress and come back to continue anytime. We'll also send an email on how you can continue",
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'edit-info':
            return {
                actionModalTitle: 'Editing your info',
                actionModalHeaderText:
                    'This action might affect your recommendations',
                actionModalSubtitle:
                    'Please, be aware that editing your previously added info might affect your recommendations.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Edit my info',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'change-use-type':
            return {
                actionModalTitle: `Switch to ${
                    useType === 'business' ? 'residential' : 'business'
                } use`,
                actionModalHeaderText: `Details required for ${
                    useType === 'business' ? 'residential' : 'business'
                } use are different`,
                actionModalSubtitle:
                    useType === 'business'
                        ? 'Please, be aware that switching to a residential use will require you to submit documents such as ID numbers and 6-12 months of up-to-date, verified bank statements'
                        : 'Please, be aware that switching to a business use will require you to submit documents such as CAC documents and 12-24 months of up-to-date, verified bank statements',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}

export const employmentTypes = [
    'Full Time',
    'Part Time',
    'Contract',
    'Self Employed',
    'Retired',
]

export const businessTypes = [
    'Sole Proprietorship',
    'Limited Liability Company',
    'Non-governmental Organisation',
    'Self-Employed',
]
