import { useEffect, useState } from 'react'
import { businessKYC, residentialKYC } from '../data'
import {
    getKYCInfo,
    retrieveEstimationApi,
} from 'src/api/shoppingExperience/account-overview'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { formatAccountOverviewData } from '../../AccountSetupOverview/data'
import { decodeUserInfo } from 'src/utils/auth'
import { errorHandler } from 'src/utils/errorHandler'
import { formatConsumerKYC } from '../utils'
import { removeCountryCodefromNGNNum } from 'src/utils/formatting'
import { validateResidentialOnePageKyc } from 'src/utils/validationSchema'

const useConsumerKYC = useType => {
    const [sections] = useState(
        useType === 'business' ? businessKYC : residentialKYC,
    )
    const [isOpen, setIsOpen] = useState(Array(sections.length).fill(true))
    const [estimation, setEstimation] = useState({})
    const [showSecondaryModal, setShowSecondaryModal] = useState(false)
    const [action, setAction] = useState('') // save-progress | show-video | progress-saved | edit-info
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [showEditInputDrawer, setShowEditInputDrawer] = useState(false)
    const [kycData, setKycData] = useState({})
    const [showToastError, setShowToastError] = useState(false)
    const [isPromo, setIsPromo] = useState(false)
    const [clusterCode, setClusterCode] = useState('')
    const [errors, setErrors] = useState({})
    useState(false)
    const [showSimulateCreditDecision, setShowSimulateCreditDecision] =
        useState(false)

    const contact_type =
        kycData?.consumer?.user?.email !== '' ? 'email' : 'phone'
    const contact_details =
        kycData?.consumer?.user?.email !== ''
            ? kycData?.consumer?.user?.email
            : removeCountryCodefromNGNNum(kycData?.consumer?.user?.phone_number)

    const [errorMessage, setErrorMessage] = useState('')

    const [gettingStartedModalOpen, setGettingStartedModalOpen] =
        useState(false)
    const [inputs, setInputs] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        street_address: '',
        city: '',
        state_of_residence: '',
        country: '',
        employmentType: '',
        company_name: '',
        job_title: '',
        is_business_registered: '',
        pension_manager: '',
        retirement_date: '',
        selfie_url: '',
        bank_statement_id: '',
        estimation_id: '',
        business_type: '',
        businessIndustry: '',
        years_in_operation: '',
        business_website: '',
        business_name: '',
        businessDescription: '',
        bvn: '',
    })

    const history = useHistory()
    const userInfo = decodeUserInfo()
    const isDEPConsumer = JSON.parse(userInfo?.isDEPConsumer || false)
    const convertedOnboardingType =
        estimation?.onboardingType === 'ALL_SOLUTIONS' ||
        estimation?.onboardingType === 'ENERGY_PROFILE'
            ? 'profiles-or-all-systems'
            : 'custom-build'

    const onboardingType =
        history?.location?.state?.onboardingType ?? convertedOnboardingType

    const goBackToSystem = () => {
        history.push({
            pathname: `/consumer/view-system/${estimation?.solution_id}`,
            state: {
                isAuthenticatedUser: true,
                estimation_id: estimation?.id,
                action: 'view_from_account_setup',
                auth_user_id: kycData?.consumer?.user?.id,
                page: isPromo && 'promo',
                clusterCode: clusterCode,
                payment_model: estimation.paymentType,
                onboardingType,
                profile_id:
                    kyc_data?.data?.data?.consumer_form_questions
                        ?.energy_profile_id,
                returnToKyc: estimation?.isDynamicKycUser,
            },
        })
    }

    const handleShowVideo = () => {
        setAction('show-video')
        setShowSecondaryModal(true)
    }

    const handleInputChange = e => {
        const { name, value } = e.target
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleKYCSubmission = () => {
        validateResidentialOnePageKyc()
            .validate({
                ...inputs,
            })
            .then(() => {
                setErrors({})
            })
            .catch(err => {
                let errList = { ...errors }
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(prev => ({ ...prev, ...errList }))
            })
    }

    const {
        isLoading,
        refetch: fetchEstimation,
        data: kyc_data,
    } = useQuery('fetch-estimation', () => retrieveEstimationApi(), {
        enabled: false,
        retry: false,
        onSuccess: res => {
            const data = res?.data?.data
            setEstimation(formatAccountOverviewData(data))
            setClusterCode(data?.consumer_form_questions?.cluster_code)
            setIsPromo(data?.is_promo_estimation)
            if (
                data?.customer_type === 'BUSINESS' &&
                !history.location?.pathname?.includes('business')
            ) {
                window.location.href = '/consumer/account-setup/business'
            }

            if (
                data?.customer_type === 'RESIDENTIAL' &&
                !history.location?.pathname?.includes('residential')
            ) {
                window.location.href = '/consumer/account-setup/residential'
            }
        },
    })

    const handleSubmit = () => {
        handleKYCSubmission()
    }

    const handleEditInfo = () => {
        setAction('edit-info')
        setActionModalOpen(true)
    }

    const handlePrimaryBtnClick = () => {
        switch (action) {
            case 'save-progress':
                setActionModalOpen(false)
                setAction('progress-saved')
                setShowSecondaryModal(true)
                break
            case 'edit-info':
                setActionModalOpen(false)
                setAction('')
                setShowEditInputDrawer(true)
                break
            case 'change-use-type':
                setActionModalOpen(false)
                setAction('')
                setGettingStartedModalOpen(true)
        }
    }

    const { refetch: kycRefetch, isFetching: kycFetching } = useQuery(
        'kyc',
        () => getKYCInfo(),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                const formattedInput = formatConsumerKYC(data, inputs)
                setKycData(data)
                setInputs(prev => ({
                    ...prev,
                    ...formattedInput,
                }))
            },
            onError: error => {
                setShowToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleChangePackage = () => {
        if (isDEPConsumer) {
            setAction(
                estimation?.consumer_status === 'DECLINED_AND_RE_ESTIMATE'
                    ? 'package-change-request-in-progress'
                    : 'confirm-package-change-request',
            )

            setActionModalOpen(true)
        } else {
            const allSolutions =
                kyc_data?.data?.data?.onboarding_type === 'ALL_SOLUTIONS'
            const profile =
                kyc_data?.data?.data?.onboarding_type === 'ENERGY_PROFILE'
            const profile_id =
                kyc_data?.data?.data?.consumer_form_questions?.energy_profile_id
            if (allSolutions) {
                history.push({
                    pathname: '/consumer/all-systems',
                    state: {
                        isAuthenticatedUser: true,
                        estimation_id: estimation?.id,
                        auth_user_id: kycData?.consumer?.user?.id,
                    },
                })
            } else if (profile) {
                history.push({
                    pathname: `/consumer/profile-details/${profile_id}`,
                    state: {
                        isAuthenticatedUser: true,
                        estimation_id: estimation?.id,
                        auth_user_id: kycData?.consumer?.user?.id,
                    },
                })
            } else {
                history.push({
                    pathname: isPromo
                        ? '/consumer/promos'
                        : '/consumer/systems',
                    state: {
                        isAuthenticatedUser: true,
                        estimation_id: estimation?.id,
                        auth_user_id: kycData?.consumer?.user?.id,
                    },
                })
            }
        }
    }

    const handleToggle = index => {
        const updatedOpenState = isOpen.map((open, i) =>
            i === index ? !open : open,
        )
        setIsOpen(updatedOpenState)
    }

    const handleSaveProgress = () => {
        setAction('save-progress')
        setActionModalOpen(true)
    }

    const handleUseTypeChange = () => {
        setAction('change-use-type')
        setActionModalOpen(true)
    }

    useEffect(() => {
        fetchEstimation()
        kycRefetch()
        // eslint-disable-next-line
    }, [useType])

    return {
        sections,
        handleToggle,
        isOpen,
        estimation,
        isLoading,
        goBackToSystem,
        handleSubmit,
        showSecondaryModal,
        setShowSecondaryModal,
        handleChangePackage,
        action,
        setAction,
        actionModalOpen,
        setActionModalOpen,
        handleSaveProgress,
        handlePrimaryBtnClick,
        handleShowVideo,
        handleEditInfo,
        showEditInputDrawer,
        setShowEditInputDrawer,
        handleUseTypeChange,
        gettingStartedModalOpen,
        setGettingStartedModalOpen,
        fetchEstimation,
        inputs,
        setInputs,
        handleInputChange,
        errorMessage,
        showToastError,
        useType,
        contact_type,
        contact_details,
        kycFetching,
        errors,
        kycData,
        showSimulateCreditDecision,
        setShowSimulateCreditDecision,
    }
}

export default useConsumerKYC
