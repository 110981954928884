import axios from '../../../config/axios'

export const getAppliancesApi = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
    startPowerRating = '',
    endPowerRating = '',
    measurement = '',
) => {
    const request = await axios.get(
        `/admin/appliances?page=${page}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&end_power_rating=${endPowerRating}&start_power_rating=${startPowerRating}&measurement=${measurement}`,
    )
    return request
}

export const updateAppliancesApi = async (id, inputs) => {
    const request = await axios.patch(`/admin/appliances/${id}`, inputs)
    return request
}

export const getAllComponentTypesApi = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
) => {
    const request = await axios.get(
        `/admin/component-types?name=${searchText}&page=${page}&start_date=${startDate}&end_date=${endDate}`,
    )
    return request
}

export const deleteApplianceApi = async id => {
    const request = await axios.delete(`/admin/appliances/${id}`)
    return request
}

export const getProviderGroupsApi = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
    startUsersCount = '',
    endUsersCount = '',
    isActive = true,
) => {
    const request = await axios.get(
        `/admin/provider/roles?name=${searchText}&page=${page}&start_date=${startDate}&end_date=${endDate}&start_users_count=${startUsersCount}&end_users_count=${endUsersCount}&is_active=${isActive}`,
    )
    return request
}

export const getProviderResourceApi = async () => {
    const request = await axios.get(`/admin/global-library-users/statistics`)
    return request
}

export const createApplianceApi = async inputs => {
    const request = await axios.post(`/admin/appliances`, inputs)
    return request
}

export const createBulkApplianceApi = async inputs => {
    const request = await axios.post(`/admin/appliances/bulk-create`, inputs)
    return request
}

export const addNewComponentTypeAttributeApi = async data => {
    const request = await axios.post(`admin/component-types`, data)
    return request
}
