import { InputFields } from 'src/components/InputFields'
import InputFieldWithButton from 'src/components/InputFields/InputFieldWithButton'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { useConsumerKYCContext } from '../../context'

const DirectorInfo = () => {
    const { inputs, handleInputChange } = useConsumerKYCContext()
    return (
        <div className="DirectorInfo">
            <div className="GroupItems">
                <InputFields
                    title="Director's First Name"
                    value={inputs?.director_first_name}
                    handleChange={handleInputChange}
                />
                <InputFields
                    title="Director's Last Name"
                    value={inputs?.director_last_name}
                    handleChange={handleInputChange}
                />
            </div>

            <div className="GroupItems">
                <InputFields
                    title="Director's Email Address"
                    value={inputs?.director_email}
                    handleChange={handleInputChange}
                />
                <SelectCountryCode
                    title="Director's Phone number"
                    inputValue={inputs?.director_phone_number}
                />
            </div>

            <InputFieldWithButton
                title="Director's BVN"
                btnText="Verify this BVN"
                type="number"
                name="cac-number"
                mobileBtnText="Verify"
                value={inputs?.bvn}
            />
        </div>
    )
}

export default DirectorInfo
