import PropTypes from 'prop-types'
import styles from '../providerGroups.module.scss'
import Button, { AppButton } from 'src/components/Button'
import EmptyState from 'src/components/EmptyState'

const UsersTable = ({ data, viewUser, removeUser, type }) => {
    const {
        TableHead,
        TableRow,
        Users,
        BtnWrapper,
        DesktopTable,
        MobileCards,
        Card,
        Top,
        Bottom,
        TitleAndText,
        MobileBtnWrapper,
    } = styles
    return (
        <div>
            {data?.length === 0 ? (
                <EmptyState subTitle="There are no users in this group" />
            ) : (
                <>
                    <div className={DesktopTable}>
                        <div className={`${TableHead} ${Users}`}>
                            <p>Name</p>
                            <p>{type === 'admin' ? 'Email' : 'Provider'}</p>
                            <p>Actions</p>
                        </div>
                        {data.map((each, i) => (
                            <div key={i} className={`${TableRow} ${Users}`}>
                                <h4>{each?.user?.display_name}</h4>
                                <p>
                                    {type === 'admin'
                                        ? each?.user?.email
                                        : each?.user?.provider_name ?? 'N/A'}
                                </p>
                                <div className={BtnWrapper}>
                                    <AppButton
                                        btnBgColor="#E2EEFF"
                                        btnTextColor="#004AAD"
                                        btnWidth="70px"
                                        btnHeight="37px"
                                        fontFamily="SF-Pro-Display-Medium"
                                        fontSize="12px"
                                        handleClick={() =>
                                            viewUser(each?.user?.id)
                                        }
                                    >
                                        View
                                    </AppButton>
                                    <Button
                                        type="MainButtonTwo"
                                        btnBgColor="transparent"
                                        btnTextColor="var(--blue)"
                                        btnWidth="70px"
                                        btnHeight="37px"
                                        fontSize="13px"
                                        handleClick={() =>
                                            removeUser(each?.user?.id)
                                        }
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/*    mobile*/}
                    <div className={MobileCards}>
                        {data.map((each, i) => (
                            <div className={Card} key={i}>
                                <div className={Top}>
                                    <p className={TitleAndText}>
                                        {' '}
                                        Name
                                        <br />
                                        <span>{each?.user?.display_name}</span>
                                    </p>
                                    <div
                                        className={`${BtnWrapper} ${MobileBtnWrapper}`}
                                    >
                                        <AppButton
                                            btnBgColor="#E2EEFF"
                                            btnTextColor="#004AAD"
                                            btnWidth="67px"
                                            btnHeight="37px"
                                            fontFamily="SF-Pro-Display-Medium"
                                            fontSize="12px"
                                            handleClick={() =>
                                                viewUser(each?.user?.id)
                                            }
                                        >
                                            View
                                        </AppButton>
                                        <Button
                                            type="MainButtonTwo"
                                            btnBgColor="transparent"
                                            btnTextColor="var(--blue)"
                                            btnWidth="67px"
                                            btnHeight="37px"
                                            fontSize="13px"
                                            handleClick={() =>
                                                removeUser(each?.user?.id)
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                </div>
                                <div className={Bottom}>
                                    <p className={TitleAndText}>
                                        {type === 'admin'
                                            ? 'Email'
                                            : 'Provider'}
                                        <br />
                                        <span>
                                            {type === 'admin'
                                                ? each?.user?.email
                                                : each?.user?.provider_name ??
                                                  'N/A'}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

UsersTable.propTypes = {
    viewUser: PropTypes.func,
    removeUser: PropTypes.func,
    data: PropTypes.array,
    type: PropTypes.string,
}

export default UsersTable
