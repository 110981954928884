import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'
import AccountSetupNav from '../AccountSetupOverview/components/Nav'
import './consumerkyc.scss'
import SelectedSolutionSection from './components/SelectedSolution'
import SectionContainer from './components/SectionContainer'
import useConsumerKYC from './hooks/useConsumerKYC'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ReactPlayer from 'react-player'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import ActionModal from 'src/components/ActionModalV2'
import { actionModalValues } from './data'
import EmailSent from 'src/components/GettingStarted/components/EmailSent'
import PropTypes from 'prop-types'
import EditApplianceInput from '../SolarSystemsListings/EditApplianceInput'
import SwitchUseType from '../AccountSetupOverview/components/SwitchUseType'
import ConsumerKYCContextProvider from './context'
import PageHeading from './components/PageHeading'

const ConsumerKYC = ({ useType }) => {
    const {
        sections,
        handleToggle,
        isOpen,
        estimation,
        goBackToSystem,
        handleSubmit,
        showSecondaryModal,
        setShowSecondaryModal,
        handleChangePackage,
        action,
        actionModalOpen,
        handleSaveProgress,
        handlePrimaryBtnClick,
        handleShowVideo,
        setAction,
        handleEditInfo,
        showEditInputDrawer,
        setShowEditInputDrawer,
        setActionModalOpen,
        handleUseTypeChange,
        gettingStartedModalOpen,
        setGettingStartedModalOpen,
        contact_details,
        contact_type,
        fetchEstimation,
        kycData,
        showSimulateCreditDecision,
        setShowSimulateCreditDecision,
    } = useConsumerKYC(useType)

    const {
        actionModalActionType,
        actionModalFooterLeftContent,
        actionModalHeaderText,
        actionModalPrimaryBtnText,
        actionModalSecondaryBtnText,
        actionModalSubtitle,
        actionModalTitle,
    } = actionModalValues(action, useType)
    const { isMobile } = useMediaQueries()

    return (
        <ConsumerKYCContextProvider useType={useType}>
            <>
                <div className="ConsumerKYC">
                    <AccountSetupNav
                        handleSubmit={handleSubmit}
                        completed={true}
                        handleSaveProgress={handleSaveProgress}
                        estimationId={estimation?.id}
                        consumerName={
                            kycData.consumer?.user
                                ? `${kycData.consumer.user.first_name || ''} ${
                                      kycData.consumer?.user?.last_name || ''
                                  }`
                                : ''
                        }
                        showSimulateCreditDecision={showSimulateCreditDecision}
                        setShowSimulateCreditDecision={
                            setShowSimulateCreditDecision
                        }
                        page={'one-page-kyc'}
                    />
                    <div className="KYCContainer">
                        <div className="KYCContent">
                            <div className="LeftContainer">
                                {isMobile ? (
                                    <PageHeading
                                        handleShowVideo={handleShowVideo}
                                    />
                                ) : (
                                    <SelectedSolutionSection
                                        estimation={estimation}
                                        goBackToSystem={goBackToSystem}
                                        useType={estimation?.use_type}
                                        handleShowVideo={handleShowVideo}
                                        handlePackageChange={
                                            handleChangePackage
                                        }
                                        handleEditInfo={handleEditInfo}
                                        handleUseTypeChange={
                                            handleUseTypeChange
                                        }
                                        showPageHeading={true}
                                    />
                                )}
                            </div>
                            <div className="SectionsContainer">
                                {sections.map((section, index) => (
                                    <SectionContainer
                                        key={section.title}
                                        title={section.title}
                                        isOpen={isOpen[index]}
                                        onToggle={() => handleToggle(index)}
                                        info={section.info}
                                    >
                                        {section.component}
                                    </SectionContainer>
                                ))}
                                {isMobile && (
                                    <SelectedSolutionSection
                                        estimation={estimation}
                                        goBackToSystem={goBackToSystem}
                                        useType={estimation?.use_type}
                                        handleShowVideo={handleShowVideo}
                                        handlePackageChange={
                                            handleChangePackage
                                        }
                                        handleEditInfo={handleEditInfo}
                                        handleUseTypeChange={
                                            handleUseTypeChange
                                        }
                                        showPageHeading={false}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <AccountSetupFooter />
                </div>

                <SecondaryModal
                    showModal={showSecondaryModal}
                    modalWidth={action === 'show-video' ? '1000px' : '450px'}
                    modalHeight="100%"
                    onCancel={() => {
                        setShowSecondaryModal(false)
                    }}
                    noPadding
                    title={
                        action === 'progress-saved' &&
                        'Getting Started on Sunfi'
                    }
                    v2={action === 'progress-saved' ? true : false}
                    modalClass={action === 'show-video' ? 'videoModal' : ''}
                    content={
                        <>
                            {action === 'show-video' && (
                                <div>
                                    <ReactPlayer
                                        url="https://www.youtube.com/watch?v=ABdhW8N1rPk"
                                        width="100%"
                                        height={isMobile ? '250px' : '643px'}
                                        playing={showSecondaryModal}
                                        controls={true}
                                        loop={true}
                                    />
                                </div>
                            )}

                            {action === 'progress-saved' && <EmailSent />}
                        </>
                    }
                    showFooter={action === 'progress-saved' ? true : false}
                    footerLeftContent="chat"
                    primaryBtnText={
                        action === 'progress-saved' ? 'Great, Thank You' : ''
                    }
                    handlePrimaryBtnClick={() => {
                        if (action === 'progress-saved') {
                            setShowSecondaryModal(false)
                            setAction('')
                        }
                    }}
                />

                <ActionModal
                    actionModalOpen={actionModalOpen}
                    headerText={actionModalHeaderText}
                    actionType={actionModalActionType}
                    footerLeftContent={actionModalFooterLeftContent}
                    primaryBtnText={actionModalPrimaryBtnText}
                    subTitle={actionModalSubtitle}
                    title={actionModalTitle}
                    secondaryBtnText={actionModalSecondaryBtnText}
                    handlePrimaryBtnClick={handlePrimaryBtnClick}
                />

                <EditApplianceInput
                    showEditInputDrawer={showEditInputDrawer}
                    hideDrawer={() => setShowEditInputDrawer(false)}
                    setShowEditModal={setActionModalOpen}
                    action={'update_from_account_setup'}
                />

                <SwitchUseType
                    showModal={gettingStartedModalOpen}
                    setShowModal={setGettingStartedModalOpen}
                    useType={
                        useType === 'residential'
                            ? 'Business use'
                            : 'Residential use'
                    }
                    id={estimation?.id}
                    contact={contact_type}
                    contact_details={contact_details}
                    fetchEstimation={fetchEstimation}
                />
            </>
        </ConsumerKYCContextProvider>
    )
}

ConsumerKYC.propTypes = {
    useType: PropTypes.string,
}

export default ConsumerKYC
