import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import FormTitleBar from 'src/components/FormTitleBar'
import { InputFields, SelectField } from 'src/components/InputFields'
import { unitsOfMeasurementOptions } from '../data'
import Button from 'src/components/Button'
import { InlineLoader } from 'src/components/Loader'
import { isMobile } from 'src/utils/mediaQueries'

import { updateAppliancesApi } from 'src/api/admin/settings'
import { AddAppliancesValidationSchema } from 'src/utils/validationSchema'

const EditAppliance = ({
    editInputs,
    setEditInputs,
    handleCancelEdit,
    handleSaveEdit,
    editErrors,
    data,
    errors,
    setErrors
}) => {
    const handleEditInputs = {
        applianceName: 'name',
        powerRating: 'power_rating',
        unitOfMeasurement: 'unit_of_measurement',
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setEditInputs(preState => ({
            ...preState,
            [name]: value,
        }))
        if (name === 'applianceName') {
            setErrors(prev => {
                return {
                    ...prev,
                    [`appliances[0].name`]: '',
                }
            })
        }
        if (name === 'powerRating') {
            setErrors(prev => {
                return {
                    ...prev,
                    [`appliances[0].power_rating`]: '',
                }
            })
        }
    }

    // this function checks if there's an update to the input value
    const removeunFilledData = obj => {
        let result = {}
        const arr = Object.keys(obj)
        for (let k of arr) {
            if (obj[k] !== data[k]) {
                const key = handleEditInputs[k]
                result[key] = obj[k]
            }
        }
        return result
    }

    const editPayload = removeunFilledData(editInputs)
    const {
        refetch: updateAppliancesRefetch,
        isFetching: updateAppliancesLoading,
    } = useQuery(
        ['update-appliance-api'],
        () => updateAppliancesApi(editInputs.id, editPayload),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                handleSaveEdit()
            },
        },
    )

    const handleSelectChange = (name, value) => {
        setEditInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleUpdate = () => {
        AddAppliancesValidationSchema.validate(
            {
                appliances: [
                    {
                        name: editInputs?.applianceName,
                        power_rating: editInputs?.powerRating,
                        unit_of_measurement: editInputs?.unitOfMeasurement,
                    },
                ],
            },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                updateAppliancesRefetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    return (
        <div>
            <FormTitleBar
                title="Edit Appliance"
                subtitle="Enter the details of your request to continue"
                fontFamily="SF-Pro-Display-Bold"
                titleFontSize={20}
                alignLeft={isMobile}
            />

            <div className="ASLMobileWidth">
                <InputFields
                    title="Appliance Name"
                    name="applianceName"
                    inputValue={editInputs?.applianceName}
                    value={editInputs?.applianceName}
                    inputWidth="100%"
                    handleChange={handleInputChange}
                    errorMessage={
                        editErrors?.applianceName ||
                        errors[`appliances[${0}].name`]
                    }
                />
            </div>

            <div className="ASLEditModalInputWrapper">
                <div className="ASLMobileWidth">
                    <InputFields
                        title="Power Rating"
                        name="powerRating"
                        type="number"
                        inputValue={editInputs?.powerRating}
                        value={editInputs?.powerRating}
                        inputWidth="224px"
                        handleChange={handleInputChange}
                        errorMessage={
                            editErrors?.powerRating ||
                            errors[`appliances[${0}].power_rating`]
                        }
                    />
                </div>

                <div className="ASLMobileWidth">
                    <SelectField
                        floatingLabel="Unit of Measurement"
                        type="approvedModal"
                        name="unitOfMeasurement"
                        selectWidth="215px"
                        withCheckBox={true}
                        initialOption={editInputs?.unitOfMeasurement}
                        value={editInputs?.unitOfMeasurement}
                        values={unitsOfMeasurementOptions}
                        handleChange={handleSelectChange}
                        selectedValue={editInputs?.unitOfMeasurement}
                        currentSelected={editInputs?.unitOfMeasurement}
                        optionStyle={{
                            width: '100%',
                            marginTop: '6px',
                            marginBottom: '6px',
                        }}
                        errorMessage={
                            errors[`appliances[${0}].unit_of_measurement`]
                        }
                    />
                </div>
            </div>

            <div className="ASLEditModalButtonWrapper">
                <Button
                    btnWidth="160px"
                    btnBgColor="#E2EEFF"
                    btnTextColor="var(--blue)"
                    btnHeight="56px"
                    handleClick={() => handleCancelEdit()}
                >
                    Cancel
                </Button>
                <Button
                    btnWidth="160px"
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnHeight="56px"
                    handleClick={handleUpdate}
                >
                    {updateAppliancesLoading ? <InlineLoader /> : 'Save'}
                </Button>
            </div>
        </div>
    )
}

EditAppliance.propTypes = {
    editInputs: PropTypes.any,
    setEditInputs: PropTypes.func,
    handleCancelEdit: PropTypes.func,
    handleSaveEdit: PropTypes.func,
    editErrors: PropTypes.any,
    setErrors: PropTypes.func,
    errors: PropTypes.object,
    data: PropTypes.any,
}
export default EditAppliance
