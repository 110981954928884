import PropTypes from 'prop-types'
import Button, { AppButton } from 'src/components/Button'
import styles from '../componentType.module.scss'
import { titleCase } from 'src/utils/formatting'
import EmptyState from 'src/components/EmptyState'
import { TableSkeletalCards } from 'src/components/SkeletalCards'
import { canUpdateComponentType } from '../data'

const ComponentTable = ({
    handleEditAttribute,
    componentAttributes,
    removeAttribute,
    isFetching,
}) => {
    const {
        TableHead,
        TableRow,
        StatusTag,
        AttrLabel,
        TableWrapper,
        TableTitle,
        Table,
        DesktopTable,
        MobileCards,
        Card,
        Top,
        Bottom,
        CardTitle,
        CardTitleText,
        MobileBtnWrapper,
        WithTag,
    } = styles
    return (
        <div className={TableWrapper}>
            {isFetching ? (
                <TableSkeletalCards total={2} mobileType="cards" />
            ) : (
                <>
                    <h2 className={TableTitle}>Component Type Attributes</h2>
                    {componentAttributes.length === 0 ? (
                        <EmptyState
                            subTitle={'This component type has no attributes'}
                        />
                    ) : (
                        <>
                            <div className={DesktopTable}>
                                <div className={TableHead}>
                                    <p>Name</p>
                                    <p>Type</p>
                                </div>
                                <div className={Table}>
                                    {componentAttributes.map((each, i) => (
                                        <div key={i} className={TableRow}>
                                            <>
                                                <div
                                                    className={
                                                        each.required &&
                                                        AttrLabel
                                                    }
                                                >
                                                    <h4>{each?.name}</h4>
                                                    {each.required && (
                                                        <span
                                                            className={
                                                                StatusTag
                                                            }
                                                        >
                                                            REQUIRED
                                                        </span>
                                                    )}
                                                </div>
                                                <p>
                                                    {titleCase(
                                                        each?.field_type,
                                                    )}
                                                </p>
                                                <AppButton
                                                    btnBgColor="#E2EEFF"
                                                    btnTextColor="#004AAD"
                                                    btnWidth="70px"
                                                    btnHeight="37px"
                                                    fontFamily="SF-Pro-Display-Medium"
                                                    fontSize="12px"
                                                    handleClick={() =>
                                                        handleEditAttribute(
                                                            each,
                                                        )
                                                    }
                                                    disabled={
                                                        !canUpdateComponentType
                                                    }
                                                    toolTipText={
                                                        !canUpdateComponentType
                                                            ? 'You are not authorised to perform this action'
                                                            : null
                                                    }
                                                    toolTipPopupWidth={167}
                                                    arrowPositionCenter
                                                >
                                                    Edit
                                                </AppButton>
                                                <Button
                                                    type="MainButtonTwo"
                                                    btnBgColor="transparent"
                                                    btnTextColor="#004AAD"
                                                    btnWidth="70px"
                                                    btnHeight="37px"
                                                    fontSize="12px"
                                                    handleClick={() =>
                                                        removeAttribute(
                                                            each?.id,
                                                        )
                                                    }
                                                    disabled={
                                                        !canUpdateComponentType
                                                    }
                                                    toolTipText={
                                                        !canUpdateComponentType
                                                            ? 'You are not authorised to perform this action'
                                                            : null
                                                    }
                                                    toolTipPopupWidth={167}
                                                    arrowPositionCenter
                                                >
                                                    Remove
                                                </Button>
                                            </>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={MobileCards}>
                                {componentAttributes.map((each, i) => (
                                    <div key={i} className={Card}>
                                        <div className={Top}>
                                            <div>
                                                <p className={CardTitle}>
                                                    Name
                                                </p>
                                                <div
                                                    className={
                                                        each.required &&
                                                        AttrLabel
                                                    }
                                                >
                                                    <h4
                                                        className={`
                                                            ${CardTitleText} ${
                                                            each?.required
                                                                ? WithTag
                                                                : ''
                                                        }`}
                                                    >
                                                        {each?.name}
                                                    </h4>
                                                    {each.required && (
                                                        <span
                                                            className={
                                                                StatusTag
                                                            }
                                                        >
                                                            REQUIRED
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={MobileBtnWrapper}>
                                                <AppButton
                                                    btnBgColor="#E2EEFF"
                                                    btnTextColor="#004AAD"
                                                    btnWidth="67px"
                                                    btnHeight="37px"
                                                    fontFamily="SF-Pro-Display-Medium"
                                                    fontSize="12px"
                                                    handleClick={() =>
                                                        handleEditAttribute(
                                                            each,
                                                        )
                                                    }
                                                    disabled={
                                                        !canUpdateComponentType
                                                    }
                                                    toolTipText={
                                                        !canUpdateComponentType
                                                            ? 'You are not authorised to perform this action'
                                                            : null
                                                    }
                                                    toolTipPopupWidth={167}
                                                    arrowPositionCenter
                                                >
                                                    Edit
                                                </AppButton>
                                                <Button
                                                    type="MainButtonTwo"
                                                    btnBgColor="transparent"
                                                    btnTextColor="#004AAD"
                                                    btnWidth="67px"
                                                    btnHeight="37px"
                                                    fontSize="12px"
                                                    handleClick={() =>
                                                        removeAttribute(
                                                            each?.id,
                                                        )
                                                    }
                                                    disabled={
                                                        !canUpdateComponentType
                                                    }
                                                    toolTipText={
                                                        !canUpdateComponentType
                                                            ? 'You are not authorised to perform this action'
                                                            : null
                                                    }
                                                    toolTipPopupWidth={167}
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={Bottom}>
                                            <div>
                                                <p className={CardTitle}>
                                                    Type
                                                </p>
                                                <p className={CardTitleText}>
                                                    {titleCase(
                                                        each?.field_type,
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    )
}

ComponentTable.propTypes = {
    handleEditAttribute: PropTypes.func,
    removeAttribute: PropTypes.func,
    componentAttributes: PropTypes.array,
    isFetching: PropTypes.bool,
}

export default ComponentTable
