import '../consumerkyc.scss'
import { ReactComponent as Edit } from 'src/assets/images/edit-icon-no-stroke.svg'
import PropTypes from 'prop-types'
import NairaSymbol from 'src/components/NairaSymbol'

const EnergyInfo = ({ energyInfo, paymentPlan, handleEditInfo }) => {
    return (
        <div className="EnergyInfo">
            <div className="EnergyInfoDetails">
                {energyInfo?.map(info => (
                    <div key={info.title}>
                        <h6>{info.title}</h6>
                        <p>
                            {info?.isAmount ? <NairaSymbol /> : ''}{' '}
                            {info?.value}
                        </p>
                    </div>
                ))}
            </div>

            <div className="GroupItems">
                <div>
                    <h6>Payment plan type</h6>
                    <p>{paymentPlan}</p>
                </div>
                <div
                    className="EditInfo"
                    role={'button'}
                    onClick={handleEditInfo}
                >
                    <Edit role={'presentation'} />
                    <p>Edit your info</p>
                </div>
            </div>
        </div>
    )
}

EnergyInfo.propTypes = {
    energyInfo: PropTypes.object,
    paymentPlan: PropTypes.string,
    handleEditInfo: PropTypes.func,
}

export default EnergyInfo
