import UpdateGroupPermissions from 'src/components/UpdateGroupPermissions'

const UpdateProviderGroupPermissions = () => {
    return (
        <UpdateGroupPermissions
            type={'provider'}
            module={'existing-provider-group'}
        />
    )
}
export default UpdateProviderGroupPermissions
