//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { encodeUserInfo, setToken } from '../../utils/auth'
import { useQuery } from 'react-query'
import { validateKYCAttempt } from 'src/api/shoppingExperience/get-system'
import Confirmation from '../../components/Confirmation'
import successImage from '../../assets/images/successImage.svg'
import SeoComponent from 'src/components/Seo'

const VerifyKYCConsumer = props => {
    const parsed = queryString.parse(props.location.search)

    const formattedParsed = {
        ...parsed,
        email: parsed?.email?.split(' ').join('+'),
    }

    const payment_model = 'Lease to Own'
    const onboardingType = 'profiles-or-all-system'
    const hideGoBackBtn = true

    const [verified, setVerified] = useState(false)
    const [width, setwidth] = useState(50)
    const history = useHistory()

    useEffect(() => {
        const handleSlider = () => {
            if (verified && width < 100) {
                setwidth(prev => prev + 50)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 2000)
        return () => clearTimeout(sliderWidth)
    }, [width, verified])

    const { refetch, error, data } = useQuery(
        'kyc verification',
        () => validateKYCAttempt(formattedParsed),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setVerified(true)
                setToken(data?.data?.data?.token?.access)
                encodeUserInfo(data)
                history.push({
                    pathname: '/consumer/account-setup/overview',
                    state: {
                        payment_model,
                        onboardingType,
                        hideGoBackBtn,
                    },
                })
            },
        },
    )

    useEffect(() => {
        if (formattedParsed?.email) {
            refetch()
        } else {
            history.push({
                pathname: '/',
            })
        }
    }, [refetch, formattedParsed])

    return (
        <>
            <SeoComponent
                title="SunFi - Verify Consumer KYC"
                tracker="VerifyKYCConsumerTracker"
            />

            <Confirmation
                imgUrl={successImage}
                header={`${'Verification In Progress'}`}
                subHeader={`${'We’ve solved the problems that make it difficult for energy providers to transition'}`}
                subHeaderStyle="48px"
                boxPadding="45px"
                scrollIndicator
                scrollWidth={width}
            />
        </>
    )
}

export default VerifyKYCConsumer
