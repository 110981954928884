import { isMobile } from 'src/utils/mediaQueries'
import '../../consumerkyc.scss'
import ImageIcon from 'src/assets/images/image-icon.svg'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import { useState } from 'react'
import IdentityModal from '../../../CreateAccount/IdentityInformation/components/IdentityModal'
import { useUploadImage } from '../../../CreateAccount/IdentityInformation/hook'
import { useConsumerKYCContext } from '../../context'
import { ReactComponent as WhiteImageIcon } from 'src/assets/images/image-icon-white.svg'

const IdentityInformationSection = () => {
    const { inputs } = useConsumerKYCContext()
    const [showModal, setShowModal] = useState(false)
    const closeModal = () => {
        setShowModal(false)
    }
    const onSelfieUploadSuccess = () => {
        closeModal()
    }
    const {
        step,
        setStep,
        selected,
        setSelected,
        uploading,
        successUpload,
        setSuccessUpload,
        pictureUrl,
        setPictureUrl,
        width,
        setWidth,
        modalFooterLeftContent,
        modalPrimaryBtnText,
        modalTitle,
        disableModalPrimaryBtn,
        handleImageSteps,
        webcamRef,
        handleRetake,
        selfieLoading,
        IdentitySelfieRef,
    } = useUploadImage(closeModal, onSelfieUploadSuccess)

    return (
        <>
            <ConsumerModalBase
                showModal={showModal}
                onCancel={() => closeModal()}
                title={modalTitle}
                footerLeftContent={
                    step === 3 && selfieLoading
                        ? 'none'
                        : modalFooterLeftContent
                }
                primaryBtnText={modalPrimaryBtnText}
                primaryBtnDisabled={disableModalPrimaryBtn}
                handlePrimaryBtnClick={handleImageSteps}
                primaryBtnLoading={step === 3 && selfieLoading}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                secondaryBtnText="Retake"
                handleSecondaryBtnClick={() => {
                    step === 3 && handleRetake()
                }}
            >
                <IdentityModal
                    step={step}
                    setStep={setStep}
                    selected={selected}
                    setSelected={setSelected}
                    webcamRef={webcamRef}
                    IdentitySelfieRef={IdentitySelfieRef}
                    successUpload={successUpload}
                    setSuccessUpload={setSuccessUpload}
                    pictureUrl={pictureUrl}
                    setPictureUrl={setPictureUrl}
                    uploading={uploading}
                    width={width}
                    setWidth={setWidth}
                />
            </ConsumerModalBase>

            {inputs?.selfieImageUrl === '' ? (
                <div className="AddImage">
                    <button onClick={() => setShowModal(true)}>
                        <span>
                            <img src={ImageIcon} />
                        </span>

                        <p>Add your image</p>
                    </button>
                </div>
            ) : (
                <div className="imageContainer">
                    <img src={inputs?.selfieImageUrl} className="image" />

                    <div className="imageIcon">
                        <WhiteImageIcon />
                    </div>
                </div>
            )}
        </>
    )
}

export default IdentityInformationSection
