import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import FormTitleBar from 'src/components/FormTitleBar'
import { ReactComponent as Close } from 'src/assets/images/close-icon.svg'
import styles from '../providerGroups.module.scss'
import Button from 'src/components/Button'
import EmptyState from 'src/components/EmptyState'
import { TableSkeletalCards } from 'src/components/SkeletalCards'
import { useQuery } from 'react-query'
import { updateProviderGroupApi } from 'src/api/admin/settings/providergroup'
import { errorHandler } from 'src/utils/errorHandler'
import { InlineLoader } from 'src/components/Loader'
import Toast from 'src/components/Toast'
import { isMobile } from 'src/utils/mediaQueries'
import { updateProvidersGroupApi } from 'src/api/providers/users/groups'

const ViewPermissionsModal = ({
    showModal,
    onCancel,
    handleSave,
    permissions,
    isFetching,
    id,
    userType,
}) => {
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const [removePermissionsIds, setRemovePermissionIds] = useState([])
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { ModalBtn, PermissionsList } = styles

    useEffect(() => {
        setSelectedPermissions(permissions)
        setRemovePermissionIds([])
    }, [permissions, showModal])

    const {
        refetch: updatePermissionsRefetch,
        isFetching: updatePermissionsLoading,
    } = useQuery(
        'update-provider-group-permissions',
        () =>
            userType === 'provider-user'
                ? updateProvidersGroupApi(id, {
                      remove_permission_ids: removePermissionsIds,
                      workspace: 'PROVIDER',
                  })
                : updateProviderGroupApi(id, {
                      remove_permission_ids: removePermissionsIds,
                      workspace: 'PROVIDER',
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setRemovePermissionIds([])
                handleSave()
            },
            onError: error => {
                setError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //remove single item from permissions list
    const removeSinglePermission = each => {
        const filteredPermissions = selectedPermissions.filter(
            item => item?.id !== each?.id,
        )
        setSelectedPermissions(filteredPermissions)
        setRemovePermissionIds(prev => [...prev, each?.id])
    }

    //persist ids to remove
    const handleRemoval = () => {
        updatePermissionsRefetch()
    }

    return (
        <>
            <SecondaryModal
                showModal={showModal}
                onCancel={onCancel}
                modalWidth="624px"
                modalHeight="614px"
                content={
                    <>
                        <FormTitleBar
                            title="View Permissions"
                            subtitle="View and edit the permissions in this group"
                            marginTop="48px"
                            marginLeft={'23px'}
                            alignLeft={isMobile}
                            titleFontSize={'20px'}
                        />
                        {error && (
                            <Toast messageType="error" message={errorMessage} />
                        )}
                        <div>
                            {isFetching ? (
                                <div className={` ${PermissionsList}`}>
                                    <TableSkeletalCards total={0} />
                                </div>
                            ) : (
                                <>
                                    {selectedPermissions?.length === 0 ? (
                                        <EmptyState subTitle="You've removed all permissions for this group" />
                                    ) : (
                                        <>
                                            <div
                                                className={` ${PermissionsList}`}
                                            >
                                                {selectedPermissions?.map(
                                                    (each, i) => {
                                                        return (
                                                            <div
                                                                className="AGAPSelectedItem"
                                                                key={i}
                                                            >
                                                                <p>
                                                                    <span>
                                                                        {
                                                                            each
                                                                                ?.tree?.[0]
                                                                        }{' '}
                                                                    </span>{' '}
                                                                    {each
                                                                        ?.tree?.[1] && (
                                                                        <span>
                                                                            {' '}
                                                                            &gt;&gt;{' '}
                                                                            {
                                                                                each
                                                                                    ?.tree?.[1]
                                                                            }
                                                                        </span>
                                                                    )}{' '}
                                                                    {each
                                                                        ?.tree?.[2] && (
                                                                        <span>
                                                                            &gt;&gt;{' '}
                                                                            {
                                                                                each
                                                                                    ?.tree?.[2]
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </p>
                                                                <div
                                                                    onClick={() =>
                                                                        removeSinglePermission(
                                                                            each,
                                                                        )
                                                                    }
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    <Close />
                                                                </div>
                                                            </div>
                                                        )
                                                    },
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className={ModalBtn}>
                                        <Button
                                            btnBgColor="#004AAD"
                                            btnTextColor="#FFFFFF"
                                            btnWidth="160px"
                                            btnHeight="56px"
                                            fontSize="14px"
                                            handleClick={() => handleRemoval()}
                                            disabled={
                                                removePermissionsIds?.length ===
                                                0
                                            }
                                        >
                                            {updatePermissionsLoading ? (
                                                <InlineLoader />
                                            ) : (
                                                'Save Changes'
                                            )}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                }
            />
        </>
    )
}

ViewPermissionsModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    handleSave: PropTypes.func,
    permissions: PropTypes.array,
    isFetching: PropTypes.bool,
    id: PropTypes.string,
    userType: PropTypes.string.isRequired,
}
export default ViewPermissionsModal
