import { useState, useEffect } from 'react'
import 'src/pages/Admin/Users/AddGroupAndPermissions/admingroupsandpermissions.scss'
import BackNav from 'src/components/BackNav'
import { ReactComponent as InfoIcon } from 'src/assets/images/info.svg'
import { ReactComponent as EnergyIllustration } from 'src/assets/images/package.svg'
import AddAdminGroupPermissions from 'src/pages/Admin/Users/AddGroupAndPermissions/addPermissions'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
    getAddedPermissionsUnderRoleApi,
    getSingleProviderGroupApi,
} from 'src/api/admin/settings/providergroup'
import { Skeleton } from 'antd'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import PropTypes from 'prop-types'
import {
    fetchSingleProviderGroupApi,
    getAddedPermissionsUnderProviderRoleApi,
} from 'src/api/providers/users/groups'

const UpdateGroupPermissions = ({ type, module }) => {
    const [groupData, setGroupData] = useState({})
    //permissions persisted on BE
    const [persistedPermissions, setPersistedPermissions] = useState([])
    //selected by user but not yet added to API call
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([])
    //error state
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const params = useParams()
    const isProvider = type === 'provider-user'

    const history = useHistory()

    const { refetch: groupRefetch, isFetching: groupFetching } = useQuery(
        'get-single-provider-group',
        () =>
            isProvider
                ? fetchSingleProviderGroupApi(params?.id)
                : getSingleProviderGroupApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setGroupData(data?.data?.data)
            },
            onError: error => {
                setError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const {
        refetch: allAssignedPermissionsRefetch,
        isFetching: allAssignedPermissionsFetching,
    } = useQuery(
        'all-assigned-permissions',
        () =>
            isProvider
                ? getAddedPermissionsUnderProviderRoleApi(params?.id, false)
                : getAddedPermissionsUnderRoleApi(params?.id, false),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const fetched = data?.data?.data
                let permissions = []
                let PermissionIds = []
                for (let i = 0; i < fetched.length; i++) {
                    permissions.push({
                        id: fetched[i].permission?.id,
                        tree: [
                            fetched[i]?.resource?.parent_name,
                            fetched[i]?.resource?.name,
                            fetched[i]?.permission?.name,
                        ],
                    })
                    PermissionIds.push(fetched[i].permission?.id)
                }
                setPersistedPermissions(permissions)
                setSelectedPermissions(permissions)
                setSelectedPermissionIds(PermissionIds)
            },
            onError: error => {
                setError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        groupRefetch()
        allAssignedPermissionsRefetch()
    }, [allAssignedPermissionsRefetch, groupRefetch])

    const isFetching = groupFetching || allAssignedPermissionsFetching

    return (
        <div className="AGAPWrapper">
            {error && <Toast messageType="error" message={errorMessage} />}
            <div className="AGAPNavWrapper">
                <BackNav
                    title={
                        type === 'admin'
                            ? 'Back to Admin'
                            : type === 'provider' || type === 'provider-user'
                            ? 'Back to Groups'
                            : ''
                    }
                    onClick={() => history.goBack()}
                />
            </div>

            <div className="AGAPContentWrapper">
                {/*left section*/}
                <div className="AGAPLeftSection">
                    <h4>Update Group</h4>
                    <h2>
                        Add permissions to{' '}
                        {isFetching ? '...' : groupData?.role?.name ?? 'N/A'}
                    </h2>
                    <p className="AGAPCountMobile">
                        <span>No of permisions added</span>
                        <span>{selectedPermissions?.length}</span>
                    </p>

                    <AddAdminGroupPermissions
                        module={module}
                        id={params?.id}
                        persistedPermissions={persistedPermissions}
                        setPersistedPermissions={setPersistedPermissions}
                        selectedPermissions={selectedPermissions}
                        setSelectedPermissions={setSelectedPermissions}
                        selectedPermissionIds={selectedPermissionIds}
                        setSelectedPermissionIds={setSelectedPermissionIds}
                        groupFetching={isFetching}
                        setError={setError}
                        setErrorMessage={setErrorMessage}
                        type={type}
                    />
                </div>

                {/*right section*/}
                <div className="AGAPRightSection">
                    <div className="AGAPInfoWrapper">
                        <InfoIcon
                            style={{
                                transform: 'scale(2)',
                                marginLeft: 20.67,
                                marginTop: 34.67,
                            }}
                        />
                        <p>
                            We’ve solved the problems that make it difficult for
                            energy providers to transition millions
                        </p>
                        <EnergyIllustration />
                    </div>

                    {/*counting the number of permissions added*/}

                    <div className="AGAPCount">
                        <p>No. of permissions added</p>
                        {isFetching ? (
                            <Skeleton.Input
                                style={{ width: '20px' }}
                                active={true}
                                size="small"
                            />
                        ) : (
                            <p>{selectedPermissions?.length}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

UpdateGroupPermissions.propTypes = {
    type: PropTypes.string,
    module: PropTypes.string,
}

export default UpdateGroupPermissions
