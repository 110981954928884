/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Pagination, Drawer, Slider, Dropdown, Menu } from 'antd'
import { format, parseISO } from 'date-fns'
import { AppButton } from 'src/components/Button'
import ButtonComponent from 'src/components/Button'
import Button from 'src/components/Button'
import RadioButton from 'src/components/RadioButton'
import {
    NoFLoatingLabelInputFields,
    DateField,
    InputFields,
} from 'src/components/InputFields'
import './settings-listings.scss'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import EditAppliance from './EditAppliance'
import ActionModal from 'src/components/ActionModal'
import EmptyState from 'src/components/EmptyState'
import AddComponentType from './AddComponentType'
import AddAppliance from './AddAppliance'
import { InlineLoader } from 'src/components/Loader'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import BackNav from 'src/components/BackNav'
import Toast from 'src/components/Toast'
import {
    getAppliancesApi,
    getAllComponentTypesApi,
    deleteApplianceApi,
    getProviderGroupsApi,
    getProviderResourceApi,
} from 'src/api/admin/settings'
import { errorHandler } from 'src/utils/errorHandler'
import { isMobile } from 'src/utils/mediaQueries'
import { ReactComponent as ArrowDown } from 'src/assets/images/arrowDownWhite.svg'
import {
    filterDrawerValues,
    viewLibraryUserPermissions,
    canViewComponentTypeList,
    canViewComponentTypeDetails,
    canCreateComponentType,
    canViewApplianceList,
    canCreateAppliance,
    canUpdateAppliance,
    canDeleteAppliance,
    checkPemmisionsForDefaultQueries,
} from './data'

import 'src/pages/Admin/Providers/Listings/providers-listing.scss'
import queryString from 'query-string'
import { formatInputValue } from 'src/utils/formatting'
import {
    updateAdminSettingsFilters,
    updatedAdminSettingsFilters,
} from 'src/redux/reducers/admin/settings/listing'
import { convertQueryParamsToObject, getKeyByValue } from 'src/utils/formatting'
import { ReactComponent as PlusIcon } from 'src/assets/images/plus-icon.svg'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
    removeAllQueryParamsFromUrl,
    removeQueryParamFromUrl,
} from 'src/utils/urlTracker'

const AdminSettings = props => {
    const settingsTabs = {
        1: 'appliances',
        2: 'provider-groups',
        3: 'provider-resources',
        4: 'component-types',
    }
    const defaultQueries = checkPemmisionsForDefaultQueries(
        useSelector(updatedAdminSettingsFilters),
    )
    const filterQuery = getSearchParamsFromUrl()
    const dispatch = useDispatch()
    const { TabPane } = Tabs
    const maxFilterAmount = 1000
    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(settingsTabs, filterQuery.tab) ||
            defaultQueries.current_tab,
    )
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(
        Number(filterQuery.page) || Number(defaultQueries.page),
    )
    const [showEdit, setShowEdit] = useState(false)
    const [showAddComponentType, setShowAddComponentType] = useState(false)
    const [showAddAppliance, setShowAddAppliance] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [action, setAction] = useState('')
    const [appliances, setAppliances] = useState([])
    const [filterMode, setFilterMode] = useState(
        defaultQueries.filter_mode === 'true' ? true : false,
    )
    const [searchText, setSearchText] = useState(
        filterQuery.search || defaultQueries.name || '',
    )
    const [totalPages, setTotalPages] = useState(1)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [componentTypes, setComponentTypes] = useState([])
    const [providerGroups, setProviderGroups] = useState([])
    const [providerResource, setProviderResource] = useState([])
    const [range, setRange] = useState([
        Number(
            currentTab === '2'
                ? defaultQueries.start_users_count
                : defaultQueries.start_power_rating,
        ) ?? '',
        Number(
            currentTab === '2'
                ? defaultQueries.end_users_count
                : defaultQueries.end_power_rating,
        ) ?? '',
    ])
    const [applianceId, setApplianceId] = useState('')
    const [measurement, setMeasurement] = useState(
        defaultQueries.measurement ?? '',
    )
    const [clearGlobalAccess, setClearGlobalAccess] = useState(false)
    const [count, setCount] = useState(0)
    const [status, setStatus] = useState(defaultQueries.state ?? '')
    const [inputs, setInputs] = useState({
        startDate: filterQuery.startDate || defaultQueries.start_date || null,
        endDate: filterQuery.endDate || defaultQueries.end_date || null,
    })
    const [data, setData] = useState({})
    const [editInputs, setEditInputs] = useState({
        id: null,
        applianceName: null,
        powerRating: null,
        unitOfMeasurement: null,
    })
    const [editErrors, setEditErrors] = useState('')
    const [showCalendar, setShowCalendar] = useState(0)
    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        inputs.endDate === null ? null : new Date(inputs.endDate),
    )
    const [currentAppliances, setCurrentAppliances] = useState([])
    const [applianceToDelete, setApplianceToDelete] = useState()
    const [errors, setErrors] = useState({})
    const [filterErrors, setFilterErrors] = useState({})
    let reset
    let resetPage = null

    const history = useHistory()
    const { filterDrawerTitle, minInputTitle, maxInputTitle } =
        filterDrawerValues(currentTab)

    const showDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'endDate') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }
    const setHandleDateChange = name => {
        if (name === 'startDate') {
            setInputs(preState => ({
                ...preState,
                [name]:
                    currentStartDate === null ? new Date() : currentStartDate,
            }))
            if (currentStartDate === null) {
                setCurrentStartDate(new Date())
            }
        }
        if (name === 'endDate') {
            setInputs(preState => ({
                ...preState,
                [name]: currentEndDate === null ? new Date() : currentEndDate,
            }))

            if (currentEndDate === null) {
                setCurrentEndDate(new Date())
            }
        }
    }

    const handleRangeChange = e => {
        const { name, value } = e.target
        setFilterErrors(prev => {
            const newErrors = { ...filterErrors }
            delete newErrors['minValue']
            delete newErrors['maxValue']
            return newErrors
        })

        if (name === 'minValue') {
            setRange(prev => [formatInputValue(name, value), prev[1]])
        }
        if (name === 'maxValue') {
            setRange(prev => [prev[0], formatInputValue(name, value)])
        }
    }

    const handleEditAppliance = item => {
        setEditInputs({
            id: item?.id,
            applianceName: item?.name,
            powerRating: item?.power_rating,
            unitOfMeasurement: item?.unit_of_measurement,
        })
        setData({
            id: item?.id,
            applianceName: item?.name,
            powerRating: item?.power_rating,
            unitOfMeasurement: item?.unit_of_measurement,
        })
        setShowEdit(true)
        setAction('edit-appliance')
    }

    const handleCancelEdit = () => {
        setShowEdit(false)
        setEditErrors('')
        setErrors({})
    }

    const handleSaveEdit = () => {
        if (editInputs.applianceName === '' || editInputs.powerRating === '') {
            if (editInputs.applianceName === '') {
                setEditErrors(prev => ({
                    ...prev,
                    applianceName: 'This field is required',
                }))
            }

            if (editInputs.powerRating === '') {
                setEditErrors(prev => ({
                    ...prev,
                    powerRating: 'This field is required',
                }))
            }
        } else {
            const newArr = currentAppliances.map(item => {
                if (item?.id === editInputs?.id) {
                    return {
                        ...item,
                        name: editInputs?.applianceName,
                        power_rating: editInputs?.powerRating,
                        unit_of_measurement: editInputs?.unitOfMeasurement,
                    }
                }
                return item
            })
            setCurrentAppliances(newArr)
            setShowEdit(false)
            setShowSuccess(true)
            setEditErrors('')
        }
    }

    //fetch appliances
    const { refetch: getAppliancesRefetch, isFetching: appliancesFetching } =
        useQuery(
            ['get-settings'],
            () =>
                getAppliancesApi(
                    searchText.length > 0 ? 1 : currentPage,
                    reset ? '' : searchText,
                    reset
                        ? ''
                        : currentStartDate
                        ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                        : '',
                    reset
                        ? ''
                        : currentEndDate
                        ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                        : '',
                    reset ? 0 : range[0],
                    reset ? '' : range[1] > 0 ? range[1] : '',
                    reset ? '' : measurement,
                ),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    const queries = data?.config?.url
                        .split('?')[1]
                        ?.replaceAll('=', ':')
                        ?.split('&')
                    dispatch(
                        updateAdminSettingsFilters(
                            convertQueryParamsToObject([
                                ...queries,
                                `current_tab:${currentTab}`,
                                `filter_mode:${filterMode}`,
                            ]),
                        ),
                    )
                    setAppliances(data?.data?.data)
                    setTotalPages(data?.data?.total_pages)
                    setCount(data?.data?.count)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    //delete appliance
    const {
        refetch: deleteApplianceRefetch,
        isFetching: deleteApplianceFetching,
    } = useQuery(['delete-appliance'], () => deleteApplianceApi(applianceId), {
        enabled: false,
        retry: false,
        onSuccess: () => {
            setShowSuccess(true)
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    //fetch all component types
    const {
        refetch: getComponentTypesRefetch,
        isFetching: componentTypesFetching,
    } = useQuery(
        ['get-componentTypes-api'],
        () =>
            getAllComponentTypesApi(
                resetPage === 1 ? resetPage : currentPage,
                reset ? '' : searchText,
                reset
                    ? ''
                    : currentStartDate
                    ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                    : '',
                reset
                    ? ''
                    : currentEndDate
                    ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                    : '',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateAdminSettingsFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                            `filter_mode:${filterMode}`,
                        ]),
                    ),
                )
                setComponentTypes(data?.data?.data)
                setTotalPages(data?.data?.total_pages)
                setCount(data?.data?.count)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //fetch provider groups
    const {
        refetch: getProviderGroupsRefetch,
        isFetching: providerGroupsFetching,
    } = useQuery(
        ['get-provider-groups'],
        () =>
            getProviderGroupsApi(
                resetPage === 1 ? resetPage : currentPage,
                reset ? '' : searchText,
                reset
                    ? ''
                    : currentStartDate
                    ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                    : '',
                reset
                    ? ''
                    : currentEndDate
                    ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                    : '',
                reset ? 0 : range[0],
                reset ? '' : range[1] > 0 ? range[1] : '',
                status && status.toLowerCase() === 'active',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateAdminSettingsFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                            `filter_mode:${filterMode}`,
                        ]),
                    ),
                )
                setProviderGroups(data?.data?.data)
                setTotalPages(data?.data?.total_pages)
                setCount(data?.data?.count)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    // fetch provider resource
    const {
        refetch: getProviderResourceRefetch,
        isFetching: providerResourceFetching,
    } = useQuery(['get-provider-resource'], () => getProviderResourceApi(), {
        enabled: false,
        retry: false,
        onSuccess: data => {
            dispatch(
                updateAdminSettingsFilters(
                    convertQueryParamsToObject([`current_tab:${currentTab}`]),
                ),
            )
            setProviderResource(data?.data?.data)
            setCount(data?.data?.count)
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const allLoadingStates =
        appliancesFetching ||
        componentTypesFetching ||
        providerGroupsFetching ||
        providerResourceFetching

    const handleDeleteAppliance = item => {
        setApplianceToDelete(item)
        setAction('delete-appliance')
        setShowDelete(true)
        setApplianceId(item)
    }
    const handleDeleteAction = () => {
        setCurrentAppliances(
            currentAppliances.filter(arr => arr !== applianceToDelete),
        )
        setShowDelete(false)
        deleteApplianceRefetch()
    }

    const updatePage = page => {
        setCurrentPage(page)
        setTotalPages(0)
        addQueryParamsToUrl({
            page: page,
        })
    }

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={currentPage}
                current={currentPage}
                total={parseInt(totalPages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={updatePage}
            />
        )
    }

    const handleSearch = () => {
        setCurrentPage(1)
        setTotalPages(0)
        setAppliances([])
        setComponentTypes([])
        setFilterMode(searchText.length > 0 ? true : false)
        switch (currentTab) {
            case '1':
                getAppliancesRefetch()
                break
            case '2':
                getProviderGroupsRefetch()
                break
            case '4':
                getComponentTypesRefetch()
                break
        }
        searchText.length > 0
            ? history.push('/admin/settings')
            : location.reload()
    }

    const handleAddFilter = () => {
        if (currentTab === '1' && Object.keys(filterErrors)?.length) {
            return
        }
        switch (currentTab) {
            case '1':
                getAppliancesRefetch()
                break
            case '2':
                getProviderGroupsRefetch()
                break
            case '4':
                getComponentTypesRefetch()
                break
        }
        setFilterMode(true)
        setDrawerVisible(false)
    }

    const handleSearchInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
        if (e.target.value === '') {
            removeQueryParamFromUrl('search')
            if (currentTab === '1') {
                getAppliancesRefetch()
            } else if (currentTab === '4') {
                getComponentTypesRefetch()
            } else if (currentTab === '2') {
                getProviderGroupsRefetch()
            }
        }
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
            if (event.target.value.length > 0) {
                addQueryParamsToUrl({
                    search: event.target.value,
                    page: 1,
                })
            } else {
                removeQueryParamFromUrl('search')
                addQueryParamsToUrl({
                    page: 1,
                })
            }
        }
    }

    const handleFilterBlur = e => {
        const { name, value } = e.target
        const numericValue = Number(value)

        if (currentTab === '1') {
            const rangeIndex0 = Number(range?.[0])
            const rangeIndex1 = Number(range?.[1])
            if (
                name === 'minValue' &&
                value !== '' &&
                range?.[1] !== '' &&
                numericValue > rangeIndex1
            ) {
                setFilterErrors(prev => ({
                    ...prev,
                    minValue: 'Must be less than max. range',
                }))
            }

            if (
                name === 'maxValue' &&
                value !== '' &&
                range?.[0] !== '' &&
                numericValue < rangeIndex0
            ) {
                setFilterErrors(prev => ({
                    ...prev,
                    maxValue: 'Must be greater than min. range',
                }))
            }
        }
    }

    const handleReset = values => {
        reset = true
        setSearchText('')
        setFilterMode(false)
        setMeasurement('')
        removeAllQueryParamsFromUrl()
        setStatus('')
        setClearGlobalAccess(true)
        setRange(['', ''])
        setInputs(prev => ({
            ...prev,
            endDate: null,
            startDate: null,
        }))
        setCurrentStartDate(null)
        setCurrentEndDate(null)
        setFilterErrors({})
        switch (values || currentTab) {
            case '1':
                getAppliancesRefetch()
                break
            case '2':
                getProviderGroupsRefetch()
                break
            case '3':
                getProviderResourceRefetch()
                break
            case '4':
                getComponentTypesRefetch()
                break
        }
        setDrawerVisible(false)
    }

    // useEffect to makes an api call when the page is changed
    useEffect(() => {
        switch (currentTab) {
            case '1':
                getAppliancesRefetch()
                break
            case '2':
                getProviderGroupsRefetch()
                break
            case '3':
                getProviderResourceRefetch()
                break
            case '4':
                getComponentTypesRefetch()
                break
        }
        setTotalPages(0)
    }, [currentPage, currentTab])

    useEffect(() => {
        setCurrentAppliances(appliances)
    }, [appliances])

    const providerResourcesCount = [
        {
            name: 'Global Library Contributors',
            no_of_providers: providerResource.gl_contributors_count,
            id: 'global-library-contributors',
        },
        {
            name: 'Global Library Users',
            no_of_providers: providerResource.gl_users_count,
            id: 'global-library-users',
        },
        {
            name: 'Subscription model providers',
            no_of_providers: providerResource.subscription_model_providers,
            id: 'subscription-model-providers',
        },
    ]

    const loadingStates =
        appliancesFetching ||
        componentTypesFetching ||
        providerGroupsFetching ||
        providerResourceFetching

    const DropdownItems = [
        {
            id: 1,
            key: 'appliances',
            text: 'Appliance',
            disabled: !canCreateAppliance,
        },
        {
            id: 2,
            key: 'provider-group',
            text: 'Provider Group',
            disabled: false,
        },
        {
            id: 3,
            key: 'component-type',
            text: 'Component Type',
            disabled: !canCreateComponentType,
        },
    ]

    const menu = (
        <Menu className="menu">
            {DropdownItems.map(item => (
                <Menu.Item
                    key={item.id}
                    onClick={() =>
                        item.id === 1
                            ? setShowAddAppliance(true)
                            : item.id === 2
                            ? history.push('/admin/users/add-group?to=Settings')
                            : setShowAddComponentType(true)
                    }
                    disabled={item.disabled}
                >
                    {item.text}
                </Menu.Item>
            ))}
        </Menu>
    )

    const closeAddComponent = () => {
        setShowAddComponentType(false)
    }

    const closeAddAppliance = () => {
        setShowAddAppliance(false)
    }

    return (
        <>
            <SecondaryModal
                noPadding="48px 80px"
                modalClass="RecommendedEnergy"
                modalWidth="624px"
                showModal={showEdit}
                closable
                onCancel={() => handleCancelEdit()}
                content={
                    <EditAppliance
                        editInputs={editInputs}
                        setEditInputs={setEditInputs}
                        handleCancelEdit={handleCancelEdit}
                        handleSaveEdit={handleSaveEdit}
                        editErrors={editErrors}
                        data={data}
                        errors={errors}
                        setErrors={setErrors}
                    />
                }
            />

            <SecondaryModal
                noPadding="48px 80px"
                modalClass="RecommendedEnergy"
                modalWidth="624px"
                showModal={showAddComponentType}
                closable
                onCancel={() => setShowAddComponentType(false)}
                content={
                    <AddComponentType
                        closeAddComponentType={closeAddComponent}
                    />
                }
            />

            <SecondaryModal
                modalWidth="624px"
                modalHeight="100%"
                showModal={showAddAppliance}
                closable
                onCancel={() => setShowAddAppliance(false)}
                content={<AddAppliance closeAddAppliance={closeAddAppliance} />}
            />
            <ActionModal
                actionModalOpen={showDelete}
                actionType="warning"
                headerText="Delete Appliance?"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText="Delete"
                actionHandler={() => handleDeleteAction()}
                noBackLink
                closable
                loading={deleteApplianceFetching}
                onCancel={() => setShowDelete(false)}
                closeModal={() => setShowDelete(false)}
            />

            <ActionModal
                actionModalOpen={showSuccess}
                actionType="success"
                headerText={
                    action === 'edit-appliance'
                        ? 'Appliance Updated Successfully'
                        : action === 'delete-appliance' &&
                          'Appliance Deleted Successfully'
                }
                onCancel={() => setShowSuccess(false)}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText="Okay, Got it"
                actionHandler={() => {
                    setShowSuccess(false)
                    getAppliancesRefetch()
                }}
                closable
                noBackLink
                noCancelBtn
            />

            <div>
                <div className="ASLPageTitle">
                    {filterMode ? (
                        <BackNav
                            title="Back to Settings"
                            onClick={() => {
                                setCurrentPage(1)
                                handleReset(currentTab)
                            }}
                        />
                    ) : (
                        <div className="ASLTitleWrapper">
                            <p className="NavPageTitle">Settings</p>
                            {currentTab !== '3' && (
                                <div className="ASLMobileDropdown">
                                    <Dropdown
                                        overlay={menu}
                                        trigger={['click']}
                                    >
                                        <a onClick={e => e.preventDefault()}>
                                            <ButtonComponent
                                                btnBgColor="var(--blue)"
                                                btnTextColor="var(--white)"
                                                btnWidth="40px"
                                                btnHeight="40px"
                                            >
                                                <span
                                                    style={{
                                                        position: 'relative',
                                                        top: '2.5px',
                                                    }}
                                                >
                                                    <PlusIcon />
                                                </span>
                                            </ButtonComponent>
                                        </a>
                                    </Dropdown>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="ASLWrapper">
                    <Drawer
                        placement="right"
                        width="367px"
                        onClose={showDrawer}
                        visible={drawerVisible}
                        closable={isMobile && true}
                    >
                        <h2 className="ARLFilterHeader">{filterDrawerTitle}</h2>
                        {/* filtering by date */}
                        <h4 className="ARLDateFilterHeader">
                            Filter By Created Date
                        </h4>
                        <div className="ARLDateFieldsWrapper">
                            <div className="ARLDateField">
                                <DateField
                                    isFullWidth
                                    placeholder="Start Date"
                                    name="startDate"
                                    caretStyle="caretStyle"
                                    dateValue={
                                        inputs.startDate === null
                                            ? null
                                            : new Date(inputs.startDate)
                                    }
                                    handleDateChange={(name, date) =>
                                        handleDateChange(name, date)
                                    }
                                    openCalendar={
                                        showCalendar === 1 ? true : false
                                    }
                                    closeCalendarHandler={() =>
                                        setShowCalendar(null)
                                    }
                                    openCalendarHandler={() =>
                                        setShowCalendar(1)
                                    }
                                    setHandleDateChange={name =>
                                        setHandleDateChange(name)
                                    }
                                    calendarClassName="ARLDataCalendar"
                                />
                            </div>

                            <div className="ARLDateField">
                                <DateField
                                    isFullWidth
                                    placeholder="End Date"
                                    name="endDate"
                                    calendarClassName="APLEndDateFilter"
                                    caretStyle="caretStyle"
                                    dateValue={
                                        inputs.endDate === null
                                            ? null
                                            : new Date(inputs.endDate)
                                    }
                                    handleDateChange={(name, date) =>
                                        handleDateChange(name, date)
                                    }
                                    openCalendar={
                                        showCalendar === 2 ? true : false
                                    }
                                    closeCalendarHandler={() =>
                                        setShowCalendar(null)
                                    }
                                    openCalendarHandler={() =>
                                        setShowCalendar(2)
                                    }
                                    setHandleDateChange={name =>
                                        setHandleDateChange(name)
                                    }
                                />
                            </div>
                        </div>
                        <h4 className="ARLStatementFilterHeader">
                            {currentTab === '1'
                                ? 'Unit Of Measurement'
                                : currentTab === '2'
                                ? 'Status'
                                : ''}
                        </h4>
                        {currentTab === '1' ? (
                            <RadioButton
                                list={['Watts', 'kVa']}
                                onCheck={v => setMeasurement(v)}
                                clearSelected={clearGlobalAccess}
                                defaultChecked={measurement}
                            />
                        ) : currentTab === '2' ? (
                            <RadioButton
                                list={['Active', 'Inactive']}
                                onCheck={v => setStatus(v)}
                                clearSelected={clearGlobalAccess}
                                defaultChecked={status}
                            />
                        ) : null}
                        {currentTab === '1' || currentTab === '2' ? (
                            <div style={{ marginTop: 25, width: '100%' }}>
                                <h4 className="ARLStatementFilterHeader">
                                    {currentTab === '1'
                                        ? 'Power Ratings'
                                        : currentTab === '2'
                                        ? 'No. of users'
                                        : ''}
                                </h4>
                                {currentTab !== '1' && currentTab !== '2' ? (
                                    <Slider
                                        range={{ draggableTrack: true }}
                                        defaultValue={[0, 0]}
                                        max={maxFilterAmount}
                                        value={range}
                                        tooltipPlacement={'bottom'}
                                        onChange={value => setRange(value)}
                                        tipFormatter={value =>
                                            `${new Intl.NumberFormat().format(
                                                value,
                                            )}`
                                        }
                                    />
                                ) : (
                                    <div className="ASLRangeWrapper">
                                        <InputFields
                                            title={minInputTitle}
                                            value={range?.[0]}
                                            name={'minValue'}
                                            handleChange={e =>
                                                handleRangeChange(e)
                                            }
                                            errorMessage={
                                                filterErrors?.minValue
                                            }
                                            onBlur={handleFilterBlur}
                                        />
                                        <InputFields
                                            title={maxInputTitle}
                                            value={range?.[1]}
                                            name={'maxValue'}
                                            handleChange={e =>
                                                handleRangeChange(e)
                                            }
                                            errorMessage={
                                                filterErrors?.maxValue
                                            }
                                            onBlur={handleFilterBlur}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : null}
                        <div className="ARLFilterButtonWrapper">
                            <AppButton
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                btnWidth={160}
                                btnHeight={56}
                                handleClick={() => handleReset(currentTab)}
                            >
                                Reset Filter
                            </AppButton>
                            <AppButton
                                btnTextColor="#FFFFFF"
                                btnBgColor="#004AAD"
                                btnWidth={160}
                                btnHeight={56}
                                handleClick={() => {
                                    setCurrentPage(1)
                                    setTimeout(() => {
                                        handleAddFilter()
                                    }, 500)
                                }}
                            >
                                {allLoadingStates ? (
                                    <InlineLoader />
                                ) : (
                                    'Apply Filter'
                                )}
                            </AppButton>
                        </div>
                    </Drawer>
                    {currentTab !== '3' && (
                        <div className="ASLSpaceBetween">
                            <div style={{ display: 'flex' }}>
                                <NoFLoatingLabelInputFields
                                    borderRadius="10px"
                                    inputWidth="350px"
                                    inputHeight="45px"
                                    type="text"
                                    inputBackground="#F6F6F6"
                                    iconType="search"
                                    prefilled
                                    inputPlaceholder="Search"
                                    style={{ paddingBottom: 7 }}
                                    handleChange={handleSearchInputChange}
                                    onIconClick={handleSearch}
                                    TextPrefilled={searchText ? true : false}
                                    value={searchText}
                                    onKeyUp={handleKeyPress}
                                    onBlur={handleOnBlur}
                                />
                                <div
                                    className="ASLFilterBox"
                                    style={{ border: '1px solid #e5e5e5' }}
                                    onClick={() => {
                                        setDrawerVisible(true)
                                        clearGlobalAccess &&
                                            setClearGlobalAccess(false)
                                    }}
                                >
                                    <svg
                                        width="12"
                                        height="15"
                                        viewBox="0 0 12 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ marginTop: 4 }}
                                    >
                                        <path
                                            d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                            fill={'#1D2A30'}
                                        />
                                    </svg>

                                    <p className="ASLFilterText">Filters</p>
                                </div>
                            </div>
                            <div className="ASLCreateNew_Btn">
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <a onClick={e => e.preventDefault()}>
                                        <Button
                                            btnTextColor="var(--white)"
                                            btnBgColor="var(--blue)"
                                            btnWidth="150px"
                                            btnHeight="56px"
                                            fontSize="14px"
                                            fontFamily="SF-Pro-Display-Semibold"
                                        >
                                            Create New
                                            <ArrowDown
                                                style={{
                                                    marginLeft: 12,
                                                }}
                                            />
                                        </Button>
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                    {filterMode && (
                        <h1 className="AdminPaymentPlanFilterText">
                            Filter Results ({count})
                        </h1>
                    )}
                    <div className="ASLTabWrapper">
                        {loadingStates ? (
                            <ListingsSkeletalCards />
                        ) : (
                            <>
                                <Tabs
                                    activeKey={currentTab}
                                    style={{ marginTop: 38 }}
                                    onChange={values => {
                                        setCurrentTab(values)
                                        setCurrentPage(1)
                                        handleReset(values)
                                        addQueryParamsToUrl({
                                            tab: settingsTabs[values],
                                            page: 1,
                                        })
                                    }}
                                >
                                    {toastError && (
                                        <Toast
                                            messageType="error"
                                            message={errorMessage}
                                        />
                                    )}
                                    {canViewApplianceList && (
                                        <TabPane
                                            tab={`Appliances ${
                                                currentTab === '1'
                                                    ? '(' + count + ')'
                                                    : ''
                                            }`}
                                            key="1"
                                        >
                                            {appliances.length > 0 ? (
                                                <div className="ASLTablewWrapper">
                                                    <div className="ASLTableHeader WithExtraButton">
                                                        <span>Name</span>
                                                        <span>
                                                            Power Ratings
                                                        </span>
                                                        <span>Unit</span>
                                                        <span>
                                                            Date Created
                                                        </span>
                                                        <span>Actions</span>
                                                    </div>
                                                    {currentAppliances?.map(
                                                        item => (
                                                            <div
                                                                key={item?.id}
                                                                className="ASLTableContent WithExtraButton"
                                                            >
                                                                <span>
                                                                    {item?.name}
                                                                </span>
                                                                <span>
                                                                    {
                                                                        item?.power_rating
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {
                                                                        item?.unit_of_measurement
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {format(
                                                                        parseISO(
                                                                            item?.created_at,
                                                                        ),
                                                                        'dd MMM, yyyy, hh:mm',
                                                                    )}
                                                                </span>
                                                                <div className="ASLBtnWrapper">
                                                                    <AppButton
                                                                        btnBgColor="#E2EEFF"
                                                                        btnTextColor="var(--blue)"
                                                                        btnTextColorOutline="var(--blue)"
                                                                        btnOutlineColor="var(--purple-light)"
                                                                        btnBgColorOutline="#E2EEFF"
                                                                        type="outline"
                                                                        btnWidth="70px"
                                                                        btnHeight="37px"
                                                                        fontSize="13px"
                                                                        fontFamily="SF-Pro-Display-Semibold"
                                                                        handleClick={() =>
                                                                            handleEditAppliance(
                                                                                item,
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            !canUpdateAppliance
                                                                        }
                                                                    >
                                                                        Edit
                                                                    </AppButton>
                                                                    <div
                                                                        style={{
                                                                            marginLeft: 8,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            btnBgColor="var(--white)"
                                                                            btnTextColor="var(--blue)"
                                                                            btnWidth="70px"
                                                                            btnHeight="37px"
                                                                            type="MainButtonTwo"
                                                                            fontSize="13px"
                                                                            fontFamily="SF-Pro-Display-Semibold"
                                                                            handleClick={() =>
                                                                                handleDeleteAppliance(
                                                                                    item?.id,
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                !canDeleteAppliance
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ),
                                                    )}
                                                    {/* mobile content */}
                                                    <div className="ASLTableMobile">
                                                        {appliances.map(
                                                            item => (
                                                                <div
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                    className="APLTableMobileContentWrapper"
                                                                >
                                                                    <div className="APLMobileContent">
                                                                        <div className="APLMobileTopWrapper">
                                                                            <p
                                                                                className="APLMobileContentTitle"
                                                                                style={{
                                                                                    textAlign:
                                                                                        'left',
                                                                                }}
                                                                            >
                                                                                NAME
                                                                            </p>
                                                                            <p
                                                                                className="APLMobileContentData"
                                                                                style={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item?.name
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <ButtonComponent
                                                                                type="XSmall"
                                                                                btnHeight="37px"
                                                                                btnWidth="70px"
                                                                                handleClick={() =>
                                                                                    handleEditAppliance(
                                                                                        item,
                                                                                    )
                                                                                }
                                                                            >
                                                                                Edit
                                                                            </ButtonComponent>
                                                                            <ButtonComponent
                                                                                type="XSmall"
                                                                                btnHeight="37px"
                                                                                btnWidth="70px"
                                                                                handleClick={() =>
                                                                                    handleDeleteAppliance(
                                                                                        item?.id,
                                                                                    )
                                                                                }
                                                                            >
                                                                                Delete
                                                                            </ButtonComponent>
                                                                        </div>
                                                                    </div>
                                                                    <div className="APLMobileContent">
                                                                        <div>
                                                                            <p className="APLMobileContentTitle">
                                                                                POWER
                                                                                RATINGS
                                                                            </p>
                                                                            <span className="APLMobileContentData">
                                                                                {
                                                                                    item.power_rating
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <p className="APLMobileContentTitle">
                                                                                UNITS
                                                                            </p>
                                                                            <p
                                                                                className="APLMobileContentData"
                                                                                style={{
                                                                                    textTransform:
                                                                                        'capitalize',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item?.unit_of_measurement
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <EmptyState
                                                    type="noActivity"
                                                    title={
                                                        filterMode
                                                            ? 'There are no results, try again!'
                                                            : 'No Appliance'
                                                    }
                                                />
                                            )}
                                        </TabPane>
                                    )}

                                    <TabPane
                                        tab={`Provider Groups ${
                                            currentTab === '2'
                                                ? '(' + count + ')'
                                                : ''
                                        }`}
                                        key="2"
                                    >
                                        {providerGroups.length > 0 ? (
                                            <div className="ASLTablewWrapper">
                                                <div className="ASLTableHeader">
                                                    <span>Name</span>
                                                    <span>No. of Users</span>
                                                    <span>Status</span>
                                                    <span>Date Created</span>
                                                    <span>Actions</span>
                                                </div>
                                                {providerGroups?.map(item => (
                                                    <div
                                                        key={item?.id}
                                                        className="ASLTableContent"
                                                    >
                                                        <span>
                                                            {item?.name}
                                                        </span>
                                                        <span>
                                                            {item?.users_count}
                                                        </span>
                                                        <p>
                                                            {item?.is_active ===
                                                            true
                                                                ? 'Active'
                                                                : 'Deactivated'}
                                                        </p>
                                                        <span>
                                                            {format(
                                                                parseISO(
                                                                    item?.created_at,
                                                                ),
                                                                'dd MMM, yyyy, hh:mm',
                                                            )}
                                                        </span>
                                                        <div className="ASLBtnWrapper">
                                                            <ButtonComponent
                                                                type="XSmall"
                                                                handleClick={() =>
                                                                    history.push(
                                                                        `/admin/settings/provider-groups/${item?.id}`,
                                                                    )
                                                                }
                                                            >
                                                                View
                                                            </ButtonComponent>
                                                        </div>
                                                    </div>
                                                ))}
                                                {/* mobile content */}
                                                <div className="ASLTableMobile">
                                                    {providerGroups.map(
                                                        item => (
                                                            <div
                                                                key={item?.id}
                                                                className="APLTableMobileContentWrapper"
                                                            >
                                                                <div className="APLMobileContent">
                                                                    <div className="APLMobileTopWrapper">
                                                                        <p
                                                                            className="APLMobileContentTitle"
                                                                            style={{
                                                                                textAlign:
                                                                                    'left',
                                                                            }}
                                                                        >
                                                                            NAME
                                                                        </p>
                                                                        <span
                                                                            className="APLMobileContentData"
                                                                            style={{
                                                                                fontSize: 14,
                                                                            }}
                                                                        >
                                                                            {
                                                                                item?.name
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <ButtonComponent
                                                                        type="XSmall"
                                                                        handleClick={() =>
                                                                            history.push(
                                                                                `/admin/settings/provider-groups/${item?.id}`,
                                                                            )
                                                                        }
                                                                    >
                                                                        View
                                                                    </ButtonComponent>
                                                                </div>
                                                                <div className="APLMobileContent">
                                                                    <div>
                                                                        <p
                                                                            className="APLMobileContentTitle"
                                                                            style={{
                                                                                textAlign:
                                                                                    'left',
                                                                            }}
                                                                        >
                                                                            STATUS
                                                                        </p>
                                                                        <p
                                                                            className="APLMobileContentData"
                                                                            style={{
                                                                                textTransform:
                                                                                    'capitalize',
                                                                            }}
                                                                        >
                                                                            {item?.state.toLowerCase()}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="APLMobileContentTitle">
                                                                            DATE
                                                                            CREATED
                                                                        </p>
                                                                        <p
                                                                            className="APLMobileContentData"
                                                                            style={{
                                                                                textTransform:
                                                                                    'capitalize',
                                                                            }}
                                                                        >
                                                                            {format(
                                                                                parseISO(
                                                                                    item?.created_at,
                                                                                ),
                                                                                'dd MMM, yyyy, hh:mm',
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <EmptyState
                                                type="noActivity"
                                                title={
                                                    filterMode
                                                        ? 'There are no results, try again!'
                                                        : 'No provider'
                                                }
                                            />
                                        )}
                                    </TabPane>
                                    <TabPane tab={`Provider Resources`} key="3">
                                        <div className="ASLTablewWrapper">
                                            <div className="ASLResourceHeader">
                                                <span>Name</span>
                                                <span>No. of Providers</span>
                                                <span>Actions</span>
                                            </div>
                                            {providerResourcesCount?.map(
                                                (item, index) => (
                                                    <div
                                                        key={index}
                                                        className="ASLTableContent"
                                                        id="four-column"
                                                        style={{
                                                            gridTemplateColumns:
                                                                '3fr 2fr 1fr',
                                                            width: '680px',
                                                        }}
                                                    >
                                                        <span>
                                                            {item?.name}
                                                        </span>
                                                        <span>
                                                            {
                                                                item?.no_of_providers
                                                            }
                                                        </span>
                                                        <div className="ASLBtnWrapper">
                                                            <ButtonComponent
                                                                type="XSmall"
                                                                handleClick={() =>
                                                                    history.push(
                                                                        `/admin/settings/provider-resources/${item?.id}`,
                                                                    )
                                                                }
                                                                disabled={
                                                                    !viewLibraryUserPermissions[
                                                                        item?.id
                                                                    ]
                                                                }
                                                                toolTipText={
                                                                    !viewLibraryUserPermissions[
                                                                        item?.id
                                                                    ]
                                                                        ? 'You are not authorised to perform this action'
                                                                        : null
                                                                }
                                                                toolTipPopupWidth={
                                                                    167
                                                                }
                                                                arrowPositionCenter
                                                            >
                                                                View
                                                            </ButtonComponent>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                        {/* mobile content */}
                                        <div className="ASLTableMobile">
                                            {providerResourcesCount.map(
                                                item => (
                                                    <div
                                                        key={item?.id}
                                                        className="APLTableMobileContentWrapper"
                                                    >
                                                        <div className="APLMobileContent">
                                                            <div>
                                                                <p
                                                                    className="APLMobileContentTitle"
                                                                    style={{
                                                                        textAlign:
                                                                            'left',
                                                                    }}
                                                                >
                                                                    NAME
                                                                </p>
                                                                <span
                                                                    className="APLMobileContentData"
                                                                    style={{
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    {item?.name}
                                                                </span>
                                                            </div>
                                                            <ButtonComponent
                                                                type="XSmall"
                                                                handleClick={() =>
                                                                    history.push(
                                                                        `/admin/settings/provider-resources/${item?.id}`,
                                                                    )
                                                                }
                                                                disabled={
                                                                    !viewLibraryUserPermissions[
                                                                        item?.id
                                                                    ]
                                                                }
                                                                toolTipText={
                                                                    !viewLibraryUserPermissions[
                                                                        item?.id
                                                                    ]
                                                                        ? 'You are not authorised to perform this action'
                                                                        : null
                                                                }
                                                                toolTipPopupWidth={
                                                                    167
                                                                }
                                                                arrowPositionCenter
                                                            >
                                                                View
                                                            </ButtonComponent>
                                                        </div>
                                                        <div className="APLMobileContent">
                                                            <div>
                                                                <p className="APLMobileContentTitle">
                                                                    PROVIDERS
                                                                </p>
                                                                <span className="APLMobileContentData">
                                                                    <span>
                                                                        {
                                                                            item?.no_of_providers
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </TabPane>
                                    {canViewComponentTypeList && (
                                        <TabPane
                                            tab={`Component Types ${
                                                currentTab === '4'
                                                    ? '(' + count + ')'
                                                    : ''
                                            }`}
                                            key="4"
                                        >
                                            {componentTypes?.length > 0 ? (
                                                <div className="ASLTablewWrapper">
                                                    <div
                                                        className="ASLTableHeader"
                                                        id="four-column"
                                                    >
                                                        <span>Name</span>
                                                        <span>Provider</span>
                                                        <span>
                                                            Date Created
                                                        </span>
                                                        <span>Actions</span>
                                                    </div>
                                                    {componentTypes.map(
                                                        item => (
                                                            <div
                                                                key={item?.id}
                                                                className="ASLTableContent"
                                                                id="four-column"
                                                            >
                                                                <span>
                                                                    {item?.name}
                                                                </span>
                                                                <span>
                                                                    {item?.provider_name ??
                                                                        '-'}
                                                                </span>
                                                                <span>
                                                                    {format(
                                                                        parseISO(
                                                                            item?.created_at,
                                                                        ),
                                                                        'dd MMM, yyyy, hh:mm',
                                                                    )}
                                                                </span>
                                                                <div className="ASLBtnWrapper">
                                                                    <ButtonComponent
                                                                        type="XSmall"
                                                                        handleClick={() =>
                                                                            history.push(
                                                                                `/admin/settings/component-types/${item?.id}`,
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            !canViewComponentTypeDetails
                                                                        }
                                                                        toolTipText={
                                                                            !canViewComponentTypeDetails
                                                                                ? 'You are not authorised to perform this action'
                                                                                : null
                                                                        }
                                                                        toolTipPopupWidth={
                                                                            167
                                                                        }
                                                                        arrowPositionCenter
                                                                        marginLeft={
                                                                            -12
                                                                        }
                                                                    >
                                                                        View
                                                                    </ButtonComponent>
                                                                </div>
                                                            </div>
                                                        ),
                                                    )}
                                                    {/* mobile content */}
                                                    <div className="ASLTableMobile">
                                                        {componentTypes.map(
                                                            item => (
                                                                <div
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                    className="APLTableMobileContentWrapper"
                                                                >
                                                                    <div className="APLMobileContent">
                                                                        <div className="APLMobileTopWrapper">
                                                                            <p
                                                                                className="APLMobileContentTitle"
                                                                                style={{
                                                                                    textAlign:
                                                                                        'left',
                                                                                }}
                                                                            >
                                                                                NAME
                                                                            </p>
                                                                            <span
                                                                                className="APLMobileContentData"
                                                                                style={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item?.name
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <ButtonComponent
                                                                            type="XSmall"
                                                                            handleClick={() =>
                                                                                history.push(
                                                                                    `/admin/settings/component-types/${item?.id}`,
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                !canViewComponentTypeDetails
                                                                            }
                                                                            toolTipText={
                                                                                !canViewComponentTypeDetails
                                                                                    ? 'You are not authorised to perform this action'
                                                                                    : null
                                                                            }
                                                                            toolTipPopupWidth={
                                                                                167
                                                                            }
                                                                            arrowPositionCenter
                                                                            marginLeft={
                                                                                -12
                                                                            }
                                                                        >
                                                                            View
                                                                        </ButtonComponent>
                                                                    </div>
                                                                    <div className="APLMobileContent">
                                                                        <div>
                                                                            <p
                                                                                className="APLMobileContentTitle"
                                                                                style={{
                                                                                    textAlign:
                                                                                        'left',
                                                                                }}
                                                                            >
                                                                                PROVIDER
                                                                            </p>
                                                                            <span className="APLMobileContentData">
                                                                                <span>
                                                                                    {item?.provider_name ??
                                                                                        '-'}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <p className="APLMobileContentTitle">
                                                                                DATE
                                                                                CREATED
                                                                            </p>
                                                                            <p
                                                                                className="APLMobileContentData"
                                                                                style={{
                                                                                    textTransform:
                                                                                        'capitalize',
                                                                                }}
                                                                            >
                                                                                {format(
                                                                                    parseISO(
                                                                                        item?.created_at,
                                                                                    ),
                                                                                    'dd MMM, yyyy, hh:mm',
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <EmptyState
                                                    type="noActivity"
                                                    title={
                                                        filterMode
                                                            ? 'There are no results, try again!'
                                                            : 'No Component type'
                                                    }
                                                />
                                            )}
                                        </TabPane>
                                    )}
                                </Tabs>
                                {currentTab !== '3' && (
                                    <div className="ASLPaginationWrapper">
                                        {paginationBlock()}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminSettings
